import { Box } from "@mui/joy"
import { styled } from "@mui/joy/styles"

export const DatasetTabFooter = styled(Box)({
  position: "absolute",
  bottom: "0px",
  width: "100%",
  textAlign: "center",
  backgroundColor: "#F4FBFF",
  borderTop: "1px solid #EFEEF4",
  padding: "20px 20px",
})
