import * as React from "react"
import Modal from "@mui/joy/Modal"
import ModalDialog, {
  ModalDialogPropsSizeOverrides,
} from "@mui/joy/ModalDialog"
import ModalClose from "@mui/joy/ModalClose"
import DialogTitle from "@mui/joy/DialogTitle"
import { OverridableStringUnion } from "@mui/types"
import Divider from "@mui/material/Divider"

interface ModalBoxProps {
  open: boolean
  title: string
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  children: React.ReactNode
  width?: number | string
  size?:
    | OverridableStringUnion<"sm" | "md" | "lg", ModalDialogPropsSizeOverrides>
    | undefined
}

const ModalBox = ({
  open,
  title,
  setOpen,
  children,
  width = 880,
  size = "md",
}: ModalBoxProps) => {
  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false)
        }}
      >
        <ModalDialog sx={{ width: width }}>
          <ModalClose />
          <DialogTitle>{title}</DialogTitle>
          <Divider />
          {children}
        </ModalDialog>
      </Modal>
    </React.Fragment>
  )
}

export default ModalBox
