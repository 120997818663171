/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AbortUploadOut
 */
export interface AbortUploadOut {
    /**
     * 
     * @type {object}
     * @memberof AbortUploadOut
     */
    responseMetadata: object;
}

/**
 * Check if a given object implements the AbortUploadOut interface.
 */
export function instanceOfAbortUploadOut(value: object): boolean {
    if (!('responseMetadata' in value)) return false;
    return true;
}

export function AbortUploadOutFromJSON(json: any): AbortUploadOut {
    return AbortUploadOutFromJSONTyped(json, false);
}

export function AbortUploadOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): AbortUploadOut {
    if (json == null) {
        return json;
    }
    return {
        
        'responseMetadata': json['ResponseMetadata'],
    };
}

export function AbortUploadOutToJSON(value?: AbortUploadOut | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'ResponseMetadata': value['responseMetadata'],
    };
}

