import { scaleLinear } from "@visx/scale"
import { Orientation } from "@visx/axis"
import React, { RefObject, forwardRef, ForwardedRef, useState } from "react"
import { useParentSize } from "@visx/responsive"
import {
  CallbackInterface,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil"
import { useEventContextMenu } from "./event/EventContextMenu"
import { StyledSvg } from "@huxley-medical/react-components/components"
import { useHandleArrowKeys } from "@huxley-medical/react-components/hooks/keyboard"
import { epochMs } from "../../constants"
import StackableLinePlot, {
  bottomPadding,
  labelWidth,
  topPadding,
} from "./StackableLinePlot"
import throttle from "lodash.throttle"
import { useMouseWheel } from "@huxley-medical/react-components/hooks/mouse"
import { EventPlot, ScorerEventData } from "../../types/event.type"
import { eventFamily } from "../../state/event.state"
import { currentEpoch, numEpochs } from "../../state/epoch.state"
import { studyPlotData, studyPlotOrder } from "../../state/study.state"
import { allPlotTimeDomain } from "../../state/signals.state"
import {
  fullNightInterval,
  intervalTimeEventTypeAtom,
} from "../../state/study.state"
import { generatePlotData } from "../../utils/studyUtils"
import { styled } from "@mui/joy/styles"
import YScaleModal from "./yScaleModal/YScaleModal"
import { snackAlert } from "../SnackAlerts"
import { LinePlot } from "../../types/line.type"
import AxisGraph from "./Axis/AxisGraph"
import { binarySearchData } from "../../utils/utils"

type ThrottledRightArrowHandlerParams = {
  totalEpochs: number
  setEpoch: (valOrUpdater: number | ((currVal: number) => number)) => void
  numEpochs: number
}

type ThrottledLeftArrowHandlerParams = {
  setEpoch: (valOrUpdater: number | ((currVal: number) => number)) => void
  numEpochs: number
}

const StyledPlots = styled("div")({
  position: "relative",
  marginTop: "5px",
  width: "100%",
  height: "100%",
})

export const numYTicksDefault = 5

/**
 * eventRemove is a recoil callback to handle deleting
 * an event window.
 *
 * @param callback
 * @returns callbackHandler
 */
export const eventRemove =
  (callback: CallbackInterface) =>
  (
    eventID: number | undefined,
    studyID: string | undefined,
    eventData: ScorerEventData,
    userId: string | undefined
  ) =>
  () => {
    if (studyID !== undefined && eventID !== undefined) {
      callback.set(eventFamily(eventData.id), {
        ...eventData,
        removed: true,
        removedOn: new Date().getTime() / 1000,
        removedBy: userId,
      })
    }
  }

/**
 * StackedPlots is the parent functional component of the individual stacked
 * plots. It handles scrolling epochs using keyboard arrow keys, the event
 * context menu, calculation of global time x-domain, and dataset epoch
 * pagination and slicing.
 *
 * @param {PlotsParams} plotsParams
 * @returns {JSX.Element} JSX.Element
 */
const StackedPlots = forwardRef((props, ref: ForwardedRef<HTMLDivElement>) => {
  const setSnackAlertMsg = useSetRecoilState(snackAlert)
  //const plotsRef = useRef<HTMLDivElement>(null)
  const ContextMenu = useEventContextMenu({
    plotsRef: ref as RefObject<HTMLDivElement>,
  })
  const totalEpochs = useRecoilValue(numEpochs)
  const [epoch, setEpoch] = useRecoilState(currentEpoch)
  const studyDataValue = useRecoilValue(studyPlotData)
  const [xAllMin, xAllMax] = useRecoilValue(allPlotTimeDomain)
  const plotOrder = useRecoilValue(studyPlotOrder)
  const selectedTimeInterval = useRecoilValue(intervalTimeEventTypeAtom)
  const fullNightIntervalValue = useRecoilValue(fullNightInterval)
  const studyd = studyDataValue

  const [scaleModalOpen, setScaleModalOpen] = useState({
    open: false,
    plotType: "",
  })
  const [autoScaling, setAutoScaling] = React.useState(false)

  const plots = generatePlotData({
    respiratoryeffortPlot: studyd.respiratory_effort,
    snorePlot: studyDataValue.snore,
    spo2Plot: studyDataValue.spo2,
    cardiacPlot: studyDataValue.hr,
    actigraphyPlot: studyDataValue.actigraphy,
    chestPlot: studyDataValue.chest_movement,
    positionPlot: studyDataValue.body_position,
    sleepPlot: studyDataValue.sleep,
    ecgPlot: studyDataValue.ecg,
  })

  /**
   * throttledLeftArrowHandler is a throttled function for scrolling the current epoch backwards
   */
  const throttledLeftArrowHandler = throttle(
    ({ setEpoch, numEpochs }: ThrottledLeftArrowHandlerParams) => {
      setEpoch((current) => {
        const newEpoch = current - numEpochs
        if (newEpoch <= 0) {
          return 0
        } else {
          return newEpoch
        }
      })
    },
    200,
    { leading: true, trailing: false }
  )

  /**
   * throttledRightArrowHandler is a throttled function for scrolling the current epoch forwards
   */
  const throttledRightArrowHandler = //throttle(
    ({
      totalEpochs,
      setEpoch,
      numEpochs,
    }: ThrottledRightArrowHandlerParams) => {
      setEpoch((current) => {
        const newEpoch = current + numEpochs
        const lastScrollableEpoch = totalEpochs - selectedTimeInterval / epochMs
        if (newEpoch >= lastScrollableEpoch) {
          return lastScrollableEpoch
        } else {
          return newEpoch
        }
      })
    }
  //, { leading: true, trailing: false }
  //)

  // Setup event handlers that will update recoil state
  //const handleEventRemove = useRecoilCallback(eventRemove)

  // Handle changing epoch state when left or right arrow keys are pressed
  // NOTE: we could move the arrow key code to EpochScrollWindow
  // (Update range before epoch (without epoch deps))
  // to remove the fromLocal state...
  // Would require mouseup for useHandleArrowKeys

  //Define the scroll amount to be half the current selected time interval in epochs
  const scrollAmount = Math.floor(selectedTimeInterval / epochMs / 2)

  useHandleArrowKeys(
    {
      onLeftArrow: () =>
        throttledLeftArrowHandler({ setEpoch, numEpochs: scrollAmount }),
      onRightArrow: () =>
        throttledRightArrowHandler({
          totalEpochs,
          setEpoch,
          numEpochs: scrollAmount,
        }),
    },
    [totalEpochs, scrollAmount]
  )

  useMouseWheel(
    {
      handler: (e) => {
        if (fullNightIntervalValue === selectedTimeInterval) return
        // 13 is the deltaY for a single scroll "tick"

        //const mouseScrollEpochs = scrollAmount

        if (e.deltaY < 0) {
          throttledRightArrowHandler({
            totalEpochs,
            setEpoch,
            numEpochs: scrollAmount,
          })
        } else {
          throttledLeftArrowHandler({
            setEpoch,
            numEpochs: scrollAmount,
          })
        }
      },
      activated: true,
    },
    [totalEpochs, fullNightIntervalValue, selectedTimeInterval, scrollAmount]
  )

  /*useHandleDeleteKey(
    {
      onDeleteKeyPress: handleEventRemove(selectedEvent, studyID, useRecoilValue(eventFamily(selectedEvent))),
      activated: true,
    },
    [selectedEvent]
  )*/

  // Caclulate page & data slicing given epoch state
  const epochOffset = epoch * epochMs

  // Slice data here based on page state
  const xPageStart =
    xAllMin + epochOffset + selectedTimeInterval > xAllMax
      ? xAllMax - selectedTimeInterval
      : xAllMin + epochOffset

  // Page end will change as per selected time interval
  const xPageEnd =
    xPageStart + selectedTimeInterval > xAllMax
      ? xAllMax
      : xPageStart + selectedTimeInterval

  // epochPageMs

  const tickIntervals =
    Math.ceil(
      selectedTimeInterval === 1 ? xAllMax - xAllMin : selectedTimeInterval
    ) * 0.1

  const [yScales, setYScales] = useState({
    Position: { minY: 0, maxY: 5 },
    SANSASleepStage: { minY: 1, maxY: 0 },
    SpO2: { minY: 60, maxY: 100 },
    HR: { minY: NaN, maxY: NaN },
    Actigraphy: { minY: NaN, maxY: NaN },
    Chest: { minY: NaN, maxY: NaN },
    Resp: { minY: NaN, maxY: NaN },
    Snoring: { minY: NaN, maxY: NaN },
    ECG: { minY: NaN, maxY: NaN },
  })

  const handleOpenScaleModal = (plotType: string) => {
    // Position and sleep plots cannot be rescaled
    if (plotType === "Position" || plotType === "SANSASleepStage") {
      return
    }
    // Set autoscaling checkbox if currently autoscaling plot
    if (
      Number.isNaN(yScales[plotType as EventPlot].minY) &&
      Number.isNaN(yScales[plotType as EventPlot].maxY)
    ) {
      setAutoScaling(true)
    }
    // Set current scale values to modal
    setNewYScales({
      minY: yScales[plotType as EventPlot].minY,
      maxY: yScales[plotType as EventPlot].maxY,
    })
    setScaleModalOpen({ open: true, plotType })
  }

  const handleCloseScaleModal = () => {
    setNewYScales({ minY: NaN, maxY: NaN })
    setScaleModalOpen({ open: false, plotType: "" })
  }

  const handleSaveScaleModal = () => {
    if (autoScaling) {
      setYScales({
        ...yScales,
        [scaleModalOpen.plotType]: {
          minY: NaN,
          maxY: NaN,
        },
      })
      handleCloseScaleModal()
      return
    }
    if (newYScales.minY > newYScales.maxY) {
      setSnackAlertMsg({
        open: true,
        message: "Minimum value must be less than maximum value",
        severity: "error",
      })
    } else {
      setYScales({
        ...yScales,
        [scaleModalOpen.plotType]: {
          minY: newYScales.minY,
          maxY: newYScales.maxY,
        },
      })
      handleCloseScaleModal()
    }
  }

  const [newYScales, setNewYScales] = useState({ minY: NaN, maxY: NaN })

  const orderedPlots: LinePlot[] =
    plotOrder?.map((plot) => {
      return plots.find((p) => p.plotType === plot.plotType) as LinePlot
    }) || []

  const enabledPlots = orderedPlots.filter(
    (plot) =>
      plotOrder.find((p) => p.plotType === plot.plotType)?.enabled &&
      plot.data.length > 0
  )

  const { width, height, parentRef } = useParentSize({ debounceTime: 0 })

  const topHeaderHeight = 20
  const plotHeight = height + topHeaderHeight
  const plotWidth = width - labelWidth

  // define time scale for this page
  const timeScale = scaleLinear<number>({
    domain: [xPageStart, xPageEnd],
    range: [0, plotWidth],
  })

  const lineHeight =
    plotHeight / (enabledPlots.length + 1) < 55
      ? 55
      : plotHeight / (enabledPlots.length + 1)

  const stackedPlotHeight = lineHeight * enabledPlots.length
  return (
    <StyledPlots ref={parentRef}>
      <YScaleModal
        autoScaling={autoScaling}
        setAutoScaling={setAutoScaling}
        handleSaveScaleModal={handleSaveScaleModal}
        handleCloseScaleModal={handleCloseScaleModal}
        newYScales={newYScales}
        setNewYScales={setNewYScales}
        yScales={yScales}
        scaleModalOpen={scaleModalOpen}
      />
      <div ref={ref}>
        <StyledSvg
          width={width}
          height={stackedPlotHeight + topPadding + bottomPadding}
        >
          {width > 8 &&
            enabledPlots.map(
              (
                {
                  data,
                  title,
                  plotType,
                  lineColor = "#000",
                  yTickValues,
                  yTickFormatter,
                  yDomainPadding,
                  unit,
                  plotLine = "line",
                },
                i
              ) => {
                // Slice data into a single page
                const pageData = data.slice(
                  binarySearchData(data, xPageStart),
                  binarySearchData(data, xPageEnd)
                )
                return (
                  <React.Fragment key={i + title}>
                    {width > 8 && pageData.length > 0 && (
                      <StackableLinePlot
                        key={title}
                        inx={i}
                        title={title}
                        data={pageData}
                        timeScale={timeScale}
                        lineColor={lineColor}
                        lineHeight={lineHeight}
                        width={width}
                        tickIntervals={tickIntervals}
                        plotType={plotType}
                        plotWidth={plotWidth}
                        yTicks={yTickValues}
                        yTickFormat={yTickFormatter}
                        unit={unit}
                        plotLine={plotLine}
                        xPageStart={xPageStart}
                        xPageEnd={xPageEnd}
                        yScales={yScales}
                        yDomainPadding={yDomainPadding}
                        handleOpenScaleModal={() =>
                          handleOpenScaleModal(plotType)
                        }
                      />
                    )}
                  </React.Fragment>
                )
              }
            )}

          {width > 0 && (
            <AxisGraph
              top={topPadding}
              left={labelWidth}
              hideTicks={true}
              // Shift tick numbers to the left
              tickTransform="translate(-5, 0)"
              // Don't hide entire axis, this fixes a bottom plot line
              // zindex as side-effect
              minTick={xPageStart}
              maxTick={xPageEnd}
              tickInterval={tickIntervals}
              orientation={Orientation.top}
              scale={timeScale}
              tickFormat={() => {
                return ``
              }}
            />
          )}

          {width > 0 && (
            <AxisGraph
              top={lineHeight * enabledPlots.length + topPadding}
              left={labelWidth}
              hideTicks={true}
              // Shift tick numbers to the left
              tickTransform="translate(-10, 0)"
              // Don't hide entire axis, this fixes a bottom plot line
              // zindex as side-effect
              minTick={xPageStart}
              maxTick={xPageEnd}
              tickInterval={tickIntervals}
              orientation={undefined}
              scale={timeScale}
              tickFormat={(time) => {
                return new Date(time.valueOf()).toLocaleTimeString()
              }}
            />
          )}
        </StyledSvg>
      </div>
      <ContextMenu />
    </StyledPlots>
  )
})
StackedPlots.displayName = "StackedPlots"
export default StackedPlots
