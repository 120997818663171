/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrganizationLocationUpdate
 */
export interface OrganizationLocationUpdate {
    /**
     * 
     * @type {string}
     * @memberof OrganizationLocationUpdate
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationLocationUpdate
     */
    organizationId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationLocationUpdate
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationLocationUpdate
     */
    address1?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationLocationUpdate
     */
    address2?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationLocationUpdate
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationLocationUpdate
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationLocationUpdate
     */
    zip?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationLocationUpdate
     */
    phone?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationLocationUpdate
     */
    enabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationLocationUpdate
     */
    isPrimary?: boolean;
}

/**
 * Check if a given object implements the OrganizationLocationUpdate interface.
 */
export function instanceOfOrganizationLocationUpdate(value: object): boolean {
    return true;
}

export function OrganizationLocationUpdateFromJSON(json: any): OrganizationLocationUpdate {
    return OrganizationLocationUpdateFromJSONTyped(json, false);
}

export function OrganizationLocationUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationLocationUpdate {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'] == null ? undefined : json['uuid'],
        'organizationId': json['organization_id'] == null ? undefined : json['organization_id'],
        'description': json['description'] == null ? undefined : json['description'],
        'address1': json['address_1'] == null ? undefined : json['address_1'],
        'address2': json['address_2'] == null ? undefined : json['address_2'],
        'city': json['city'] == null ? undefined : json['city'],
        'state': json['state'] == null ? undefined : json['state'],
        'zip': json['zip'] == null ? undefined : json['zip'],
        'phone': json['phone'] == null ? undefined : json['phone'],
        'enabled': json['enabled'] == null ? undefined : json['enabled'],
        'isPrimary': json['is_primary'] == null ? undefined : json['is_primary'],
    };
}

export function OrganizationLocationUpdateToJSON(value?: OrganizationLocationUpdate | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'organization_id': value['organizationId'],
        'description': value['description'],
        'address_1': value['address1'],
        'address_2': value['address2'],
        'city': value['city'],
        'state': value['state'],
        'zip': value['zip'],
        'phone': value['phone'],
        'enabled': value['enabled'],
        'is_primary': value['isPrimary'],
    };
}

