import { studyPlotData } from "../state/study.state"
import { ExclusionAnnotation } from "../types/exclusion.type"
import { getNotOverlappingTimeSeriesWithEventArray } from "../utils/seriesUtils"
import { TimeSeriesPoint } from "@huxley-medical/react-components/types"
import { atom, selector } from "recoil"

export const ecgExclusions = atom<ExclusionAnnotation[]>({
  key: "ecgExclusions",
  default: [],
})

export const ecgBadExclusions = atom<ExclusionAnnotation[]>({
  key: "ecgBadExclusions",
  default: [],
})

export const ppgExclusions = atom<ExclusionAnnotation[]>({
  key: "ppgExclusions",
  default: [],
})

export const allExclusions = selector<ExclusionAnnotation[]>({
  key: "allExclusions",
  get: ({ get }) => {
    const ecgEx = get(ecgExclusions).filter((exclusion) => !exclusion.removed)
    const ppgEx = get(ppgExclusions).filter((exclusion) => !exclusion.removed)
    return [...ecgEx, ...ppgEx]
  },
})

export const nonRemovedEcgExclusions = selector<ExclusionAnnotation[]>({
  key: "nonRemovedEcgExclusions",
  get: ({ get }) => {
    const ecgEx = get(ecgExclusions)
    return ecgEx.filter((exclusion) => !exclusion.removed)
  },
})

export const nonRemovedPpgExclusions = selector<ExclusionAnnotation[]>({
  key: "nonRemovedPpgExclusions",
  get: ({ get }) => {
    const ppgEx = get(ppgExclusions)
    return ppgEx.filter((exclusion) => !exclusion.removed)
  },
})

export const allNonRemovedExclusions = selector<ExclusionAnnotation[]>({
  key: "allNonRemovedExclusions",
  get: ({ get }) => {
    const allexcl = get(allExclusions)
    return allexcl.filter((exclusion) => !exclusion.removed)
  },
})

export const nonExcludedHr = selector<TimeSeriesPoint[]>({
  key: "nonExcludedHr",
  get: ({ get }) => {
    const hr = get(studyPlotData).hr
    const exclusions = get(allNonRemovedExclusions)
    return getNotOverlappingTimeSeriesWithEventArray(hr, exclusions)
  },
})
