import { Point, TimeSeriesPoint } from "@huxley-medical/react-components/types"

export function secToHours(seconds: number): number {
  return seconds / 3600
}

/**
 * Converts seconds to hours and minutes.
 * @param d - The number of seconds.
 * @returns The formatted string representing hours and minutes.
 */
export function secondsToHms(d: number) {
  d = Number(d)
  const h = Math.floor(d / 3600)
  const m = Math.floor((d % 3600) / 60)

  const hDisplay = h > 0 ? h + "h " : ""
  const mDisplay = m > 0 ? m + "m " : ""
  return hDisplay + mDisplay
}

/**
 * Converts an array of TimeSeriesPoint objects to an array of Point objects.
 * @param data The array of TimeSeriesPoint objects to convert.
 * @returns An array of Point objects.
 */
export const timeSeriesPointsToPoints = (data: TimeSeriesPoint[]): Point[] =>
  data.map((p) => ({ x: p.date.getTime(), y: p.value }))
