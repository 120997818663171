import { Group } from "@visx/group"
import { useEffect, useRef, useState } from "react"
import CancelIcon from "@mui/icons-material/Cancel"
import { LinearGradient } from "@visx/gradient"
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil"
import { useWindowDrag } from "@huxley-medical/react-components/hooks/mouse"
import { Point, TimeSeriesPoint } from "@huxley-medical/react-components/types"
import EventHandle from "./EventHandle"
import { handleEventDrag } from "../../../components/scoring/event/handlers/handleEventDrag"
import { eventMoveResize } from "../../../components/scoring/event/handlers/eventMoveResize"
//import { eventMakeSelected } from "../../../components/scoring/event/handlers/eventMakeSelected"
import { DynamicInterval } from "../../../utils/DynamicInterval"
import { relMouseCoords } from "@huxley-medical/react-components/utils"
import { snackAlert } from "../../SnackAlerts"
import { eventRemove } from "../StackedPlots"
import { useParams } from "react-router-dom"
import { ScrollIndicator } from "../scroll/ScrollIndicator"
import { useTooltip, useTooltipInPortal } from "@visx/tooltip"
import { ScorerEventData } from "../../../types/event.type"
import { DragThresholds } from "../../../types/handlers.type"
import {
  eventContextMenu,
  eventFamily,
  plotTypeFilterEventsData,
} from "../../../state/event.state"
import {
  fullNightInterval,
  intervalTimeEventTypeAtom,
} from "../../../state/study.state"
import {
  ResizeScrollMode,
  ScrollRoutineContext,
} from "../../../types/scroller.type"
import { currentEpoch, numEpochs } from "../../../state/epoch.state"
import {
  nonRemovedEcgExclusions,
  nonRemovedPpgExclusions,
} from "../../../state/exclusions.state"
import { ExclusionAnnotation } from "../../../types/exclusion.type"
import { disableGraph } from "../../../state/graph.state"
import { useScrollRoutineScheduler } from "../../../components/scoring/scroll/hooks"
import { currentUser } from "../../../state/auth.state"
import { StyledEventGroup } from "../../../styled/Group"
import { colorMap } from "../../../const/event.const"
import { StyledMoveText } from "../../../styled/Text"
import { StyledCircle } from "../../../styled/Circle"
import { ScaleLinear } from "d3-scale"
import { styled } from "@mui/joy/styles"
import { Bar } from "@visx/shape"

type EventParams = {
  height: number
  eventID: number
  parentWidth: number
  isSelected: boolean
  xOffset: number
  timeScale: ScaleLinear<number, number, never>
  data: TimeSeriesPoint[]
}

export const StyledEvent = styled(Bar)({
  stroke: "#2c2c2c",
  strokeWidth: "0px",
})

export const StyledTextBackground = styled(Bar)({
  strokeWidth: "0px",
  borderRadius: "0px",
})

const eventBorderWidth = 1.5

/**
 * Event represents the semi-transparent w/gradient event windows that are
 * drawn on plots. Can be dragged and resized by user. Also contains
 * context menu used to change event type or delete.
 *
 * @param {EventParams} eventParams
 * @returns {JSX.Element} JSX.Element
 */

const Event = ({
  eventID,
  height,
  parentWidth,
  isSelected,
  timeScale,
  xOffset,
  data,
}: EventParams): JSX.Element => {
  const [isResizing, setIsResizing] = useState(false)
  const [moveCSS, setMoveCSS] = useState<object>({})
  const [mouseStatus, setMouseStatus] = useState<boolean>(false)
  const [moveEventStart, setMoveEventStart] = useState<undefined | Point>(
    undefined
  )
  // Used for refreshing moveEventStart after scroll
  const [moveEventStartRel, setMoveEventStartRel] = useState<undefined | Point>(
    undefined
  )

  // Used for relative mouse coord calulations
  const innerRef = useRef<SVGRectElement>(null)

  // stateful ref for epoch scrolling edge cases in handleEventDrag
  const dragThresholds = useRef<DragThresholds>({
    brokeRightThreshold: false,
    brokeLeftThreshold: false,
  })

  // Used to open context menu
  const setContextMenu = useSetRecoilState(eventContextMenu)

  // Raw unsorted list of all events
  // required for setting recoil state (useRecoilCallback setters must
  // be passed current state)
  //const events = useRecoilValue(eventIDs)
  // The event data (id, type, range, etc)
  const eventData = useRecoilValue(eventFamily(eventID))
  const {
    event_ts,
    id,
    type,
    autogenerated,
    event_data,
    plot,
    removed,
    label,
  } = eventData

  // Convert to pixels for rendering
  const startPx = timeScale(event_ts[0])
  const endPx = timeScale(event_ts[1])
  const selectedTimeInterval = useRecoilValue(intervalTimeEventTypeAtom)
  const endInterval = endPx

  //if the event is a HR event set the pixel width to 5px
  const widthPx = Math.max(3, plot === "HR" ? 3 : endInterval - startPx)
  const range = [startPx, widthPx]

  // Setup event handlers that will update recoil state
  const handleEventMoveResize = useRecoilCallback(eventMoveResize)
  //const handleEventMakeSelected = useRecoilCallback(eventMakeSelected)
  const handleEventRemove = useRecoilCallback(eventRemove)
  const fullNightIntervalValue = useRecoilValue(fullNightInterval)
  const setNewRange = (newRangePx: number[]) => {
    handleEventMoveResize(eventData, timeScale, newRangePx, data)
  }
  const { studyID } = useParams()
  const allPlotEvents = useRecoilValue(
    plotTypeFilterEventsData({
      plotType: eventData.plot,
      studyID: studyID as string,
    })
  )

  const [scrollMode, setScrollMode] = useState<ResizeScrollMode>()
  const scrollRoutine = useRef<DynamicInterval>(new DynamicInterval())
  const [epoch, setCurrentEpoch] = useRecoilState(currentEpoch)
  const totalEpochs = useRecoilValue(numEpochs)
  const setSnackAlertMsg = useSetRecoilState(snackAlert)
  const ecgExclusionsData = useRecoilValue(nonRemovedEcgExclusions)
  const ppgExclusionsData = useRecoilValue(nonRemovedPpgExclusions)
  const exclusionData: ExclusionAnnotation[] =
    plot === "HR" ? ecgExclusionsData : ppgExclusionsData
  const disabledGraph = useRecoilValue(disableGraph)
  const [xMin, xMax] = timeScale.domain()
  const filteredPlotEvents = allPlotEvents.filter(
    (eventData: ScorerEventData) =>
      eventData.event_ts[1] > xMin && eventData.event_ts[0] < xMax
  )

  // Setup context for function run in setTimeout
  const scrollCtx: ScrollRoutineContext = {
    scrollMode,
    rangePx: range,
    currentEpoch: epoch,
    exclusionData,
    parentWidth,
    totalEpochs,
    timeScale,
    eventID,
    allPlotEvents: filteredPlotEvents,
    setSnackAlertMsg,
    setCurrentEpoch,
    setNewRange,
  }
  scrollRoutine.current.additional = scrollCtx
  // timer scheduler for scrolling epochs
  // activated by [scrollMode, moveEventStart]
  useScrollRoutineScheduler({
    scrollMode,
    moveEventStart,
    scrollRoutine,
    setScrollMode,
  })
  // Handle move/drag events, reposition event
  // will activate scroll routine scheduler above if dragged out of bounds
  useWindowDrag(
    {
      start: moveEventStart,
      el: innerRef.current,
      dragMove: handleEventDrag({
        range,
        parentWidth,
        data,
        widthPx,
        xOffset,
        moveEventStart,
        plot,
        eventID,
        allPlotEvents: filteredPlotEvents,
        timeScale,
        scrollRoutine,
        moveEventStartRel,
        dragThresholds,
        setSnackAlertMsg,
        setMoveEventStart,
        setNewRange,
        setScrollMode,
      }),
      dragEnd: () => {
        // Set moveEventStart for mouseUp
        setMoveEventStart(undefined)

        // reset stateful ref for epoch scrolling edge cases in handleEventDrag
        dragThresholds.current.brokeRightThreshold = false
        dragThresholds.current.brokeLeftThreshold = false
      },
    },
    [filteredPlotEvents.length]
  )

  const shouldShowControls = moveEventStart !== undefined || isResizing
  const textWidthPixelRatio = 6.7
  const eventTypeTextWidth = type.length * textWidthPixelRatio

  const numSeconds = Math.round(((event_ts[1] - event_ts[0]) / 1000) * 10) / 10
  const { showTooltip, hideTooltip, tooltipOpen, tooltipLeft, tooltipTop } =
    useTooltip()
  const { containerRef, TooltipInPortal } = useTooltipInPortal()
  const handleMouseEnter = (
    event: React.MouseEvent<SVGElement>,
    datum: ScorerEventData
  ) => {
    showTooltip({
      tooltipLeft: event.pageX,
      tooltipTop: event.pageY,
    })
  }

  const handleMouseLeave = () => {
    hideTooltip()
  }
  const user = useRecoilValue(currentUser)
  const ecgLabel = (startTime: number, endTime: number) => {
    const duration = Math.abs(endTime - startTime)
    const timeUnits = [
      { value: Math.floor(duration / (1000 * 60 * 60)), label: "hours" },
      {
        value: Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60)),
        label: "minutes",
      },
      { value: Math.floor((duration % (1000 * 60)) / 1000), label: "seconds" },
    ]
    const result = timeUnits
      .filter((unit) => unit.value > 0)
      .map((unit) => `${unit.value} ${unit.label}`)
      .join(" ")

    return (
      <>
        <strong>Start time: </strong>
        {new Date(startTime).toLocaleTimeString()}
        <br />
        <strong>End time: </strong>
        {new Date(endTime).toLocaleTimeString()}
        <br />
        <strong>Duration: </strong>
        {result}
        <br />
      </>
    )
  }

  useEffect(() => {
    if (fullNightIntervalValue === 0 && selectedTimeInterval === 0) return

    setMouseStatus(
      fullNightIntervalValue !== selectedTimeInterval ? true : false
    )
    setMoveCSS(
      fullNightIntervalValue !== selectedTimeInterval &&
        !disabledGraph &&
        plot !== "ECG"
        ? {
            cursor: "move",
          }
        : {
            cursor: "pointer",
          }
    )
  }, [fullNightIntervalValue, selectedTimeInterval, disabledGraph])

  if (removed) {
    return <></>
  }

  return (
    <>
      <StyledEventGroup
        sx={{
          // Keep controls if hovering over event
          "&:hover .close-icon, &:hover .resize-icon": {
            display: "block",
          },
          // Keep controls if dragging or resizing
          ...(shouldShowControls
            ? {
                ".close-icon, .resize-icon": {
                  display: "block",
                },
              }
            : {}),
          ...moveCSS,
        }}
        left={range[0]}
        className="event"
        onMouseEnter={(event) => handleMouseEnter(event, eventData)}
        onMouseLeave={handleMouseLeave}
        onMouseMove={(event) => handleMouseEnter(event, eventData)}
      >
        <Group
          onMouseDown={(e) => {
            if (disabledGraph || !mouseStatus) return
            // left click
            if (e.nativeEvent.button === 0) {
              e.stopPropagation()
              // stopPropagation is needed to prevent drawing a new event
              // when this is a move. But we still need to clear the selected
              // context menu element from parent, that would normally be
              // cleared via propagation
              setContextMenu(undefined)
              setMoveEventStart({ x: e.pageX, y: e.pageY })

              // Used for refreshing event start after scroll
              setMoveEventStartRel(relMouseCoords(e, e.target as Element))
            }

            // stack element on top
            // which makes isSelected === true
            //handleEventMakeSelected(eventID, events)
          }}
          onContextMenu={(e) => {
            if (!mouseStatus) return
            e.preventDefault()
            setContextMenu({ eventData, mouseEvent: e })
          }}
        >
          <LinearGradient
            vertical
            fromOffset="95%"
            from={colorMap[type]}
            fromOpacity={0.4}
            to={colorMap[type]}
            toOffset="100%"
            toOpacity={0.4}
            id={`${id}`}
          />
          <StyledEvent
            fill={`url(#${id})`}
            innerRef={innerRef}
            height={type === "Beats" ? 0.7 * height : height - 1}
            width={widthPx}
            y={type === "Beats" ? 0.3 * height : 0}
            rx={0}
            sx={{
              // Set border highlight if this event is selected
              ...(isSelected
                ? {
                    stroke: colorMap[type],
                  }
                : {}),
              ...moveCSS,
            }}
          />
          {fullNightIntervalValue !== selectedTimeInterval &&
            !disabledGraph &&
            eventData.plot === "SpO2" && (
              <>
                <EventHandle
                  className="left-resize-handle"
                  height={height}
                  orientation="left"
                  plotData={data}
                  x={eventBorderWidth * -1}
                  range={range}
                  timeScale={timeScale}
                  eventData={eventData}
                  parentWidth={parentWidth}
                  eventWidth={widthPx}
                  isDragging={setIsResizing}
                  xOffset={xOffset}
                  scrollMode={scrollMode}
                  setScrollMode={setScrollMode}
                />
                <EventHandle
                  className="right-resize-handle"
                  height={height}
                  x={
                    range[1] -
                    (widthPx <= 5 ? widthPx / 2 : 15) +
                    eventBorderWidth
                  }
                  orientation="right"
                  plotData={data}
                  range={range}
                  timeScale={timeScale}
                  eventData={eventData}
                  parentWidth={parentWidth}
                  eventWidth={widthPx}
                  isDragging={setIsResizing}
                  xOffset={xOffset}
                  scrollMode={scrollMode}
                  setScrollMode={setScrollMode}
                />
              </>
            )}
          <Group>
            <StyledTextBackground
              x={
                type === "Beats"
                  ? range[1] - 35
                  : range[1] / 2 - (eventTypeTextWidth + 10) / 2
              }
              y={type === "Beats" ? height - 15 : 0}
              rx={type === "Beats" ? 2 : 3}
              fill={colorMap[type]}
              height={type === "Beats" ? 14 : 18}
              width={
                type === "Beats"
                  ? eventTypeTextWidth - 2
                  : eventTypeTextWidth + 8
              }
            />
            <StyledMoveText
              textAnchor={type === "Beats" ? "start" : "middle"}
              fontWeight="bold"
              fontSize={11}
              fill="#fff"
              sx={{
                ...(fullNightIntervalValue !== selectedTimeInterval &&
                !disabledGraph &&
                widthPx < 5
                  ? {
                      cursor: "pointer",
                    }
                  : {}),
              }}
              x={
                type === "Beats"
                  ? eventData?.label === "SVEB"
                    ? -31
                    : -27
                  : range[1] / 2
              }
              y={type === "Beats" ? height - 4.5 : 12}
              cursor="pointer"
            >
              {["Beats", "Rhythms"].includes(type) ? eventData?.label : type}
            </StyledMoveText>
          </Group>

          {((range[1] > 75 && plot === "SpO2") || plot === "HR") && (
            <StyledMoveText
              textAnchor="start"
              fontSize={11}
              fontWeight="bold"
              fill={colorMap[type]}
              x={5}
              y={height - 10}
              cursor="pointer"
            >
              {autogenerated ? `Auto` : `Manual`}
            </StyledMoveText>
          )}

          {range[1] > 40 && plot === "SpO2" && (
            <StyledMoveText
              textAnchor="end"
              fontSize={11}
              fontWeight="bold"
              fill={colorMap[type]}
              x={range[1] - 5}
              y={height - 10}
            >
              {`${(
                (event_data?.max_spo2 || 0) - (event_data?.min_spo2 || 0)
              ).toFixed(1)}%`}
            </StyledMoveText>
          )}
        </Group>
        <ScrollIndicator
          height={height}
          scrollMode={scrollMode}
          width={range[1]}
          color={colorMap[type]}
        />
        {fullNightIntervalValue !== selectedTimeInterval && !disabledGraph && (
          <Group
            onMouseDown={(e) => {
              // Prevent drawing a new event
              e.stopPropagation()
            }}
            onClick={handleEventRemove(eventID, studyID, eventData, user?.uuid)}
            left={type === "Beats" ? 0 : range[1] / 2 + eventTypeTextWidth / 2}
            top={type === "Beats" ? height - 16 : 0}
            style={{ cursor: "pointer" }}
          >
            <StyledCircle
              className="close-icon"
              sx={{ display: "none", fill: "#FFF" }}
              cx={7.5}
              cy={7.5}
              r={6}
            />
            <CancelIcon
              className="close-icon"
              sx={{ display: "none", color: "#a03434" }}
              width={15}
              height={15}
            />
          </Group>
        )}
      </StyledEventGroup>
      <div ref={containerRef} style={{ position: "relative" }}>
        {tooltipOpen && plot === "SpO2" && (
          <TooltipInPortal
            //key={Math.random()}
            top={tooltipTop}
            left={tooltipLeft}
          >
            <strong>Min SpO2: </strong>
            {`${event_data?.min_spo2?.toFixed(1)}`}%
            <br />
            <strong>Max SpO2: </strong>
            {`${event_data?.max_spo2?.toFixed(1)}`}%
            <br />
            <strong>Desat Time: </strong>
            {`${numSeconds}sec`}
            <br />
            <strong>Desat. Depth: </strong>
            {`${(
              (event_data?.max_spo2 || 0) - (event_data?.min_spo2 || 0)
            ).toFixed(1)}%`}
            %
            <br />
            <strong>Type: </strong>
            {autogenerated ? `Auto` : `Manual`}
            <br />
          </TooltipInPortal>
        )}
        )
        {tooltipOpen && plot === "ECG" && (
          <TooltipInPortal
            //key={Math.random()}
            top={tooltipTop}
            left={tooltipLeft}
          >
            <strong>{type === "Beats" ? "Beat:" : "Rhythm:"}</strong>
            {label}
            <br />
            {type === "Rhythms" && <>{ecgLabel(event_ts[0], event_ts[1])}</>}
          </TooltipInPortal>
        )}
      </div>
    </>
  )
}

export default Event
