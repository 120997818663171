import { NumberValue } from "d3-scale"
import { StudyPlots } from "../interfaces/study.interface"
import { LinePlot } from "../types/line.type"
import { PositionMap, SleepStageMap } from "../const/signals.const"
import { ColorPaletteProp } from "@mui/joy/styles/types/colorSystem"
import { GetAllStudyOrdersStudyStatusesEnum } from "../generated/fetchclient"

export const studyDesatThresholdOptions = [
  { label: "3%", value: "three" },
  { label: "4%", value: "four" },
  { label: "Both", value: "both" },
]

export const states = [
  "AK",
  "AL",
  "AR",
  "AZ",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MI",
  "MN",
  "MO",
  "MS",
  "MT",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY",
]

export enum StudyStatus {
  ORDERED = "Ordered",
  IN_PROGRESS = "In progress",
  AWAITING_UPLOAD = "Awaiting upload",
  UPLOADED = "Uploaded",
  PROCESSING = "Processing",
  PROCESSING_CONVERTING = "Processing - converting",
  PROCESSING_CONVERTING_COMPLETED = "Processing - converting completed",
  PROCESSING_GENERATING_STUDY = "Processing - generating study",
  PROCESSING_FAILED = "Processing - failed",
  PROCESSED = "Processed",
  READY_FOR_REVIEW = "Ready for review",
  READY_FOR_INTERPRETATION = "Ready for interpretation",
  COMPLETE = "Complete",
  ABORTED = "Aborted",
  REJECTED = "Rejected",
}

export enum SimplifiedStudyStatus {
  ORDERED = "Ordered",
  IN_PROGRESS = "In progress",
  READY_FOR_REVIEW = "Ready for review",
  READY_FOR_INTERPRETATION = "Ready for interpretation",
  COMPLETE = "Complete",
  REJECTED = "Rejected",
}

export const InProgressStudyStatuses = [
  StudyStatus.IN_PROGRESS,
  StudyStatus.AWAITING_UPLOAD,
  StudyStatus.UPLOADED,
  StudyStatus.PROCESSING,
  StudyStatus.PROCESSING_CONVERTING,
  StudyStatus.PROCESSING_CONVERTING_COMPLETED,
  StudyStatus.PROCESSING_GENERATING_STUDY,
  StudyStatus.PROCESSING_FAILED,
  StudyStatus.PROCESSED,
  StudyStatus.ABORTED,
]

export const statusStrToEnum: { [key: string]: StudyStatus } = {
  OD: StudyStatus.ORDERED,
  IP: StudyStatus.IN_PROGRESS,
  AU: StudyStatus.AWAITING_UPLOAD,
  UP: StudyStatus.UPLOADED,
  PG: StudyStatus.PROCESSING,
  CV: StudyStatus.PROCESSING_CONVERTING,
  CC: StudyStatus.PROCESSING_CONVERTING_COMPLETED,
  GC: StudyStatus.PROCESSING_GENERATING_STUDY,
  FA: StudyStatus.PROCESSING_FAILED,
  PC: StudyStatus.PROCESSED,
  RR: StudyStatus.READY_FOR_REVIEW,
  RI: StudyStatus.READY_FOR_INTERPRETATION,
  CE: StudyStatus.COMPLETE,
  AB: StudyStatus.ABORTED,
  RJ: StudyStatus.REJECTED,
}

export const StatusTextToStr: {
  [key: string]: GetAllStudyOrdersStudyStatusesEnum
} = {
  Ordered: "OD",
  "In progress": "IP",
  "Awaiting upload": "AU",
  Uploaded: "UP",
  Processing: "PG",
  "Processing - converting": "CV",
  "Processing - converting completed": "CC",
  "Processing - generating study": "GC",
  "Processing - failed": "FA",
  Processed: "PC",
  "Ready for review": "RR",
  "Ready for interpretation": "RI",
  Complete: "CE",
  Aborted: "AB",
  Rejected: "RJ",
}

export function generatePlotData({
  respiratoryeffortPlot,
  snorePlot,
  spo2Plot,
  cardiacPlot,
  actigraphyPlot,
  chestPlot,
  positionPlot,
  sleepPlot,
  ecgPlot,
}: StudyPlots): LinePlot[] {
  return [
    {
      title: "ECG",
      data: ecgPlot,
      lineColor: "#cb2f2f",
      plotType: "ECG",
      unit: "mV",
    },
    {
      title: "Snoring",
      data: snorePlot,
      lineColor: "#5A97F7",
      plotType: "Snoring",
      unit: "",
    },
    {
      title: "Resp. Effort",
      data: respiratoryeffortPlot,
      lineColor: "#5A97F7",
      plotType: "Resp",
      unit: "",
    },
    {
      title: "SpO2",
      data: spo2Plot,
      lineColor: "#5A97F7",
      plotType: "SpO2",
      unit: "% SpO2",
    },
    {
      title: "HR",
      data: cardiacPlot,
      lineColor: "#cb2f2f",
      plotType: "HR",
      unit: "BPM",
    },
    {
      title: "Chest",
      data: chestPlot,
      lineColor: "#e0be66",
      plotType: "Chest",
      yDomainPadding: 0.01,
      unit: "m/s^2",
    },
    {
      title: "Actigraphy",
      data: actigraphyPlot,
      lineColor: "#e0be66",
      plotType: "Actigraphy",
      yDomainPadding: 15,
      unit: "m/s^2",
    },
    {
      title: "Position",
      data: positionPlot,
      lineColor: "#2b2b2b",
      plotType: "Position",
      yTickValues: Object.values(PositionMap).map(
        (positionType, _) => positionType
      ),
      yTickFormatter: (num: NumberValue) => {
        const positionKey = Object.entries(PositionMap).find(
          ([_, val]) => val === num
        )
        return positionKey ? positionKey[0].charAt(0) : "?"
      },
      yDomainPadding: 15,
      unit: "position",
      plotLine: "step-line",
    },
    {
      title: "Sleep",
      data: sleepPlot,
      lineColor: "#2b2b2b",
      plotType: "SANSASleepStage",
      yTickValues: Object.keys(SleepStageMap).map((_, inx) => inx),
      yTickFormatter: (num: NumberValue) => {
        return Object.keys(SleepStageMap)[num.valueOf()] ?? "?"
      },
      yDomainPadding: 15,
      unit: "",
      plotLine: "step-line",
    },
  ]
}

export const getStudyStatusColorAndText = (
  status: StudyStatus
): {
  color: ColorPaletteProp
  text: string
} => {
  const statusTextMap = {
    [StudyStatus.COMPLETE]: { color: "success", text: "Complete" },
    [StudyStatus.READY_FOR_INTERPRETATION]: {
      color: "primary",
      text: "Interpret",
    },
    [StudyStatus.READY_FOR_REVIEW]: { color: "primary", text: "Ready" },
    [StudyStatus.ORDERED]: { color: "primary", text: "Ordered" },
    [StudyStatus.IN_PROGRESS]: { color: "neutral", text: "In Progress" },
    [StudyStatus.AWAITING_UPLOAD]: { color: "neutral", text: "In Progress" },
    [StudyStatus.UPLOADED]: { color: "neutral", text: "In Progress" },
    [StudyStatus.PROCESSING]: { color: "neutral", text: "In Progress" },
    [StudyStatus.PROCESSING_CONVERTING]: {
      color: "neutral",
      text: "In Progress",
    },
    [StudyStatus.PROCESSING_CONVERTING_COMPLETED]: {
      color: "neutral",
      text: "In Progress",
    },
    [StudyStatus.PROCESSING_GENERATING_STUDY]: {
      color: "neutral",
      text: "In Progress",
    },
    [StudyStatus.PROCESSING_FAILED]: {
      color: "neutral",
      text: "In Progress",
    },
    [StudyStatus.PROCESSED]: { color: "neutral", text: "In Progress" },
    [StudyStatus.ABORTED]: { color: "neutral", text: "In Progress" },
    [StudyStatus.REJECTED]: { color: "danger", text: "Rejected" },
    default: { color: "neutral", text: "Unknown Status" },
  }

  const statusData = statusTextMap[status] || statusTextMap.default

  return {
    color: statusData.color as ColorPaletteProp,
    text: statusData.text,
  }
}

export const studyStatusValues = () =>
  (Object.values(StudyStatus) as Array<StudyStatus>).map(
    (status: StudyStatus) => ({ value: status, label: status })
  )

export function simplifyStudyStatus(status: StudyStatus) {
  if (InProgressStudyStatuses.includes(status)) {
    return StudyStatus.IN_PROGRESS
  }

  return status
}
