import { useRef, useState } from "react"
import { Group } from "@visx/group"
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil"
import { useWindowDrag } from "@huxley-medical/react-components/hooks/mouse"
import { relMouseCoords } from "@huxley-medical/react-components/utils"
import Event from "../../../components/scoring/event/index"
import { Point, TimeSeriesPoint } from "@huxley-medical/react-components/types"
import { NumberValue, ScaleLinear } from "d3-scale"
import { useParams } from "react-router-dom"
import { DynamicInterval } from "../../../utils/DynamicInterval"
import {
  drawEventInProgressFamily,
  eventFamily,
  eventIDs,
  plotTypeFilterEventsData,
  plotTypeTimeFilter,
} from "../../../state/event.state"
import { currentEpoch, numEpochs } from "../../../state/epoch.state"
import {
  fullNightInterval,
  intervalTimeEventTypeAtom,
} from "../../../state/study.state"
import {
  nonRemovedEcgExclusions,
  nonRemovedPpgExclusions,
} from "../../../state/exclusions.state"
import { disableGraph } from "../../../state/graph.state"
import { currentUser } from "../../../state/auth.state"
import {
  ResizeScrollMode,
  ScrollRoutineContext,
} from "../../../types/scroller.type"
import { ExclusionAnnotation } from "../../../types/exclusion.type"
import { snackAlert } from "../../../components/SnackAlerts"
import { EventPlot, ScorerEventData } from "../../../types/event.type"
import { useScrollRoutineScheduler } from "../../../components/scoring/scroll/hooks"
import { ScrollIndicator } from "../../../components/scoring/scroll/ScrollIndicator"
import handleCreateEventSelection from "./handlers/handleCreateEventSelection"
import handleCreateEvent from "./handlers/handleCreateEvent"
import handleClickCreateEvent from "./handlers/handleClickCreateEvent"
import setNewRangeCallback from "./handlers/setNewRangeCallback"
import { TickFormatter } from "@visx/axis"
import { styled } from "@mui/joy/styles"
import { Bar } from "@visx/shape"

type PlotEventsParams = {
  height: number
  width: number
  children?: React.ReactNode
  timeScale: ScaleLinear<number, number, never>
  plot: EventPlot
  data: TimeSeriesPoint[]
  unit: string
  xOffset: number
  yTickFormat?: TickFormatter<NumberValue> | undefined
  yScale: ScaleLinear<number, number, never>
  innerRef: React.RefObject<SVGRectElement>
}

export const StyledDrawEvent = styled(Bar)({
  fill: "rgba(0,0,0, 0.1)",
  stroke: "#000",
  strokeWidth: "0px",
})

/**
 * PlotEvents is the transparent overlay drawn on top of StackableLinePlots.
 * Used to handle the drawing of new events, and render the visible event
 * windows on top of the plots.
 *
 * @param {PlotEventsParams} plotEventsParams
 * @returns {JSX.Element} JSX.Element
 */
const PlotEvents = ({
  height,
  width,
  children,
  timeScale,
  plot,
  xOffset,
  data,
  innerRef,
  unit,
  yScale,
  yTickFormat,
}: PlotEventsParams): JSX.Element => {
  // Raw unsorted list of event IDs
  const events = useRecoilValue(eventIDs)

  const { studyID } = useParams()

  // EventData[] of events filtered by plot (SpO2, HR, etc)
  const allPlotEvents = useRecoilValue(
    plotTypeFilterEventsData({
      plotType: plot,
      studyID: studyID as string,
      // timeScaleDomain: timeScale.domain(),
    })
  )

  const [drawEventStart, setDrawEventStart] = useState<undefined | Point>(
    undefined
  )
  // Use DrawnRange since it's interoperable with other scrolling
  const [drawEventInProgress, setDrawEventInProgress] = useRecoilState(
    drawEventInProgressFamily(plot)
  )

  // Convert to pixels for rendering
  const startPx = timeScale(drawEventInProgress?.[0] ?? 0)
  const endPx = timeScale(drawEventInProgress?.[1] ?? 0)
  const widthPx = endPx - startPx
  // Relative pixel values for new event range
  const rangePx = [startPx, widthPx]

  // New px ranges must be converted to absolute time
  const setNewRange = useRecoilCallback(
    setNewRangeCallback({ plot, timeScale })
  )
  // State and refs for scroll mode
  const [scrollMode, setScrollMode] = useState<ResizeScrollMode>()
  const scrollRoutine = useRef<DynamicInterval>(new DynamicInterval())
  const [epoch, setCurrentEpoch] = useRecoilState(currentEpoch)
  const totalEpochs = useRecoilValue(numEpochs)
  const setSnackAlertMsg = useSetRecoilState(snackAlert)
  const fullNightIntervalValue = useRecoilValue(fullNightInterval)
  const selectedTimeInterval = useRecoilValue(intervalTimeEventTypeAtom)
  const ecgExclusionsData = useRecoilValue(nonRemovedEcgExclusions)
  const ppgExclusionsData = useRecoilValue(nonRemovedPpgExclusions)
  const disabledGraph = useRecoilValue(disableGraph)
  const currentUserData = useRecoilValue(currentUser)
  const exclusionData: ExclusionAnnotation[] =
    plot === "HR" ? ecgExclusionsData : plot === "SpO2" ? ppgExclusionsData : []
  const [xMin, xMax] = timeScale.domain()
  const filteredExclusions = exclusionData.filter(
    (exData) => exData.endTS * 1000 > xMin && exData.startTS * 1000 < xMax
  )
  const filteredPlotEvents = allPlotEvents.filter(
    (eventData: ScorerEventData) =>
      eventData.event_ts[1] > xMin && eventData.event_ts[0] < xMax
  )
  const createEvent = useRecoilCallback(
    ({ set }) =>
      async (newEvent: ScorerEventData) => {
        await Promise.all([set(eventFamily(newEvent.id), newEvent)])
        set(eventIDs, (oldEventIDs) => [...oldEventIDs, newEvent.id])
      }
  )
  // DRY end draw state modifications
  const endDraw = () => {
    setDrawEventStart(undefined)
    setDrawEventInProgress(undefined)
  }

  // Setup context for function run in setTimeout
  const scrollCtx: ScrollRoutineContext = {
    scrollMode,
    parentWidth: width,
    totalEpochs,
    timeScale,
    rangePx,
    exclusionData: filteredExclusions,
    currentEpoch: epoch,
    allPlotEvents: filteredPlotEvents,
    setNewRange,
    setSnackAlertMsg,
    setCurrentEpoch,
  }
  scrollRoutine.current.additional = scrollCtx

  // timer scheduler for scrolling epochs
  // activated by [scrollMode, moveEventStart]
  useScrollRoutineScheduler({
    scrollMode,
    moveEventStart: drawEventStart,
    scrollRoutine,
    setScrollMode,
  })

  // Handles drawing new events
  useWindowDrag(
    {
      start: drawEventStart,
      el: innerRef.current,
      dragMove: handleCreateEventSelection({
        plotWidth: width,
        drawEventStart,
        scrollRoutine,
        setNewRange,
        setDrawEventStart,
        setScrollMode,
        setSnackAlertMsg,
      }),
      dragEnd: handleCreateEvent({
        plot,
        width,
        allPlotEvents: filteredPlotEvents,
        scrollRoutine,
        hrExclusionData: ecgExclusionsData,
        plotData: data,
        studyID: studyID as string,
        currentUserData,
        endDraw,
        createEvent,
      }),
    },
    [filteredPlotEvents.length]
  )

  // events contains data for all plot types, so we filter for the applicable
  // one
  const filteredEvents = useRecoilValue<number[]>(
    plotTypeTimeFilter({
      plotType: plot,
      timeScaleDomain: timeScale.domain(),
      studyID: studyID as string,
    })
  )

  return (
    <Group
      className="plot-events"
      onMouseDown={(e) => {
        //block events if full night interval is selected
        if (fullNightIntervalValue === selectedTimeInterval || disabledGraph) {
          return
        }

        //get the mouse coordinates relative to the plot
        const mouseCoordinates = relMouseCoords(e, innerRef.current as Element)

        //left click event handler
        if (e.nativeEvent.button === 0 && plot === "SpO2") {
          //set the start of the draw event since SPO2 allows click and drag of ranged event
          setDrawEventStart(mouseCoordinates)
        } else if (e.nativeEvent.button === 0 && plot === "HR") {
          //for resp events we create the event immediately
          handleClickCreateEvent({
            mouseCoordinates,
            plot,
            width,
            allPlotEvents: filteredPlotEvents,
            scrollRoutine,
            plotData: data,
            currentUserData,
            studyID: studyID as string,
            endDraw,
            createEvent,
          })
        }
      }}
    >
      {children /* grid rows, cols, and line path */}
      {events &&
        filteredEvents.map((eventID) => {
          return (
            <Event
              eventID={eventID}
              xOffset={xOffset}
              parentWidth={width}
              key={eventID}
              height={height}
              isSelected={events[events.length - 1] === eventID}
              timeScale={timeScale}
              data={data}
            />
          )
        })}

      <ScrollIndicator
        height={height}
        scrollMode={scrollMode}
        width={width}
        color="rgba(0,0,0, 0.3)"
      />

      {drawEventInProgress !== undefined && (
        <StyledDrawEvent
          sx={{ fill: "rgba(0,0,0, 0.3)" }}
          height={height}
          x={startPx}
          width={widthPx}
          pointerEvents="none"
        />
      )}
    </Group>
  )
}

export default PlotEvents
