/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DeviceOut } from './DeviceOut';
import {
    DeviceOutFromJSON,
    DeviceOutFromJSONTyped,
    DeviceOutToJSON,
} from './DeviceOut';

/**
 * 
 * @export
 * @interface PagedDeviceOut
 */
export interface PagedDeviceOut {
    /**
     * 
     * @type {Array<DeviceOut>}
     * @memberof PagedDeviceOut
     */
    items: Array<DeviceOut>;
    /**
     * 
     * @type {number}
     * @memberof PagedDeviceOut
     */
    count: number;
}

/**
 * Check if a given object implements the PagedDeviceOut interface.
 */
export function instanceOfPagedDeviceOut(value: object): boolean {
    if (!('items' in value)) return false;
    if (!('count' in value)) return false;
    return true;
}

export function PagedDeviceOutFromJSON(json: any): PagedDeviceOut {
    return PagedDeviceOutFromJSONTyped(json, false);
}

export function PagedDeviceOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagedDeviceOut {
    if (json == null) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(DeviceOutFromJSON)),
        'count': json['count'],
    };
}

export function PagedDeviceOutToJSON(value?: PagedDeviceOut | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': ((value['items'] as Array<any>).map(DeviceOutToJSON)),
        'count': value['count'],
    };
}

