/* eslint-disable prettier/prettier */
import { ScorerEventData } from "../../../types/event.type"
import { ExclusionAnnotation } from "../../../types/exclusion.type"
import { ScaleLinear } from "d3-scale"

export type EventIntersectorParams = {
  eventID?: number
  widthPx: number
  parentWidth: number
  allPlotEvents: ScorerEventData[]
  exclusions: ExclusionAnnotation[]
  timeScale: ScaleLinear<number, number, never>
  reCheck?: boolean
}

export type IntersectorResult = {
  suggestedRange: number[] | undefined
  intersectedWith: ScorerEventData | ExclusionAnnotation | undefined
}

/**
 * eventIntersector will detect intersections and return
 * the applicable intersected EventData and a suggested
 * repositioning range that won't intersect with other events.
 *
 * If the event is entirley contained in another event, entirley
 * contains another event, or goes off plot: intersectedWith
 * and suggestedRange of IntersectorResult will both be undefined.
 *
 * TODO: differentiate between off plot and contained conditions.
 *
 * @param {EventIntersectorParams} eventIntersectorParams
 * @returns
 */
export const eventIntersector =
  ({
    eventID,
    widthPx,
    parentWidth,
    allPlotEvents,
    exclusions,
    timeScale,
    reCheck = false,
  }: EventIntersectorParams) =>
    (newStartPx: number, newEndPx: number): IntersectorResult => {
      const noSuggestedRange = {
        suggestedRange: undefined,
        intersectedWith: undefined,
      }

      let start = newStartPx
      let end = newEndPx
      /*const exclusionDataValue = exclusions.filter(
                      (exclusion) => exclusion.removed === null
                    )*/

      // Get the time values from px
      const scaledStart = timeScale.invert(start)
      const scaledEnd = timeScale.invert(end)

      let intersectedWith: ScorerEventData | ExclusionAnnotation | undefined

      for (let i = 0; i < allPlotEvents.length; i += 1) {
        const otherEvent = allPlotEvents[i]

        // Skip detection with self
        if (otherEvent.id === eventID) break

        // Detect if other event is contained within
        const innerContained =
          scaledStart >= otherEvent.event_ts[0] &&
          scaledEnd <= otherEvent.event_ts[1]

        // Detect if other event contains event
        const outerContained =
          scaledStart <= otherEvent.event_ts[0] &&
          scaledEnd >= otherEvent.event_ts[1]

        // Return if contained condition is hit, no need for re-checking
        // new suggested range
        if (outerContained || innerContained)
          return {
            intersectedWith: otherEvent,
            suggestedRange: undefined,
          }

        // Detect left intersections
        const leftIntersects =
          scaledStart > otherEvent.event_ts[0] &&
          scaledStart < otherEvent.event_ts[1]

        // Compute new suggested values
        if (leftIntersects) {
          intersectedWith = otherEvent
          start = timeScale(otherEvent.event_ts[1])
          end = start + widthPx

          // Return if off-plot condition is hit, no need for re-checking
          // new suggested range
          if (end > parentWidth) {
            return {
              intersectedWith,
              suggestedRange: undefined,
            }
          }
          break
        }

        // Detect right intersections
        const rightIntersects =
          scaledEnd > otherEvent.event_ts[0] && scaledEnd < otherEvent.event_ts[1]

        // Compute new suggested values
        if (rightIntersects) {
          intersectedWith = otherEvent
          end = timeScale(otherEvent.event_ts[0])
          start = end - widthPx

          // Return if off-plot condition is hit, no need for re-checking
          // new suggested range
          if (start < 0) {
            return {
              intersectedWith,
              suggestedRange: undefined,
            }
          }
          break
        }
      }

      for (let i = 0; i < exclusions.length; i++) {
        const exclusionData = exclusions[i]
        // Detect if exclusion is contained within
        const innerContained =
          scaledStart >= exclusionData.startTS * 1000 &&
          scaledEnd <= exclusionData.endTS * 1000

        // Detect if exclusion contains event
        const outerContained =
          scaledStart <= exclusionData.startTS * 1000 &&
          scaledEnd >= exclusionData.endTS * 1000

        if (outerContained || innerContained)
          return {
            intersectedWith: exclusionData,
            suggestedRange: undefined,
          }

        // Detect left intersections
        const leftIntersects =
          scaledStart > exclusionData.startTS * 1000 &&
          scaledStart < exclusionData.endTS * 1000

        // Compute new suggested values
        if (leftIntersects) {
          intersectedWith = exclusionData
          start = timeScale(exclusionData.endTS * 1000)
          end = start + widthPx

          // Return if off-plot condition is hit, no need for re-checking
          // new suggested range
          if (end > parentWidth) {
            return {
              intersectedWith,
              suggestedRange: undefined,
            }
          }
          break
        }

        // Detect right intersections
        const rightIntersects =
          scaledEnd > exclusionData.startTS * 1000 &&
          scaledEnd < exclusionData.endTS * 1000

        // Compute new suggested values
        if (rightIntersects) {
          intersectedWith = exclusionData
          end = timeScale(exclusionData.startTS * 1000)
          start = end - widthPx

          // Return if off-plot condition is hit, no need for re-checking
          // new suggested range
          if (start < 0) {
            return {
              intersectedWith,
              suggestedRange: undefined,
            }
          }
          break
        }
      }

      // Recheck suggested range
      if (intersectedWith !== undefined && !reCheck) {
        // There was an intersection that could require repositioning, check to
        // make sure resposition doesn't intersect with others. repositioning
        // ensures no gap between potentially intersecting windows
        const intersectAgain = eventIntersector({
          eventID,
          widthPx,
          parentWidth,
          exclusions,
          allPlotEvents,
          timeScale,
          reCheck: true,
        })(start, end)

        // repositioning location intersects with another, no suggested range
        if (intersectAgain.intersectedWith !== undefined)
          return {
            intersectedWith,
            suggestedRange: undefined,
          }

        // repositioning location goes off grid, do nothing
        if (intersectAgain.suggestedRange === undefined) {
          return noSuggestedRange
        }
      }

      return {
        intersectedWith,
        suggestedRange: [start, end],
      }
    }

/**
 * findEventIntersectStart given a list of allPlotEvents and the eventID
 * that's being resized, return the x px value of where the resized event
 * intersected another event's start.
 *
 * @param allPlotEvents
 * @param timeScale
 * @param eventID
 * @param start
 * @param end
 * @returns {number | undefined} intersectedStartScaledXPx
 */
export const findEventIntersectStart = (
  allPlotEvents: ScorerEventData[],
  exclusions: ExclusionAnnotation[],
  timeScale: ScaleLinear<number, number, never>,
  eventID: number | undefined,
  start: number,
  end: number
): number | undefined => {
  const event = allPlotEvents
    // remove own ID
    .filter((eD) => eD.id !== eventID)
    // apply time scale
    .map((eD) => timeScale(eD.event_ts[0]))
    // only look for events that are after this one
    .filter((eventStart) => eventStart > start)
    // order matters to find the first one for repositioning
    .sort((es1, es2) => es1 - es2)
    .find((eventStart) => end > eventStart)

  const exclusion = exclusions
    .map((eD) => timeScale(eD.startTS * 1000))
    .filter((exclusionStart) => exclusionStart > start)
    .sort((es1, es2) => es1 - es2)
    .find((exclusionStart) => end > exclusionStart)

  const intersectedData =
    event !== undefined && exclusion !== undefined
      ? event >= exclusion
        ? event
        : exclusion
      : event ?? exclusion

  return intersectedData
}

/**
 * findEventIntersectEnd given a list of allPlotEvents and the eventID
 * that's being resized, return the x px value of where the resized event
 * intersected another event's end.
 *
 * @param allPlotEvents
 * @param timeScale
 * @param eventID
 * @param start
 * @param end
 * @returns {number | undefined} intersectedEndScaledXPx
 */
export const findEventIntersectEnd = (
  allPlotEvents: ScorerEventData[],
  exclusions: ExclusionAnnotation[],
  timeScale: ScaleLinear<number, number, never>,
  eventID: number | undefined,
  start: number,
  end: number
) => {
  const event = allPlotEvents
    // remove own ID
    .filter((eD) => eD.id !== eventID)
    // apply time scale
    .map((eD) => timeScale(eD.event_ts[1]))
    // only look for events that are before this one
    .filter((eventEnd) => eventEnd < end)
    // order matters to find the last one for repositioning
    .sort((ee1, ee2) => ee2 - ee1)
    .find((eventEnd) => start < eventEnd)

  const exclusion = exclusions
    .map((eD) => timeScale(eD.endTS * 1000))
    .filter((exclusionEnd) => exclusionEnd < end)
    .sort((ee1, ee2) => ee2 - ee1)
    .find((exclusionEnd) => start < exclusionEnd)

  const intersectedData =
    event !== undefined && exclusion !== undefined
      ? event >= exclusion
        ? event
        : exclusion
      : event ?? exclusion

  return intersectedData
}
