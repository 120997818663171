import { atom, selector } from "recoil"
import { ScoringCriteria } from "../types/event.type"
import { nonExcludedSpo2, sleepInfo, sleepingHr } from "../state/signals.state"
import { studyMetaData } from "../state/study.state"
import {
  activeDesatEvents,
  activeRespEvents,
  activeSvebEvents,
  activeVebEvents,
} from "../state/event.state"
import { nonExcludedHr } from "../state/exclusions.state"
import { SummaryIndices } from "../interfaces/graphSummary.interface"
import {
  timeseriesMax,
  timeseriesMean,
  timeseriesMin,
} from "../utils/seriesUtils"
import {
  meanOfDesatNadirs,
  sumDesatEventsBetweenValues,
} from "../utils/eventUtils"
import { secToHours } from "../utils/timeUtils"

export const summaryIndices = atom<SummaryIndices | undefined>({
  key: "summaryIndices",
  default: undefined,
})

export const spo2Metrics = selector<{
  meanSpo2: number
  minSpo2: number
  maxSpo2: number
}>({
  key: "spo2Metrics",
  get: ({ get }) => {
    const spo2 = get(nonExcludedSpo2)
    return {
      meanSpo2: timeseriesMean(spo2),
      minSpo2: timeseriesMin(spo2),
      maxSpo2: timeseriesMax(spo2),
    }
  },
})

export const desatMetrics = selector<{
  meanDesatNadir: number
  desatsBelow9: number
  desatsNineTo20: number
  desatsAbove20: number
  odi4?: number
  ahi4?: number
  odi3?: number
  ahi3?: number
  totalEvents?: number
}>({
  key: "desatMetrics",
  get: ({ get }) => {
    const scoringCrit = get(studyMetaData).scoringCriteria
    const sleepDuration = get(sleepInfo).effSleepTime
    const desatEventData = get(activeDesatEvents)
    const respEventData = get(activeRespEvents)
    const meanDesatNadir = meanOfDesatNadirs(desatEventData)
    const desatsUnderNine = sumDesatEventsBetweenValues(desatEventData, 0, 9)
    const desatsNineTo20 = sumDesatEventsBetweenValues(desatEventData, 9, 20)
    const desatsAbove20 = sumDesatEventsBetweenValues(desatEventData, 20, 100)
    const odi = desatEventData.length / secToHours(sleepDuration)
    const ahi = respEventData.length / secToHours(sleepDuration)

    const totalEvents = desatEventData.length + respEventData.length

    return {
      meanDesatNadir,
      desatsBelow9: desatsUnderNine,
      desatsNineTo20,
      desatsAbove20,
      odi4: scoringCrit === ScoringCriteria.FOUR ? odi : undefined,
      ahi4: scoringCrit === ScoringCriteria.FOUR ? ahi : undefined,
      odi3: scoringCrit === ScoringCriteria.THREE ? odi : undefined,
      ahi3: scoringCrit === ScoringCriteria.THREE ? ahi : undefined,
      totalEvents: totalEvents,
    }
  },
})

export const hrMetrics = selector<{
  meanHr: number
  minHr: number
  maxHr: number
  meanHrSleeping: number
  minHrSleeping: number
  maxHrSleeping: number
}>({
  key: "hrMetrics",
  get: ({ get }) => {
    const hr = get(nonExcludedHr)
    const sleepHr = get(sleepingHr)
    return {
      meanHr: timeseriesMean(hr),
      minHr: timeseriesMin(hr),
      maxHr: timeseriesMax(hr),
      meanHrSleeping: timeseriesMean(sleepHr),
      minHrSleeping: timeseriesMin(sleepHr),
      maxHrSleeping: timeseriesMax(sleepHr),
    }
  },
})

export const ecgMetrics = selector<{
  vebEvents: number
  svebEvents: number
}>({
  key: "ecgMetrics",
  get: ({ get }) => {
    const vebEvents = get(activeVebEvents).length
    const svebEvents = get(activeSvebEvents).length
    return {
      vebEvents: vebEvents,
      svebEvents: svebEvents,
    }
  },
})
