import { LegacyRef, RefObject, useEffect } from "react"
import "./signature.css"
import { Box, Button } from "@mui/joy"

interface IMappedEvents {
  start: string[]
  move: string[]
  end: string[]
}

interface SignaturePadProps {
  signatureRef: RefObject<HTMLCanvasElement | null>
}

const SignaturePad = ({ signatureRef }: SignaturePadProps) => {
  const mappedEvents: IMappedEvents = {
    start: ["mousedown", "touchstart"],
    move: ["mousemove", "touchmove"],
    end: ["mouseup", "touchend", "touchcancel"],
  }
  const clearSignature = () => {
    if (signatureRef === null) return

    const canvas = signatureRef.current
    const context = canvas?.getContext("2d")
    if (canvas && context) {
      context.clearRect(0, 0, canvas.width, canvas.height)
    }
  }

  useEffect(() => {
    if (signatureRef === null) return
    const canvas = signatureRef.current as HTMLCanvasElement
    const context = canvas?.getContext("2d")
    let drawing = false

    if (canvas && context) {
      const addEventListeners = (
        element: HTMLElement,
        event: "start" | "move" | "end",
        fn: (ev: Event) => void
      ) => {
        const eventArray = mappedEvents[event]
        const len = eventArray.length

        for (let i = 0; i < len; ++i) {
          element.addEventListener(eventArray[i], fn, false)
        }
      }

      const onSigStart = (ev: Event) => {
        const coords = getOffset(ev)

        if (!coords) {
          return
        }

        context?.beginPath()
        context?.moveTo(coords.x, coords.y)
        drawing = true
      }

      const onSigMove = (ev: Event) => {
        if (!drawing) {
          return
        }
        ev.preventDefault()
        const coords = getOffset(ev)

        if (!coords) {
          return
        }

        context?.lineTo(coords.x, coords.y)
        context.lineWidth = 1.5
        context.strokeStyle = "#000"
        context.lineJoin = context.lineCap = "round"
        context?.stroke()
      }

      const onSigEnd = (ev: Event) => {
        drawing = false
      }

      const getOffset = (ev: Event) => {
        let x = 0
        let y = 0
        if (ev instanceof MouseEvent) {
          x = ev.offsetX
          y = ev.offsetY
        } else if (ev instanceof TouchEvent) {
          const tEvent = ev
          const touches = tEvent.touches

          if (touches.length > 1) {
            return
          }

          const touch = touches[0]
          const rect = (ev.currentTarget as HTMLElement).getBoundingClientRect()
          x = touch.clientX - rect.x
          y = touch.clientY - rect.y
        }
        return {
          x: (x / canvas.offsetWidth) * canvas.width,
          y: (y / canvas.offsetHeight) * canvas.height,
        }
      }

      addEventListeners(canvas, "start", onSigStart)
      addEventListeners(canvas, "move", onSigMove)
      addEventListeners(canvas, "end", onSigEnd)
    }
  }, [])

  return (
    <>
      {signatureRef !== null ? (
        <canvas
          ref={signatureRef as LegacyRef<HTMLCanvasElement>}
          className="signature-pad"
        ></canvas>
      ) : (
        <></>
      )}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button onClick={() => clearSignature()} variant="outlined" size="md">
          Clear
        </Button>
      </Box>
    </>
  )
}

export default SignaturePad
