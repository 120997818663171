/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeviceOut
 */
export interface DeviceOut {
    /**
     * 
     * @type {string}
     * @memberof DeviceOut
     */
    uuid?: string;
    /**
     * 
     * @type {Date}
     * @memberof DeviceOut
     */
    deletedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof DeviceOut
     */
    serialNumber: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceOut
     */
    deviceType?: string;
}

/**
 * Check if a given object implements the DeviceOut interface.
 */
export function instanceOfDeviceOut(value: object): boolean {
    if (!('serialNumber' in value)) return false;
    return true;
}

export function DeviceOutFromJSON(json: any): DeviceOut {
    return DeviceOutFromJSONTyped(json, false);
}

export function DeviceOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceOut {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'] == null ? undefined : json['uuid'],
        'deletedAt': json['deleted_at'] == null ? undefined : (new Date(json['deleted_at'])),
        'serialNumber': json['serial_number'],
        'deviceType': json['device_type'] == null ? undefined : json['device_type'],
    };
}

export function DeviceOutToJSON(value?: DeviceOut | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'deleted_at': value['deletedAt'] == null ? undefined : ((value['deletedAt']).toISOString()),
        'serial_number': value['serialNumber'],
        'device_type': value['deviceType'],
    };
}

