import InternalHeader from "../../components/internalheader/InternalHeader"
import { Box, Button } from "@mui/joy"
import { useRecoilState, useRecoilValue } from "recoil"
import { currentStudy } from "../../state/study.state"
import { currentUser } from "../../state/auth.state"
import { StudyStatus, statusStrToEnum } from "../../utils/studyUtils"
import useGenerateReportPdf from "../../hooks/useGenerateReportPdf"
import {
  isGeneratingPDFReport,
  reportStatus,
} from "../../state/pdfReport.state"
import usePrintPdf from "../../hooks/usePrintPdf"

const StudyScoringHeader = ({
  studyID,
  interpretCurrentStudy,
  chartRef,
}: {
  studyID: string | undefined
  interpretCurrentStudy: () => Promise<void>
  chartRef: React.RefObject<HTMLDivElement>
}) => {
  const { generatePdf } = useGenerateReportPdf()
  const printSleepPdf = usePrintPdf(chartRef)
  const [isGeneratingPDF, setIsGeneratingPdf] = useRecoilState(
    isGeneratingPDFReport
  )
  const reportButtonStatus = useRecoilValue(reportStatus)
  const printEcgPdf = async () => {
    setIsGeneratingPdf(true)
    await generatePdf({ studyId: studyID as string })
  }
  const study = useRecoilValue(currentStudy)
  const user = useRecoilValue(currentUser)

  if (study === undefined && isGeneratingPDF === false) return null
  const headerList = [
    {
      fontWeight: 500,
      fontSize: 12,
      text: "Patient: " + study !== undefined ? study?.patient?.name : "N/A",
    },
    {
      fontWeight: 500,
      fontSize: 12,
      text: "Studies",
    },
    {
      fontWeight: 500,
      fontSize: 12,
      text: study?.id === undefined ? "N/A" : study.id.toString(),
    },
  ]

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <InternalHeader breadCrumbList={headerList}>
        <></>
      </InternalHeader>
      <Box sx={{ display: "flex", gap: "0.5rem" }}>
        {user?.primaryRole === "Physician" && (
          <Button
            disabled={
              study !== undefined
                ? statusStrToEnum[study.status] === StudyStatus.COMPLETE
                : false
            }
            variant="solid"
            size="md"
            onClick={interpretCurrentStudy}
          >
            Interpret
          </Button>
        )}
        <Button
          disabled={!reportButtonStatus}
          variant="solid"
          size="md"
          onClick={printSleepPdf}
        >
          Sleep Report
        </Button>
        <Button
          disabled={!reportButtonStatus}
          variant="solid"
          size="md"
          onClick={printEcgPdf}
        >
          ECG Report
        </Button>
      </Box>
    </Box>
  )
}

export default StudyScoringHeader
