import { useRecoilCallback, useRecoilValue } from "recoil"
import useStudies from "./useStudies"
import {
  csdRegionsOfInterest,
  eventFamily,
  eventIDs,
  eventMetaData,
  respiratoryEvents,
  sleepWakeEvents,
} from "../state/event.state"
import { desatMetrics, summaryIndices } from "../state/graphSummary.state"
import {
  ecgBadExclusions,
  ecgExclusions,
  ppgExclusions,
} from "../state/exclusions.state"
import { currentStudy } from "../state/study.state"
import { ScorerEventData } from "../types/event.type"
import {
  saveBeatsEventDataConverter,
  saveEventDataConverter,
} from "../utils/eventUtils"
import { ExclusionAnnotation } from "../types/exclusion.type"
//import { JSONContent } from "@tiptap/core"

const useInterpretStudy = () => {
  const studiesApi = useStudies()
  const study = useRecoilValue(currentStudy)
  const eventIdData = useRecoilValue(eventIDs)
  const eventMetadata = useRecoilValue(eventMetaData)
  const eventSummaryIndices = useRecoilValue(summaryIndices)
  const csdRoI = useRecoilValue(csdRegionsOfInterest)
  const eventDesatMetrics = useRecoilValue(desatMetrics)
  const ecgExclusionAnnotations = useRecoilValue(ecgExclusions)
  const ppgExclusionAnnotations = useRecoilValue(ppgExclusions)
  const respEvents = useRecoilValue(respiratoryEvents)
  const sleep = useRecoilValue(sleepWakeEvents)
  const fetchData = useRecoilCallback(
    ({ snapshot }) =>
      async (eventID: number) => {
        return await snapshot.getPromise(eventFamily(eventID))
      }
  )
  const ecgBadExclusionsData = useRecoilValue(ecgBadExclusions)

  interface InterpretStudyProps {
    //interpretationEditorContentData: JSONContent
    setStudyInterpreted: React.Dispatch<React.SetStateAction<boolean>>
  }

  const interpretStudy = async ({
    //interpretationEditorContentData,
    setStudyInterpreted,
  }: InterpretStudyProps) => {
    if (study?.uuid === undefined) return

    const eventPromiseData = await Promise.all(
      eventIdData.map((eventID) => fetchData(eventID))
    )
    const ahiEvents = eventPromiseData
      .filter((event: ScorerEventData) => event.plot === "HR")
      .map((event: ScorerEventData) => saveEventDataConverter(event))
    const desatEvents = eventPromiseData
      .filter((event: ScorerEventData) => event.plot === "SpO2")
      .map((event: ScorerEventData) => saveEventDataConverter(event))
    const ecg_rhythms = [
      ...eventPromiseData
        .filter(
          (event: ScorerEventData) =>
            event.plot === "ECG" && event.type === "Rhythms"
        )
        .map((event: ScorerEventData) => saveEventDataConverter(event)),
      ...ecgBadExclusionsData
        .filter(
          (exclusionData) => exclusionData?.event_data?.label === "BAD_QUALITY"
        )
        .map((exclusionData: ExclusionAnnotation) => {
          delete exclusionData.label
          return exclusionData as unknown as ScorerEventData
        }),
    ]

    const lead_off_events = ecgBadExclusionsData
      .filter((exclusionData) => exclusionData.label === "leads_off_events")
      .map((exclusionData) => {
        delete exclusionData.label
        return exclusionData as unknown as ScorerEventData
      })

    const ecg_beats = eventPromiseData
      .filter(
        (event: ScorerEventData) =>
          event.plot === "ECG" && event.type === "Beats"
      )
      .map((event: ScorerEventData) => saveBeatsEventDataConverter(event))

    const { ahi3, ahi4, odi3, odi4 } = eventDesatMetrics
    const eventData = {
      metadata: eventMetadata,
      events: {
        csd_regions_of_interest: csdRoI,
        desat_events: desatEvents,
        ecg_exclusion_annotations: ecgExclusionAnnotations,
        final_ahi_events: ahiEvents,
        ppg_exclusion_annotations: ppgExclusionAnnotations,
        respiratory_events: respEvents,
        sleep,
      },
      summary_indices: {
        ...eventSummaryIndices,
        sahi_3: ahi3 && parseFloat(ahi3.toFixed(2)),
        sahi_4: ahi4 && parseFloat(ahi4.toFixed(2)),
        odi_3: odi3 && parseFloat(odi3.toFixed(2)),
        odi_4: odi4 && parseFloat(odi4.toFixed(2)),
      },
    }
    const propName =
      eventSummaryIndices?.scoring_criteria === "3"
        ? "eventDataAhiThree"
        : "eventDataAhiFour"
    await studiesApi.updateStudyEvents(study?.uuid, {
      [propName]: eventData,
      eventDataEcg: {
        rhythms: ecg_rhythms,
        beats: ecg_beats,
        leads_off_events: lead_off_events,
      },
    })
    const success = await studiesApi.interpretStudy(study?.uuid, {
      interpretationNotes: {
        /*interpretationEditorContentData*/
      },
    })
    setStudyInterpreted(success)
  }

  return {
    interpretStudy,
  }
}

export default useInterpretStudy
