import { Box, IconButton } from "@mui/material"
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { convertHexToRGBA } from "@huxley-medical/react-components/utils"
import { eventMakeSelected } from "../../../components/scoring/event/handlers/eventMakeSelected"
import { useParams } from "react-router-dom"
import {
  eventFamily,
  eventIDs,
  selectedEventID,
} from "../../../state/event.state"
import { allPlotTimeDomain } from "../../../state/signals.state"
import { currentEpoch } from "../../../state/epoch.state"
import { colorMap } from "../../../const/event.const"
import { StyledEventRow } from "../../../styled/Table"

/**
 * EventRow is a functional component representing an event as a
 * sidebar table row.
 *
 * @param param.eventID
 * @returns {JSX.Element} JSX.Element
 */
const EventRow = ({ eventID }: { eventID: number }): JSX.Element => {
  const event = useRecoilValue(eventFamily(eventID))

  const [xAllMin] = useRecoilValue(allPlotTimeDomain)
  const setEpoch = useSetRecoilState(currentEpoch)
  const events = useRecoilValue(eventIDs)
  const handleEventMakeSelected = useRecoilCallback(eventMakeSelected)
  const halfWidth = (event.event_ts[1] - event.event_ts[0]) / 2

  const time = new Date(event.event_ts[0])
  const color = convertHexToRGBA(colorMap[event.type], 0.2)

  const { studyID } = useParams()

  const selectedEvent = useRecoilValue(selectedEventID({ studyID }))

  const isSelected = selectedEvent === event.id

  return (
    <StyledEventRow
      onClick={() => {
        // Scroll to epoch where this event is
        const containedEpoch = Math.floor(
          (event.event_ts[0] + halfWidth - xAllMin) / 1000 / 30
        )
        let targetScollEpoch = containedEpoch - 3 // half of epochs per page
        if (targetScollEpoch < 0) targetScollEpoch = 0

        setEpoch(targetScollEpoch)
        handleEventMakeSelected(eventID, events)
      }}
      sx={{
        backgroundColor: color,
        "& td": {
          padding: "10px",
        },
        ...(isSelected
          ? {
              border: `2px solid ${colorMap[event.type]}`,
            }
          : {}),
      }}
    >
      <td>
        <Box sx={{ color: "#006BB9", lineHeight: "20px" }}>
          {time.toLocaleTimeString()}
        </Box>
      </td>
      <td>{event.type}</td>
      <td style={{ padding: "0" }}>
        <IconButton>
          <MoreVertIcon />
        </IconButton>
      </td>
    </StyledEventRow>
  )
}

export default EventRow
