/* eslint-disable prettier/prettier */
import { atom, selector, selectorFamily } from "recoil"
import { StudyMetadata } from "../interfaces/study.interface"
import { eventFamily, eventIDs, sleepWakeEvents } from "../state/event.state"
import { studySignals } from "../state/signals.state"
import {EventPlot, RefineEventType, ScoringCriteria} from "../types/event.type"
import { SleepStudyOrderOut } from "../generated/fetchclient/models/SleepStudyOrderOut"
import { StudyPlotSignals } from "../interfaces/signals.interface"
import {
  bodyPosValuesToPostionMap,
  sleepWakeEventsToTimeSeriesPoints,
  timeSeriesPointsToPointsConvert,
} from "../utils/seriesUtils"
import { timeIntervalType } from "../types/time.type"
import { SleepStudyOutData } from "../types/study.type"

export const currentStudy = atom<SleepStudyOutData | undefined>({
  key: "currentStudy",
  default: undefined,
})

export const studyCount = atom<number>({
  key: "studyCount",
  default: 0,
})

export const activeStudyID = atom<string | undefined>({
  key: "activeStudyID",
})

export const isLoadingStudy = atom<boolean>({
  key: "isLoadingStudy",
  default: false,
})

export const studies = atom<SleepStudyOrderOut[]>({
  key: "studies",
  default: [],
})

export const studyMetaData = atom<StudyMetadata>({
  key: "studyMetaData",
  default: {
    friendlyId: undefined,
    studyStartTime: 0,
    studyEndTime: 0,
    scoringCriteria: ScoringCriteria.THREE,
    swVersion: undefined,
  },
})

export const studyPlotData = selector<StudyPlotSignals>({
  key: "studyPlotData",
  get: ({ get }) => {
    const studyd = get(studySignals)
    const sleepd = get(sleepWakeEvents)
    const sleepPlot = sleepd ? sleepWakeEventsToTimeSeriesPoints(sleepd) :[]

    const respiratoryEffortPlot = studyd?.resp_effort
      ? timeSeriesPointsToPointsConvert(studyd.resp_effort)
      : []

    const snorePlot = studyd?.snore_envelop
      ? timeSeriesPointsToPointsConvert(studyd.snore_envelop)
      : []

    const spo2Plot = studyd?.spo2
      ? timeSeriesPointsToPointsConvert(studyd.spo2)
      : []

    const ecgPlot = studyd?.ecg
      ? timeSeriesPointsToPointsConvert(studyd.ecg)
      : []

    const cardiacPlot = studyd?.hr
      ? timeSeriesPointsToPointsConvert(studyd.hr)
      : []

    const actigraphyPlot = studyd?.actigraphy
      ? timeSeriesPointsToPointsConvert(studyd.actigraphy)
      : []

    const chestPlot = studyd?.chest_movement
      ? timeSeriesPointsToPointsConvert(studyd.chest_movement)
      : []

    const positionPlot = studyd?.body_position
      ? timeSeriesPointsToPointsConvert({
        values: studyd.body_position.values.map((value: number) =>
          bodyPosValuesToPostionMap(value)
        ),
        timestamps: studyd.body_position.timestamps,
      })
      : []

    return {
      respiratory_effort: respiratoryEffortPlot,
      snore: snorePlot,
      spo2: spo2Plot,
      hr: cardiacPlot,
      actigraphy: actigraphyPlot,
      chest_movement: chestPlot,
      body_position: positionPlot,
      sleep: sleepPlot,
      ecg: ecgPlot,
    }
  },
})

export const studyPlotOrder = atom<{ plotType: EventPlot, enabled: boolean }[]>(
    {
        key: "studyPlotOrder",
        default: [
          {plotType: "Snoring", enabled: true},
          {plotType: "Resp", enabled: true},
          {plotType: "ECG", enabled: true},
          {plotType: "SpO2", enabled: true},
          {plotType: "HR", enabled: true},
          {plotType: "Chest", enabled: true},
          {plotType: "Actigraphy", enabled: true},
          {plotType: "Position", enabled: true},
          {plotType: "SANSASleepStage", enabled: true},
        ],
    }
)

export const timeIntervals = atom<timeIntervalType[]>({
  key: "timeIntervals",
  default: [],
})

export const fullNightInterval = atom<number>({
  key: "fullNightInterval",
  default: 0,
})

export const refineEventTypeAtom = atom<RefineEventType>({
  key: "refineEventTypeAtom",
  default: "All",
})

/**
 * plotSidebarTimeSort - returns sorted list of eventIDs based on
 * refineEventTypeAtom atom state
 */
export const plotSidebarTimeSort = selectorFamily({
  key: `plotSidebarTimeSort`,
  get:
    ({ studyID }: { studyID: string }) =>
      ({ get }) =>
        get(eventIDs)
          .map((eventID) => get(eventFamily(eventID)))
          .filter(
            (eventData) =>
              get(refineEventTypeAtom) === "All" ||
              eventData.type === get(refineEventTypeAtom)
          )
          .sort((a, b) => a.event_ts[0] - b.event_ts[0])
          .map((eventData) => eventData.id),
})

export const intervalTimeEventTypeAtom = atom<number>({
  key: "intervalTimeEventTypeAtom",
  default: 0,
})