import { KeyboardArrowRight } from "@mui/icons-material"
import Box from "@mui/joy/Box"
import IconButton from "@mui/joy/IconButton"
import List from "@mui/joy/List"
import ListItemContent from "@mui/joy/ListItemContent"
import ListItemButton from "@mui/joy/ListItemButton"
import Typography from "@mui/joy/Typography"
import Grid from "@mui/joy/Grid"
import Card from "@mui/joy/Card"
import CardContent from "@mui/joy/CardContent"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import { Button, Stack } from "@mui/joy"
import { useRecoilValue } from "recoil"
import { InterpretationTemplateFormData } from "./InterpretationTemplateModal"
import { interpretationTemplates } from "../../../state/interpretationtemplate.state"

interface ListInterpretationTemplateProps {
  setTemplateInterpretationContent: React.Dispatch<
    React.SetStateAction<InterpretationTemplateFormData>
  >
  templateInterpretationContent: InterpretationTemplateFormData
  openEditInterpretationForm: (
    interpretationTemplateData: InterpretationTemplateFormData
  ) => void
  selectedInterpretationContent: string
  selectInterpretationEditorContent: () => void
  deleteInterpretationTemplate: (templateId: string) => Promise<void>
}

const ListInterpretationTemplateGrid = ({
  setTemplateInterpretationContent,
  templateInterpretationContent,
  openEditInterpretationForm,
  selectedInterpretationContent,
  selectInterpretationEditorContent,
  deleteInterpretationTemplate,
}: ListInterpretationTemplateProps) => {
  const interpretationTemplatesDataList = useRecoilValue(
    interpretationTemplates
  )
  return (
    <>
      <Grid
        xs={4}
        sx={{
          maxHeight: {
            md: "450px",
            xs: "200px",
          },
          minHeight: {
            md: "400px",
            xs: "200px",
          },
          overflowY: "scroll",
        }}
      >
        <List sx={{ p: 0 }}>
          {interpretationTemplatesDataList.map((item, index) => (
            <ListItemButton
              selected={
                item.uuid === templateInterpretationContent.uuid ? true : false
              }
              color={
                item.uuid === templateInterpretationContent.uuid
                  ? "primary"
                  : undefined
              }
              onClick={() => {
                setTemplateInterpretationContent({
                  uuid: item.uuid,
                  title: item.title,
                  notes: item.notes,
                  userId: item.user.uuid !== undefined ? item.user.uuid : "",
                })
              }}
              key={index}
              variant="outlined"
            >
              <ListItemContent sx={{ p: 1 }}>{item.title}</ListItemContent>
              <KeyboardArrowRight />
            </ListItemButton>
          ))}
        </List>
      </Grid>
      <Grid
        xs={8}
        sx={{
          maxHeight: {
            md: "600px",
            xs: "200px",
          },
          minHeight: {
            md: "400px",
            xs: "200px",
          },
        }}
      >
        <Card
          sx={{
            maxHeight: {
              md: "450px",
              xs: "150px",
            },
            minHeight: {
              md: "450px",
              xs: "150px",
            },
            overflow: "hidden",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography level="h3">
              {templateInterpretationContent.title}
            </Typography>
            <Stack direction="row" spacing={1}>
              <IconButton
                aria-label="edit interpretation form modal"
                variant="plain"
                color="neutral"
                size="md"
                onClick={() => {
                  openEditInterpretationForm(templateInterpretationContent)
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                aria-label="Delete template"
                variant="plain"
                color="neutral"
                size="md"
                onClick={() => {
                  deleteInterpretationTemplate(
                    templateInterpretationContent.uuid as string
                  )
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Stack>
          </Box>
          <CardContent sx={{ overflowY: "auto", zIndex: 0 }}>
            <Box
              className="interpretationTemplateEditor"
              sx={{
                position: "relative",
                mt: -2,
                pr: 0,
              }}
            >
              <p
                dangerouslySetInnerHTML={{
                  __html: selectedInterpretationContent,
                }}
              ></p>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid xs={12} display="flex" justifyContent="flex-end">
        <Button
          type="submit"
          onClick={() => selectInterpretationEditorContent()}
        >
          {"Use template"}
        </Button>
      </Grid>
    </>
  )
}

export default ListInterpretationTemplateGrid
