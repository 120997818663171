/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PhysicianOut } from './PhysicianOut';
import {
    PhysicianOutFromJSON,
    PhysicianOutFromJSONTyped,
    PhysicianOutToJSON,
} from './PhysicianOut';
import type { SleepStudyOutBase } from './SleepStudyOutBase';
import {
    SleepStudyOutBaseFromJSON,
    SleepStudyOutBaseFromJSONTyped,
    SleepStudyOutBaseToJSON,
} from './SleepStudyOutBase';

/**
 * 
 * @export
 * @interface ActiveSleepStudyOrderOut
 */
export interface ActiveSleepStudyOrderOut {
    /**
     * 
     * @type {string}
     * @memberof ActiveSleepStudyOrderOut
     */
    uuid?: string;
    /**
     * 
     * @type {Date}
     * @memberof ActiveSleepStudyOrderOut
     */
    deletedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof ActiveSleepStudyOrderOut
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ActiveSleepStudyOrderOut
     */
    patient: string;
    /**
     * 
     * @type {string}
     * @memberof ActiveSleepStudyOrderOut
     */
    address1: string;
    /**
     * 
     * @type {string}
     * @memberof ActiveSleepStudyOrderOut
     */
    address2?: string;
    /**
     * 
     * @type {string}
     * @memberof ActiveSleepStudyOrderOut
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof ActiveSleepStudyOrderOut
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof ActiveSleepStudyOrderOut
     */
    zip: string;
    /**
     * 
     * @type {Date}
     * @memberof ActiveSleepStudyOrderOut
     */
    orderDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof ActiveSleepStudyOrderOut
     */
    scheduledDate: Date;
    /**
     * 
     * @type {string}
     * @memberof ActiveSleepStudyOrderOut
     */
    status: string;
    /**
     * 
     * @type {object}
     * @memberof ActiveSleepStudyOrderOut
     */
    statusHistory: object;
    /**
     * 
     * @type {string}
     * @memberof ActiveSleepStudyOrderOut
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof ActiveSleepStudyOrderOut
     */
    oxygenDesatThreshold: string;
    /**
     * 
     * @type {PhysicianOut}
     * @memberof ActiveSleepStudyOrderOut
     */
    orderingPhysician: PhysicianOut;
    /**
     * 
     * @type {PhysicianOut}
     * @memberof ActiveSleepStudyOrderOut
     */
    interpretingPhysician: PhysicianOut;
    /**
     * 
     * @type {string}
     * @memberof ActiveSleepStudyOrderOut
     */
    assignedDevice: string;
    /**
     * 
     * @type {string}
     * @memberof ActiveSleepStudyOrderOut
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof ActiveSleepStudyOrderOut
     */
    organization: string;
    /**
     * 
     * @type {SleepStudyOutBase}
     * @memberof ActiveSleepStudyOrderOut
     */
    study?: SleepStudyOutBase;
}

/**
 * Check if a given object implements the ActiveSleepStudyOrderOut interface.
 */
export function instanceOfActiveSleepStudyOrderOut(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('patient' in value)) return false;
    if (!('address1' in value)) return false;
    if (!('city' in value)) return false;
    if (!('state' in value)) return false;
    if (!('zip' in value)) return false;
    if (!('orderDate' in value)) return false;
    if (!('scheduledDate' in value)) return false;
    if (!('status' in value)) return false;
    if (!('statusHistory' in value)) return false;
    if (!('type' in value)) return false;
    if (!('oxygenDesatThreshold' in value)) return false;
    if (!('orderingPhysician' in value)) return false;
    if (!('interpretingPhysician' in value)) return false;
    if (!('assignedDevice' in value)) return false;
    if (!('organization' in value)) return false;
    return true;
}

export function ActiveSleepStudyOrderOutFromJSON(json: any): ActiveSleepStudyOrderOut {
    return ActiveSleepStudyOrderOutFromJSONTyped(json, false);
}

export function ActiveSleepStudyOrderOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActiveSleepStudyOrderOut {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'] == null ? undefined : json['uuid'],
        'deletedAt': json['deleted_at'] == null ? undefined : (new Date(json['deleted_at'])),
        'id': json['id'],
        'patient': json['patient'],
        'address1': json['address_1'],
        'address2': json['address_2'] == null ? undefined : json['address_2'],
        'city': json['city'],
        'state': json['state'],
        'zip': json['zip'],
        'orderDate': (new Date(json['order_date'])),
        'scheduledDate': (new Date(json['scheduled_date'])),
        'status': json['status'],
        'statusHistory': json['status_history'],
        'type': json['type'],
        'oxygenDesatThreshold': json['oxygen_desat_threshold'],
        'orderingPhysician': PhysicianOutFromJSON(json['ordering_physician']),
        'interpretingPhysician': PhysicianOutFromJSON(json['interpreting_physician']),
        'assignedDevice': json['assigned_device'],
        'notes': json['notes'] == null ? undefined : json['notes'],
        'organization': json['organization'],
        'study': json['study'] == null ? undefined : SleepStudyOutBaseFromJSON(json['study']),
    };
}

export function ActiveSleepStudyOrderOutToJSON(value?: ActiveSleepStudyOrderOut | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'deleted_at': value['deletedAt'] == null ? undefined : ((value['deletedAt']).toISOString()),
        'id': value['id'],
        'patient': value['patient'],
        'address_1': value['address1'],
        'address_2': value['address2'],
        'city': value['city'],
        'state': value['state'],
        'zip': value['zip'],
        'order_date': ((value['orderDate']).toISOString().substring(0,10)),
        'scheduled_date': ((value['scheduledDate']).toISOString().substring(0,10)),
        'status': value['status'],
        'status_history': value['statusHistory'],
        'type': value['type'],
        'oxygen_desat_threshold': value['oxygenDesatThreshold'],
        'ordering_physician': PhysicianOutToJSON(value['orderingPhysician']),
        'interpreting_physician': PhysicianOutToJSON(value['interpretingPhysician']),
        'assigned_device': value['assignedDevice'],
        'notes': value['notes'],
        'organization': value['organization'],
        'study': SleepStudyOutBaseToJSON(value['study']),
    };
}

