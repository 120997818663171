import {
  Box,
  Chip,
  FormControl,
  IconButton,
  Input,
  Option,
  Select,
  Sheet,
  Stack,
} from "@mui/joy"
import { Refresh, Search } from "@mui/icons-material"
import { GridColDef, GridPaginationModel } from "@mui/x-data-grid"
import { StyledDataGrid } from "../../styled/DataGrid"
import { useEffect, useState } from "react"
import { createBasicGridColDef } from "../../utils/tableUtils"
import { removeEmptyValuesFromObject } from "../../utils/utils"
import {
  SimplifiedStudyStatus,
  StatusTextToStr,
  StudyStatus,
  simplifyStudyStatus,
  statusStrToEnum,
} from "../../utils/studyUtils"
import { formatPhoneNumber, Value } from "react-phone-number-input"
import { dateSortComparator, formatDate } from "../../utils/formatDate"
import NewPatientModal from "./newpatientmodal"
import usePatients from "../../hooks/usePatients"
import { GetAllPatientsRequest } from "../../generated/fetchclient"
import { useNavigate } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { isHuxleyUser } from "../../state/auth.state"

interface PatientTableRow {
  id?: string
  patientName: string
  mrn: string
  dob: string
  race: string
  sex: string
  phone: string
  email: string
  referringPhysicianName?: string
  referringPhysicianPhone?: string
  status: string
}

const emptyPatientQuery: GetAllPatientsRequest = {
  name: "",
  mrn: "",
  studyStatuses: [],
}

function PatientTable() {
  const nav = useNavigate()
  const patients = usePatients()
  const { patients: apiPatients, patientCount, resetPatientData } = patients
  const isAdmin = useRecoilValue(isHuxleyUser)
  const [isLoading, setIsLoading] = useState(false)
  const [statusFilter, setStatusFilter] = useState<string[]>([])
  const [tableRows, setTableRows] = useState<PatientTableRow[]>([])
  const [patientQuery, setPatientQuery] =
    useState<GetAllPatientsRequest>(emptyPatientQuery)
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>()

  useEffect(() => {
    const rows: PatientTableRow[] = apiPatients.map((p) => {
      const status = p.studyOrders[0]?.status
      return {
        id: p.uuid,
        patientName: p.fullName,
        mrn: p.mrn,
        dob: formatDate(p.dob),
        race: p.race,
        sex: p.sex,
        phone: formatPhoneNumber(p.primaryPhone as Value),
        email: p.email,
        referringPhysicianName: `${p.referringPhysicianFirstName || ""} ${
          p.referringPhysicianLastName || ""
        }`,
        referringPhysicianPhone:
          p.referringPhysicianPhone &&
          formatPhoneNumber(p.referringPhysicianPhone as Value),
        status:
          status && isAdmin
            ? statusStrToEnum[status]
            : simplifyStudyStatus(statusStrToEnum[status]),
      }
    })

    setTableRows(rows)
  }, [apiPatients])

  useEffect(() => {
    return () => {
      resetPatientData()
    }
  }, [])

  const searchPatients = async (
    paginationModel: GridPaginationModel | undefined = undefined
  ) => {
    const filteredQuery = removeEmptyValuesFromObject(patientQuery)

    setIsLoading(true)
    if (paginationModel === undefined) {
      await patients.listPatients(filteredQuery)
    } else {
      await patients.listPatients({
        ...filteredQuery,
        limit: paginationModel.pageSize,
        offset: paginationModel.page * paginationModel.pageSize,
      })
    }
    setIsLoading(false)
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault()
      event.stopPropagation()
      searchPatients(paginationModel)
    }
  }

  const columns: GridColDef[] = [
    createBasicGridColDef("id", "ID", 0),
    createBasicGridColDef("patientName", "Patient Name", 8),
    createBasicGridColDef("mrn", "MRN", 4),
    {
      field: "dob",
      headerName: "DOB",
      flex: 4,
      editable: false,
      sortComparator: (v1: string, v2: string) => dateSortComparator(v1, v2),
    },
    createBasicGridColDef("race", "Race", 2),
    createBasicGridColDef("sex", "Sex", 2),
    createBasicGridColDef("phone", "Phone", 5),
    createBasicGridColDef("email", "Email", 8),
    createBasicGridColDef("referringPhysicianName", "Ref. Physician Name", 8),
    createBasicGridColDef("referringPhysicianPhone", "Ref. Physician Phone", 8),
    createBasicGridColDef("status", "Study status", 8),
  ]

  return (
    <Stack spacing={2} sx={{ overflow: "hidden" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Stack direction="row" spacing={2} display="flex" alignItems="center">
          <FormControl>
            <Input
              placeholder="Name"
              value={patientQuery.name}
              onChange={(e) =>
                setPatientQuery({
                  ...patientQuery,
                  name: e.target.value,
                })
              }
              onKeyDown={handleKeyDown}
            />
          </FormControl>
          <FormControl>
            <Input
              placeholder="MRN"
              value={patientQuery.mrn}
              onChange={(e) =>
                setPatientQuery({
                  ...patientQuery,
                  mrn: e.target.value,
                })
              }
              onKeyDown={handleKeyDown}
            />
          </FormControl>
          <FormControl sx={{ minWidth: 250 }}>
            <Select
              startDecorator="Study status"
              multiple
              value={statusFilter}
              onChange={(_, newValue) => {
                const studyStatuses = newValue.map(
                  (status) => StatusTextToStr[status]
                )
                setPatientQuery({
                  ...patientQuery,
                  studyStatuses,
                })
                setStatusFilter(newValue)
              }}
              placeholder="All"
              renderValue={(value) => {
                return (
                  <Sheet sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {value.map((selectedOption) => (
                      <Chip
                        key={selectedOption.value}
                        sx={{
                          borderRadius: "4px",
                        }}
                        variant="outlined"
                      >
                        {selectedOption.label}
                      </Chip>
                    ))}
                  </Sheet>
                )
              }}
            >
              {Object.values(isAdmin ? StudyStatus : SimplifiedStudyStatus).map(
                (status, index) => (
                  <Option key={index} value={status}>
                    {status}
                  </Option>
                )
              )}
            </Select>
          </FormControl>
          <IconButton
            onClick={() => searchPatients(paginationModel)}
            variant="solid"
            color="primary"
          >
            <Search />
          </IconButton>
          <IconButton
            onClick={() => {
              setPatientQuery(emptyPatientQuery)
              setStatusFilter([])
              if (paginationModel === undefined) {
                patients.listPatients()
              } else {
                patients.listPatients({
                  limit: paginationModel.pageSize,
                  offset: paginationModel.page * paginationModel.pageSize,
                })
              }
            }}
            variant="outlined"
            color="primary"
          >
            <Refresh />
          </IconButton>
        </Stack>
        <NewPatientModal />
      </Box>
      <Box sx={{ height: "calc(100% - 52px)" }}>
        <StyledDataGrid
          rows={tableRows}
          columns={columns}
          rowCount={patientCount}
          loading={isLoading}
          paginationMode="server"
          onPaginationModelChange={(paginationModel) => {
            setPaginationModel(paginationModel)
            searchPatients(paginationModel)
          }}
          onRowClick={(params) => nav(`/patients/${params.row.id}`)}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
        />
      </Box>
    </Stack>
  )
}

export default PatientTable
