import Box from "@mui/joy/Box"
import Button from "@mui/joy/Button"
import Typography from "@mui/joy/Typography"

interface EditSignatureButtonProps {
  editSignature: boolean
  setEditSignature: React.Dispatch<React.SetStateAction<boolean>>
}

const EditSignatureButton = ({
  editSignature,
  setEditSignature,
}: EditSignatureButtonProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        justifyContent: "space-between",
      }}
    >
      {!editSignature ? (
        <Typography sx={{ textAlign: "center" }} level="title-md">
          {"Your uploaded signature"}
        </Typography>
      ) : (
        <Typography sx={{ textAlign: "center" }} level="title-md"></Typography>
      )}
      <Button
        onClick={() => setEditSignature(!editSignature)}
        variant="solid"
        size="sm"
        color="primary"
        sx={{
          alignSelf: "flex-end",
        }}
      >
        {editSignature ? "Cancel" : "Edit"}
      </Button>
    </Box>
  )
}

export default EditSignatureButton
