import { useRecoilValue } from "recoil"
import huxleySmall from "./HUXLEY_CENTERED_Small.png"
import { currentStudy, studyMetaData } from "../../state/study.state"
import { sleepInfo } from "../../state/signals.state"
import {
  desatMetrics,
  hrMetrics,
  spo2Metrics,
  summaryIndices,
} from "../../state/graphSummary.state"
import { ScoringCriteria } from "../../types/event.type"
import { secondsToHms } from "../../utils/timeUtils"
import { timeStampConverter } from "../../utils/seriesUtils"

export function formatDateMed(date: Date): string {
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  })
}

export function formatTime24Simple(date: Date): string {
  return date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  })
}

function PDFReport() {
  const studyMetaDataValue = useRecoilValue(studyMetaData)
  const sleepInfoValue = useRecoilValue(sleepInfo)
  const spo2metrics = useRecoilValue(spo2Metrics)
  const hrmetrics = useRecoilValue(hrMetrics)
  const desatmetrics = useRecoilValue(desatMetrics)
  const study = useRecoilValue(currentStudy)
  const summaryindices = useRecoilValue(summaryIndices)
  const { studyDate, studyEndDate } = {
    studyDate: new Date(timeStampConverter(studyMetaDataValue.studyStartTime)),
    studyEndDate: new Date(timeStampConverter(studyMetaDataValue.studyEndTime)),
  }

  const totalStudyTime =
    studyMetaDataValue.studyEndTime - studyMetaDataValue.studyStartTime
  const totalStudyTimeString = secondsToHms(totalStudyTime)
  const sleep_efficiency =
    (sleepInfoValue.sleepDurationSeconds / totalStudyTime) * 100
  const totalSleepString = secondsToHms(sleepInfoValue.sleepDurationSeconds)
  const effSleepTimeString = secondsToHms(sleepInfoValue.effSleepTime)
  const meanOfDesatNadirs = desatmetrics.meanDesatNadir.toFixed(1)

  const numBeats =
    summaryindices?.num_heart_beats !== undefined
      ? String(summaryindices.num_heart_beats)
      : "N/A"

  const scoringcrit =
    studyMetaDataValue.scoringCriteria === ScoringCriteria.THREE
      ? "AHI-3%"
      : "AHI-4%"

  const sleepReportData = {
    study_id: study?.id,
    sw_version: studyMetaDataValue.swVersion,
    patient_name: study?.patient?.name,
    dob: study?.patient?.dob.toLocaleDateString(),
    mrn: study?.patient?.mrn,
    referring_physician: "Ref Phys",
    interpreting_physician: study?.studyOrder?.interpretingPhysician.name,
    study_date: formatDateMed(studyDate),
    scoring_criteria: scoringcrit,
    study_start_time: formatTime24Simple(studyDate),
    study_end_time: formatTime24Simple(studyEndDate),
    total_study_time: totalStudyTimeString,
    sleep_time: totalSleepString,
    sleep_latency: secondsToHms(sleepInfoValue.sleepLatencySeconds),
    sleep_efficiency: sleep_efficiency.toFixed(0),
    effective_sleep_time: effSleepTimeString,
    sahi_3: desatmetrics.ahi3?.toFixed(1) ?? "N/A",
    sahi_4: desatmetrics.ahi4?.toFixed(1) ?? "N/A",
    odi_3: desatmetrics.odi3?.toFixed(1) ?? "N/A",
    odi_4: desatmetrics.odi4?.toFixed(1) ?? "N/A",
    total_resp_events: desatmetrics.totalEvents ?? "N/A",
    spo2_statistics: {
      min: spo2metrics.minSpo2.toFixed(1),
      mean: spo2metrics.meanSpo2.toFixed(1),
      max: spo2metrics.maxSpo2.toFixed(1),
    },
    desat_event_counts: {
      range_4_9: desatmetrics.desatsBelow9,
      range_10_20: desatmetrics.desatsNineTo20,
      range_gt_20: desatmetrics.desatsAbove20,
      total:
        desatmetrics.desatsBelow9 +
        desatmetrics.desatsNineTo20 +
        desatmetrics.desatsAbove20,
    },
    heart_rate_statistics: {
      awake: {
        min: hrmetrics.minHr.toFixed(0),
        mean: hrmetrics.meanHr.toFixed(0),
        max: hrmetrics.maxHr.toFixed(0),
      },
      sleep: {
        min: hrmetrics.minHrSleeping.toFixed(0),
        mean: hrmetrics.meanHrSleeping.toFixed(0),
        max: hrmetrics.maxHrSleeping.toFixed(0),
      },
    },
    total_beats_analyzed: numBeats,
  }

  return (
    <div className="report-page">
      <div id="header">
        <div className="huxley-head-logo">
          <img alt="Huxley Head Logo" src={huxleySmall} />
        </div>
        <div className="title">
          <h2 className="header-title">SANSA Sleep Report</h2>
          <p className="study-id">Study ID: {sleepReportData.study_id}</p>
        </div>
        <div className="address">
          <div className="huxley-info">
            Huxley Medical, Inc.
            <br />
            1465 Northside Dr NW,
            <br />
            Suite 217 Atlanta, GA 30318
            <br />
            (404) 123-4567 | <a href="#">www.huxleymed.com</a>
          </div>
        </div>
      </div>

      <div className="section-header">Study Information</div>

      <div id="patient-info">
        <div className="column">
          <p>
            <strong>Patient Name:</strong> {sleepReportData.patient_name}
          </p>
          <p>
            <strong>DOB:</strong> {sleepReportData.dob}
          </p>
          <p>
            <strong>MRN:</strong> {sleepReportData.mrn}
          </p>
        </div>
        <div className="column">
          <p>
            <strong>Referring Physician:</strong>{" "}
            {sleepReportData.referring_physician}
          </p>
          <p>
            <strong>Interpreting Physician:</strong>{" "}
            {sleepReportData.interpreting_physician}
          </p>
          <div className="scoring-criteria">
            <p>
              <strong>Scoring Criteria:</strong>{" "}
              {sleepReportData.scoring_criteria}
            </p>
          </div>
        </div>
        <div className="column">
          <p>
            <strong>Study Date:</strong> {sleepReportData.study_date}
          </p>
          <p>
            <strong>Study Start Time:</strong>{" "}
            {sleepReportData.study_start_time}
          </p>
          <p>
            <strong>Study End Time:</strong> {sleepReportData.study_end_time}
          </p>
        </div>
      </div>

      <div className="section-header">Summary Data</div>

      <div id="summary">
        <div className="summary-column">
          <table className="data-table">
            <tr>
              <th className="table-head" colSpan={2}>
                <strong>Events Summary</strong>
              </th>
            </tr>
            <tr>
              <td>sAHI 3%</td>
              <td>{sleepReportData.sahi_3}</td>
            </tr>
            <tr>
              <td>sAHI 4%</td>
              <td>{sleepReportData.sahi_4}</td>
            </tr>
            <tr>
              <td>ODI 3%</td>
              <td>{sleepReportData.odi_3}</td>
            </tr>
            <tr>
              <td>ODI 4%</td>
              <td>{sleepReportData.odi_4}</td>
            </tr>
            <tr>
              <td>Total Resp Events</td>
              <td>{sleepReportData.total_resp_events}</td>
            </tr>
          </table>
        </div>
        <div className="summary-column">
          <table className="data-table">
            <tr>
              <th className="table-head" colSpan={2}>
                <strong>Sleep Summary</strong>
              </th>
            </tr>
            <tr>
              <td>Total Study Time</td>
              <td>{sleepReportData.total_study_time}</td>
            </tr>
            <tr>
              <td>Sleep Time</td>
              <td>{sleepReportData.sleep_time}</td>
            </tr>
            <tr>
              <td>Effective Sleep Time</td>
              <td>{sleepReportData.effective_sleep_time}</td>
            </tr>
            <tr>
              <td>Sleep Latency</td>
              <td>{sleepReportData.sleep_latency}</td>
            </tr>
            <tr>
              <td>Sleep Efficiency</td>
              <td>{sleepReportData.sleep_efficiency}%</td>
            </tr>
          </table>
        </div>
        <div className="summary-column">
          <table className="oxygen-sat-table nadirs">
            <tbody>
              <tr className="table-head">
                <th className="table-head" colSpan={3}>
                  SpO2 Statistics (%)
                </th>
              </tr>
              <tr className="center-row">
                <td>Min </td>
                <td>Mean </td>
                <td>Max</td>
              </tr>
              <tr className="center-row">
                <td>{sleepReportData.spo2_statistics.min}</td>
                <td>{sleepReportData.spo2_statistics.mean}</td>
                <td>{sleepReportData.spo2_statistics.max}</td>
              </tr>
            </tbody>
          </table>
          <table className="data-table">
            <tbody>
              <tr className="table-head">
                <th className="table-head" colSpan={5}>
                  Desat Event Counts
                </th>
              </tr>
              <tr className="center-row">
                <td>
                  <strong>{"<"}9%</strong>
                </td>
                <td>
                  <strong>10-20%</strong>
                </td>
                <td>
                  <strong>{">"}20%</strong>
                </td>
                <td>
                  <strong>Total</strong>
                </td>
              </tr>
              <tr>
                <td>{sleepReportData.desat_event_counts.range_4_9}</td>
                <td>{sleepReportData.desat_event_counts.range_10_20}</td>
                <td>{sleepReportData.desat_event_counts.range_gt_20}</td>
                <td>{sleepReportData.desat_event_counts.total}</td>
              </tr>
              <tr>
                <td colSpan={5}>Mean Desat Nadir: {meanOfDesatNadirs}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="summary-column">
          <table className="data-table">
            <tbody>
              <tr className="table-head">
                <th className="table-head" colSpan={4}>
                  Heart Rate Statistics
                </th>
              </tr>
              <tr>
                <td></td>
                <td>Min </td>
                <td>Mean </td>
                <td>Max</td>
              </tr>
              <tr>
                <td>All Study</td>
                <td>{sleepReportData.heart_rate_statistics.awake.min}</td>
                <td>{sleepReportData.heart_rate_statistics.awake.mean}</td>
                <td>{sleepReportData.heart_rate_statistics.awake.max}</td>
              </tr>
              <tr>
                <td>Sleeping</td>
                <td>{sleepReportData.heart_rate_statistics.sleep.min}</td>
                <td>{sleepReportData.heart_rate_statistics.sleep.mean}</td>
                <td>{sleepReportData.heart_rate_statistics.sleep.max}</td>
              </tr>
            </tbody>
          </table>
          <table className="data-table top-border">
            <tbody>
              <tr>
                <td id="ox-desat-nadir-label" colSpan={3}>
                  Total Beats Analyzed:
                </td>
                <td colSpan={1}>{sleepReportData.total_beats_analyzed}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="graph-placeholder">
        {/*Comes from the usePringPDF hook*/}
      </div>
      <div className="sig-block">
        <div className="sig-block-text">
          <span>Signature:_________________________</span>
          <span>Date:____________________</span>
        </div>
      </div>
      <div className="footer-items">
        <div className="footer-left">
          {/* <img
            alt="Huxley Logo"
            className="huxley-glyph-logo-footer"
            src={huxleyMedium}
          /> */}
          &copy; Huxley Medical, Inc.
        </div>
        <div className="footer-right">
          <div className="footer-right-text">
            <span>SW Ver. {sleepReportData.sw_version}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PDFReport
