import Grid from "@mui/joy/Grid/Grid"
import Box from "@mui/joy/Box/Box"
import Input from "@mui/joy/Input/Input"
import TipTap from "../tiptap/editor/TipTap"
import {
  Action,
  InterpretationTemplateFormData,
} from "../../views/scoring/interpretationtemplatemodal/InterpretationTemplateModal"
import { JSONContent } from "@tiptap/core"
import Button from "@mui/joy/Button"
import GridFormInput from "../forms/grid/GridFormInput"

interface InterpretationTemplateFormProps {
  initialInterpretationEditorContentData: JSONContent
  formData: InterpretationTemplateFormData
  dispatch: React.Dispatch<Action>
  formErrors: {
    title: string
    notes: string
  }
  formSubmit: () => void
  formButtonTitle: string
  setBackButton: React.Dispatch<React.SetStateAction<boolean>>
}

const InterpretationTemplateForm = ({
  initialInterpretationEditorContentData,
  formData,
  dispatch,
  formErrors,
  formSubmit,
  formButtonTitle,
  setBackButton,
}: InterpretationTemplateFormProps) => {
  const handlePropChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: "CHANGE",
      payload: { [ev.target.name]: ev.target.value },
    })
  }
  const onContentChange = (contentData: JSONContent) => {
    dispatch({
      type: "CHANGE",
      payload: { notes: contentData },
    })
  }
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    formSubmit()
  }
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <GridFormInput width={12} label="Title" error={formErrors.title}>
          <Input
            name="title"
            value={formData.title}
            onChange={(event) => handlePropChange(event)}
            required
          />
        </GridFormInput>
        <GridFormInput width={12} label="Notes" error={formErrors.notes}>
          <Box className="addTemplateInterpretationEditor">
            <TipTap
              editable={true}
              menuStatus={true}
              extension={[]}
              content={initialInterpretationEditorContentData as JSONContent}
              onContentChange={onContentChange}
            />
          </Box>
        </GridFormInput>
        <Grid
          xs={12}
          sx={{
            display: "flex",
            gap: "1rem",
            justifyContent: "flex-end",
          }}
        >
          <Button variant="outlined" onClick={() => setBackButton(false)}>
            Back
          </Button>
          <Button type="submit">{formButtonTitle}</Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default InterpretationTemplateForm
