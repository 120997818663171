import { useRecoilTransaction_UNSTABLE, useSetRecoilState } from "recoil"
import { usePortalApi } from "../connections"
import { snackAlert } from "../components/SnackAlerts"
import { useEffect } from "react"
import {
  currentStudy,
  fullNightInterval,
  intervalTimeEventTypeAtom,
  isLoadingStudy,
  studyMetaData,
  timeIntervals,
} from "../state/study.state"
import {
  ecgExclusions,
  ppgExclusions,
  ecgBadExclusions,
} from "../state/exclusions.state"
import {
  csdRegionsOfInterest,
  eventFamily,
  eventIDs,
  eventMetaData,
  respiratoryEvents,
  sleepWakeEvents,
} from "../state/event.state"
import { currentEpoch } from "../state/epoch.state"
import { summaryIndices } from "../state/graphSummary.state"
import { allPlotTimeDomain, studySignals } from "../state/signals.state"
import {
  EventApi,
  EventPlot,
  EventType,
  ScorerEventData,
  ScoringCriteria,
} from "../types/event.type"
import { EventsData } from "../interfaces/events.interface"
import { SignalsData } from "../interfaces/signals.interface"
import { handleApiError } from "../utils/apiUtils"
import {
  eventApiData,
  eventApiDataConverter,
  leadOffToExclusion,
  timeFilteredSignal,
} from "../utils/eventUtils"
import { graphTimeIntervals } from "../const/signals.const"
import { ExclusionAnnotation } from "../types/exclusion.type"
import { reportStatus } from "../state/pdfReport.state"
import { SleepStudyOutData } from "../types/study.type"

const useLoadStudy = (studyID: string) => {
  const api = usePortalApi()
  const setIsLoadingStudy = useSetRecoilState(isLoadingStudy)
  const setSnackAlertMsg = useSetRecoilState(snackAlert)
  const setStudyData = useSetRecoilState(studySignals)
  const setReportStatus = useSetRecoilState(reportStatus)
  const createStudyData = useRecoilTransaction_UNSTABLE(
    ({ set, reset }) =>
      async (
        eventsData: EventsData,
        study: SleepStudyOutData | undefined,
        scoringCriteria: ScoringCriteria,
        allEventData: ScorerEventData[]
      ) => {
        const startTime = eventsData.metadata.study_start * 1000
        const endTime = eventsData.metadata.study_end * 1000
        const allNightIntervalTime = endTime - startTime
        set(currentStudy, study)
        set(eventMetaData, eventsData.metadata)
        set(summaryIndices, eventsData.summary_indices)
        set(csdRegionsOfInterest, eventsData.events.csd_regions_of_interest)
        set(studyMetaData, {
          friendlyId: studyID,
          studyStartTime: eventsData.metadata.study_start,
          studyEndTime: eventsData.metadata.study_end,
          scoringCriteria: scoringCriteria,
          swVersion: eventsData.metadata.autoscore_version ?? undefined,
        })
        set(timeIntervals, [
          { label: "All Night", value: allNightIntervalTime },
          ...graphTimeIntervals,
        ])
        set(fullNightInterval, allNightIntervalTime)
        set(intervalTimeEventTypeAtom, allNightIntervalTime)
        set(allPlotTimeDomain, [
          eventsData.metadata.study_start * 1000,
          eventsData.metadata.study_end * 1000,
        ])
        set(respiratoryEvents, eventsData.events.respiratory_events)
        set(sleepWakeEvents, eventsData.events.sleep)
        reset(eventIDs)
        set(ecgExclusions, eventsData.events.ecg_exclusion_annotations ?? [])
        set(ppgExclusions, eventsData.events.ppg_exclusion_annotations ?? [])
        set(ecgBadExclusions, eventsData.events.ecg_bad_annotations ?? [])
        set(currentEpoch, 0)
        const allEventId: number[] = []
        allEventData.forEach(async (eventData: ScorerEventData) => {
          allEventId.push(eventData.id)
          set(eventFamily(eventData.id), eventData)
        })
        set(eventIDs, allEventId)
      }
  )

  const fetchStudy = async (
    studyid: string
  ): Promise<SleepStudyOutData | undefined> => {
    if (!api) return
    try {
      const study = await api.getStudy({ studyId: studyid })
      return study as SleepStudyOutData
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  const fetchApiData = async (url: string) => await (await fetch(url)).json()
  const fetchEventData = async (url: string): Promise<EventsData> => {
    const eventData = await fetchApiData(url)
    return eventData
  }

  const setApiDataToPlots = async (
    signalData: SignalsData,
    eventsData: EventsData
  ) => {
    setStudyData({
      metadata: signalData.metadata, //TODO this is not actually used after we set it.  Should change the interface to split the two types
      hr: signalData.hr
        ? timeFilteredSignal(
            signalData.hr,
            eventsData.metadata.study_start,
            eventsData.metadata.study_end
          )
        : undefined,
      resp_effort: signalData.resp
        ? timeFilteredSignal(
            signalData.resp,
            eventsData.metadata.study_start,
            eventsData.metadata.study_end
          )
        : undefined,
      spo2: signalData.spo2
        ? timeFilteredSignal(
            signalData.spo2,
            eventsData.metadata.study_start,
            eventsData.metadata.study_end
          )
        : undefined,
      body_position: signalData.body_position
        ? timeFilteredSignal(
            signalData.body_position,
            eventsData.metadata.study_start,
            eventsData.metadata.study_end
          )
        : undefined,
      snore_envelop: signalData.snore_envelop
        ? timeFilteredSignal(
            signalData.snore_envelop,
            eventsData.metadata.study_start,
            eventsData.metadata.study_end
          )
        : undefined,
      chest_movement: signalData.chest_movement
        ? timeFilteredSignal(
            signalData.chest_movement,
            eventsData.metadata.study_start,
            eventsData.metadata.study_end
          )
        : undefined,
      actigraphy: signalData.actigraphy
        ? timeFilteredSignal(
            signalData.actigraphy,
            eventsData.metadata.study_start,
            eventsData.metadata.study_end
          )
        : undefined,
      ecg: signalData.ecg
        ? timeFilteredSignal(
            signalData.ecg,
            eventsData.metadata.study_start,
            eventsData.metadata.study_end
          )
        : undefined,
    })
  }

  useEffect(() => {
    setIsLoadingStudy(true)
    if (!api) return

    const fetchData = async () => {
      try {
        const study = await fetchStudy(studyID)
        if (!study) {
          throw new Error("Failed to fetch study data")
        }
        const urlresponse = await api.getStudyUrl({
          studyId: studyID,
        })
        if (!urlresponse) {
          throw new Error("Failed to fetch study data")
        }
        const { ahi3Url, ahi4Url, signalsUrl, pulseAnnotationsUrl } =
          urlresponse
        const signalData = await fetchApiData(signalsUrl)
        const scoringCriteria =
          study && study.studyOrder.oxygenDesatThreshold === "three"
            ? ScoringCriteria.THREE
            : ScoringCriteria.FOUR

        const eventsData: EventsData | undefined =
          scoringCriteria === ScoringCriteria.THREE
            ? ((await fetchEventData(ahi3Url)) as EventsData)
            : ((await fetchEventData(ahi4Url)) as EventsData)

        if (!eventsData) {
          throw new Error("Study has no event data")
        }
        // These values aren't extracted nor edited; save them to include in the interpret study request input
        const respiratory_events = eventsData.events.final_ahi_events
          ? eventApiDataConverter(
              eventsData.events.final_ahi_events,
              "HR" as EventPlot,
              "Resp" as EventType,
              studyID
            )
          : []
        const desat_events = eventsData.events.desat_events
          ? eventApiDataConverter(
              eventsData.events.desat_events,
              "SpO2" as EventPlot,
              "Desat" as EventType,
              studyID
            )
          : []
        if (!pulseAnnotationsUrl) {
          await createStudyData(eventsData, study, scoringCriteria, [
            ...respiratory_events,
            ...desat_events,
          ])
          await setApiDataToPlots(signalData, eventsData)
          setIsLoadingStudy(false)
          return
        }

        const ecgEventConverter = (
          ecgEvent: EventApi[],
          eventPlot: EventPlot,
          eventType: EventType,
          studyID: string,
          ecgCondition: string[]
        ) => {
          const ecgEventData: ScorerEventData[] = []
          for (let i = 0; i < ecgEvent.length; i++) {
            const event = ecgEvent[i]
            if (!ecgCondition.includes(event.event_data.label)) {
              ecgEventData.push(
                eventApiData(event, eventPlot, eventType, studyID)
              )
            }
            if (
              eventType === "Rhythms" &&
              event.event_data.label === "BAD_QUALITY"
            ) {
              ecg_bad_exclusions.push(event as unknown as ExclusionAnnotation)
            }
          }
          return ecgEventData
        }
        const ecgAnn = await fetchApiData(pulseAnnotationsUrl as string)
        if (ecgAnn && ecgAnn.heart_rate) {
          signalData.hr = {
            timestamps: ecgAnn.heart_rate.timestamps,
            values: ecgAnn.heart_rate.values,
          }
        }
        const ecg_bad_exclusions: ExclusionAnnotation[] = []
        const rhythmCondition = ["Normal Sinus Rhythm", "NSR", "BAD_QUALITY"]
        const beatsCondition = ["N"]
        const ecg_rhythms: ScorerEventData[] | [] = ecgAnn.rhythms
          ? ecgEventConverter(
              ecgAnn.rhythms,
              "ECG" as EventPlot,
              "Rhythms" as EventType,
              studyID,
              rhythmCondition
            )
          : []
        const ecg_beats: ScorerEventData[] | [] = ecgAnn.beats
          ? ecgEventConverter(
              ecgAnn.beats,
              "ECG" as EventPlot,
              "Beats" as EventType,
              studyID,
              beatsCondition
            )
          : []
        if (ecgAnn.leads_off_events) {
          ecg_bad_exclusions.push(
            ...leadOffToExclusion(ecgAnn.leads_off_events)
          )
        }

        eventsData.events.ecg_bad_annotations = ecg_bad_exclusions

        if (
          ecgAnn?.rhythms?.length > 0 ||
          ecgAnn?.beats?.length > 0 ||
          ecgAnn?.leads_off_events?.length > 0
        ) {
          setReportStatus(true)
        }
        const allEventData = [
          ...respiratory_events,
          ...desat_events,
          ...ecg_rhythms,
          ...ecg_beats,
        ]
        await createStudyData(eventsData, study, scoringCriteria, allEventData)
        await setApiDataToPlots(signalData, eventsData)
        setIsLoadingStudy(false)
      } catch (error) {
        handleApiError(setSnackAlertMsg)(error)
      }
    }
    fetchData()
  }, [api])
}

export default useLoadStudy
