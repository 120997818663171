/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AbortUploadIn,
  AbortUploadOut,
  CompleteUploadIn,
  CreateEcgReportOut,
  DeviceIn,
  DeviceOut,
  DeviceUpdate,
  ErrorSchema,
  InterpretStudyIn,
  InterpretationTemplateIn,
  InterpretationTemplateOut,
  InterpretationTemplateUpdate,
  OrganizationIn,
  OrganizationLocationOut,
  OrganizationOut,
  OrganizationUpdate,
  PagedDeviceOut,
  PagedInterpretationTemplateOut,
  PagedOrganizationOut,
  PagedPatientOut,
  PagedSleepStudyOrderOut,
  PagedUserOut,
  PatientIn,
  PatientOut,
  PatientUpdate,
  RequestUploadIn,
  RequestUploadOut,
  SessionKeyOut,
  SignatureUploadOut,
  SleepStudyIn,
  SleepStudyOrderIn,
  SleepStudyOrderOut,
  SleepStudyOrderUpdate,
  SleepStudyOut,
  StudyDataUrlOut,
  StudyEdfDownload,
  UserIn,
  UserOut,
  UserUpdate,
} from '../models/index';
import {
    AbortUploadInFromJSON,
    AbortUploadInToJSON,
    AbortUploadOutFromJSON,
    AbortUploadOutToJSON,
    CompleteUploadInFromJSON,
    CompleteUploadInToJSON,
    CreateEcgReportOutFromJSON,
    CreateEcgReportOutToJSON,
    DeviceInFromJSON,
    DeviceInToJSON,
    DeviceOutFromJSON,
    DeviceOutToJSON,
    DeviceUpdateFromJSON,
    DeviceUpdateToJSON,
    ErrorSchemaFromJSON,
    ErrorSchemaToJSON,
    InterpretStudyInFromJSON,
    InterpretStudyInToJSON,
    InterpretationTemplateInFromJSON,
    InterpretationTemplateInToJSON,
    InterpretationTemplateOutFromJSON,
    InterpretationTemplateOutToJSON,
    InterpretationTemplateUpdateFromJSON,
    InterpretationTemplateUpdateToJSON,
    OrganizationInFromJSON,
    OrganizationInToJSON,
    OrganizationLocationOutFromJSON,
    OrganizationLocationOutToJSON,
    OrganizationOutFromJSON,
    OrganizationOutToJSON,
    OrganizationUpdateFromJSON,
    OrganizationUpdateToJSON,
    PagedDeviceOutFromJSON,
    PagedDeviceOutToJSON,
    PagedInterpretationTemplateOutFromJSON,
    PagedInterpretationTemplateOutToJSON,
    PagedOrganizationOutFromJSON,
    PagedOrganizationOutToJSON,
    PagedPatientOutFromJSON,
    PagedPatientOutToJSON,
    PagedSleepStudyOrderOutFromJSON,
    PagedSleepStudyOrderOutToJSON,
    PagedUserOutFromJSON,
    PagedUserOutToJSON,
    PatientInFromJSON,
    PatientInToJSON,
    PatientOutFromJSON,
    PatientOutToJSON,
    PatientUpdateFromJSON,
    PatientUpdateToJSON,
    RequestUploadInFromJSON,
    RequestUploadInToJSON,
    RequestUploadOutFromJSON,
    RequestUploadOutToJSON,
    SessionKeyOutFromJSON,
    SessionKeyOutToJSON,
    SignatureUploadOutFromJSON,
    SignatureUploadOutToJSON,
    SleepStudyInFromJSON,
    SleepStudyInToJSON,
    SleepStudyOrderInFromJSON,
    SleepStudyOrderInToJSON,
    SleepStudyOrderOutFromJSON,
    SleepStudyOrderOutToJSON,
    SleepStudyOrderUpdateFromJSON,
    SleepStudyOrderUpdateToJSON,
    SleepStudyOutFromJSON,
    SleepStudyOutToJSON,
    StudyDataUrlOutFromJSON,
    StudyDataUrlOutToJSON,
    StudyEdfDownloadFromJSON,
    StudyEdfDownloadToJSON,
    UserInFromJSON,
    UserInToJSON,
    UserOutFromJSON,
    UserOutToJSON,
    UserUpdateFromJSON,
    UserUpdateToJSON,
} from '../models/index';

export interface AbortStudyUploadRequest {
    abortUploadIn: AbortUploadIn;
}

export interface CompleteStudyUploadRequest {
    completeUploadIn: CompleteUploadIn;
}

export interface CreateDeviceRequest {
    deviceIn: DeviceIn;
}

export interface CreateInterpretationTemplateRequest {
    interpretationTemplateIn: InterpretationTemplateIn;
}

export interface CreateOrganizationRequest {
    organizationIn: OrganizationIn;
}

export interface CreatePatientRequest {
    patientIn: PatientIn;
}

export interface CreateStudyEcgReportRequest {
    studyId: string;
}

export interface CreateStudyOrderRequest {
    sleepStudyOrderIn: SleepStudyOrderIn;
}

export interface CreateUserRequest {
    userIn: UserIn;
}

export interface DeleteDeviceRequest {
    serialNumber: string;
}

export interface DeleteInterpretationTemplateRequest {
    templateId: string;
}

export interface DeleteOrganizationRequest {
    organizationId: string;
}

export interface DeletePatientRequest {
    patientId: string;
}

export interface DeleteStudyOrderRequest {
    studyOrderId: string;
}

export interface DeleteUserRequest {
    userId: string;
}

export interface GetAllDevicesRequest {
    includeDeleted?: boolean;
    serialNumber?: string;
    limit?: number;
    offset?: number;
}

export interface GetAllInterpretationTemplatesRequest {
    includeDeleted?: boolean;
    templateTitle?: string;
    limit?: number;
    offset?: number;
}

export interface GetAllOrganizationsRequest {
    includeDeleted?: boolean;
    name?: string;
    enabled?: boolean;
    limit?: number;
    offset?: number;
}

export interface GetAllPatientsRequest {
    includeDeleted?: boolean;
    organizationId?: string;
    name?: string;
    firstName?: string;
    lastName?: string;
    mrn?: string;
    dob?: string;
    studyStatuses?: Array<GetAllPatientsStudyStatusesEnum>;
    limit?: number;
    offset?: number;
}

export interface GetAllStudyOrdersRequest {
    includeDeleted?: boolean;
    organizationId?: string;
    organizationName?: string;
    mrn?: string;
    patientId?: string;
    patientName?: string;
    studyStart?: string;
    studyStatuses?: Array<GetAllStudyOrdersStudyStatusesEnum>;
    orderingPhysicianId?: string;
    interpretingPhysicianId?: string;
    interpretingPhysicianName?: string;
    referringPhysicianName?: string;
    limit?: number;
    offset?: number;
}

export interface GetAllUsersRequest {
    includeDeleted?: boolean;
    organizationId?: string;
    text?: string;
    roles?: Array<string>;
    statuses?: Array<string>;
    limit?: number;
    offset?: number;
}

export interface GetIndividualDeviceRequest {
    serialNumber: string;
}

export interface GetOrganizationRequest {
    organizationId: string;
}

export interface GetPatientRequest {
    patientId: string;
}

export interface GetSignatureUrlRequest {
    physicianId: string;
}

export interface GetStudyRequest {
    studyId: string;
}

export interface GetStudyEdfRequest {
    studyId: string;
}

export interface GetStudyOrderRequest {
    studyOrderId: string;
}

export interface GetStudyUrlRequest {
    studyId: string;
}

export interface GetUserRequest {
    userId: string;
}

export interface HstportalViewsInternalPulseaiCallbackRequest {
    studyId: string;
}

export interface InterpretStudyRequest {
    studyId: string;
    interpretStudyIn: InterpretStudyIn;
}

export interface ResetUserPasswordRequest {
    userId: string;
}

export interface RestoreDeviceRequest {
    serialNumber: string;
}

export interface RestoreOrganizationRequest {
    organizationId: string;
}

export interface RestorePatientRequest {
    patientId: string;
}

export interface RestoreStudyOrderRequest {
    studyOrderId: string;
}

export interface RestoreUserRequest {
    userId: string;
}

export interface UpdateDeviceRequest {
    serialNumber: string;
    deviceUpdate: DeviceUpdate;
}

export interface UpdateInterpretationTemplateRequest {
    templateId: string;
    interpretationTemplateUpdate: InterpretationTemplateUpdate;
}

export interface UpdateOrganizationRequest {
    organizationId: string;
    organizationUpdate: OrganizationUpdate;
}

export interface UpdateOrganizationLocationRequest {
    locationId: string;
}

export interface UpdatePatientRequest {
    patientId: string;
    patientUpdate: PatientUpdate;
}

export interface UpdateStudyRequest {
    studyId: string;
    sleepStudyIn: SleepStudyIn;
}

export interface UpdateStudyOrderRequest {
    studyOrderId: string;
    sleepStudyOrderUpdate: SleepStudyOrderUpdate;
}

export interface UpdateUserRequest {
    userId: string;
    userUpdate: UserUpdate;
}

export interface UploadSignatureRequest {
    physicianId: string;
    file: Blob;
}

export interface UploadStudyRequest {
    studyOrderId: string;
    requestUploadIn: RequestUploadIn;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Abort Study Upload
     */
    async abortStudyUploadRaw(requestParameters: AbortStudyUploadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AbortUploadOut>> {
        if (requestParameters['abortUploadIn'] == null) {
            throw new runtime.RequiredError(
                'abortUploadIn',
                'Required parameter "abortUploadIn" was null or undefined when calling abortStudyUpload().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/abort-upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AbortUploadInToJSON(requestParameters['abortUploadIn']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AbortUploadOutFromJSON(jsonValue));
    }

    /**
     * Abort Study Upload
     */
    async abortStudyUpload(requestParameters: AbortStudyUploadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AbortUploadOut> {
        const response = await this.abortStudyUploadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Complete Study Upload
     */
    async completeStudyUploadRaw(requestParameters: CompleteStudyUploadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['completeUploadIn'] == null) {
            throw new runtime.RequiredError(
                'completeUploadIn',
                'Required parameter "completeUploadIn" was null or undefined when calling completeStudyUpload().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/complete-study-upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompleteUploadInToJSON(requestParameters['completeUploadIn']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Complete Study Upload
     */
    async completeStudyUpload(requestParameters: CompleteStudyUploadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.completeStudyUploadRaw(requestParameters, initOverrides);
    }

    /**
     * Create Device
     */
    async createDeviceRaw(requestParameters: CreateDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeviceOut>> {
        if (requestParameters['deviceIn'] == null) {
            throw new runtime.RequiredError(
                'deviceIn',
                'Required parameter "deviceIn" was null or undefined when calling createDevice().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/devices`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeviceInToJSON(requestParameters['deviceIn']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceOutFromJSON(jsonValue));
    }

    /**
     * Create Device
     */
    async createDevice(requestParameters: CreateDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeviceOut> {
        const response = await this.createDeviceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Interpretation Template
     */
    async createInterpretationTemplateRaw(requestParameters: CreateInterpretationTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InterpretationTemplateOut>> {
        if (requestParameters['interpretationTemplateIn'] == null) {
            throw new runtime.RequiredError(
                'interpretationTemplateIn',
                'Required parameter "interpretationTemplateIn" was null or undefined when calling createInterpretationTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/interpretation-templates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InterpretationTemplateInToJSON(requestParameters['interpretationTemplateIn']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InterpretationTemplateOutFromJSON(jsonValue));
    }

    /**
     * Create Interpretation Template
     */
    async createInterpretationTemplate(requestParameters: CreateInterpretationTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InterpretationTemplateOut> {
        const response = await this.createInterpretationTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Organization
     */
    async createOrganizationRaw(requestParameters: CreateOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationOut>> {
        if (requestParameters['organizationIn'] == null) {
            throw new runtime.RequiredError(
                'organizationIn',
                'Required parameter "organizationIn" was null or undefined when calling createOrganization().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/organizations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrganizationInToJSON(requestParameters['organizationIn']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationOutFromJSON(jsonValue));
    }

    /**
     * Create Organization
     */
    async createOrganization(requestParameters: CreateOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationOut> {
        const response = await this.createOrganizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Patient
     */
    async createPatientRaw(requestParameters: CreatePatientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientOut>> {
        if (requestParameters['patientIn'] == null) {
            throw new runtime.RequiredError(
                'patientIn',
                'Required parameter "patientIn" was null or undefined when calling createPatient().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/patients`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PatientInToJSON(requestParameters['patientIn']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientOutFromJSON(jsonValue));
    }

    /**
     * Create Patient
     */
    async createPatient(requestParameters: CreatePatientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientOut> {
        const response = await this.createPatientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for generating ECG PDF reports via Lambda. Returns a pre-signed URL to the future report.
     * Create Ecg Report
     */
    async createStudyEcgReportRaw(requestParameters: CreateStudyEcgReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateEcgReportOut>> {
        if (requestParameters['studyId'] == null) {
            throw new runtime.RequiredError(
                'studyId',
                'Required parameter "studyId" was null or undefined when calling createStudyEcgReport().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/studies/{study_id}/reports/ecg`.replace(`{${"study_id"}}`, encodeURIComponent(String(requestParameters['studyId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateEcgReportOutFromJSON(jsonValue));
    }

    /**
     * Endpoint for generating ECG PDF reports via Lambda. Returns a pre-signed URL to the future report.
     * Create Ecg Report
     */
    async createStudyEcgReport(requestParameters: CreateStudyEcgReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateEcgReportOut> {
        const response = await this.createStudyEcgReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Study Order
     */
    async createStudyOrderRaw(requestParameters: CreateStudyOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SleepStudyOrderOut>> {
        if (requestParameters['sleepStudyOrderIn'] == null) {
            throw new runtime.RequiredError(
                'sleepStudyOrderIn',
                'Required parameter "sleepStudyOrderIn" was null or undefined when calling createStudyOrder().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/study-orders`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SleepStudyOrderInToJSON(requestParameters['sleepStudyOrderIn']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SleepStudyOrderOutFromJSON(jsonValue));
    }

    /**
     * Create Study Order
     */
    async createStudyOrder(requestParameters: CreateStudyOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SleepStudyOrderOut> {
        const response = await this.createStudyOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create User
     */
    async createUserRaw(requestParameters: CreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserOut>> {
        if (requestParameters['userIn'] == null) {
            throw new runtime.RequiredError(
                'userIn',
                'Required parameter "userIn" was null or undefined when calling createUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserInToJSON(requestParameters['userIn']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserOutFromJSON(jsonValue));
    }

    /**
     * Create User
     */
    async createUser(requestParameters: CreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserOut> {
        const response = await this.createUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Current User
     */
    async currentUserRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserOut>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/current-user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserOutFromJSON(jsonValue));
    }

    /**
     * Get Current User
     */
    async currentUser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserOut> {
        const response = await this.currentUserRaw(initOverrides);
        return await response.value();
    }

    /**
     * Delete Device
     */
    async deleteDeviceRaw(requestParameters: DeleteDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeviceOut>> {
        if (requestParameters['serialNumber'] == null) {
            throw new runtime.RequiredError(
                'serialNumber',
                'Required parameter "serialNumber" was null or undefined when calling deleteDevice().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/devices/{serial_number}`.replace(`{${"serial_number"}}`, encodeURIComponent(String(requestParameters['serialNumber']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceOutFromJSON(jsonValue));
    }

    /**
     * Delete Device
     */
    async deleteDevice(requestParameters: DeleteDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeviceOut> {
        const response = await this.deleteDeviceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Interpretation Template
     */
    async deleteInterpretationTemplateRaw(requestParameters: DeleteInterpretationTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InterpretationTemplateOut>> {
        if (requestParameters['templateId'] == null) {
            throw new runtime.RequiredError(
                'templateId',
                'Required parameter "templateId" was null or undefined when calling deleteInterpretationTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/interpretation-templates/{template_id}`.replace(`{${"template_id"}}`, encodeURIComponent(String(requestParameters['templateId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InterpretationTemplateOutFromJSON(jsonValue));
    }

    /**
     * Delete Interpretation Template
     */
    async deleteInterpretationTemplate(requestParameters: DeleteInterpretationTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InterpretationTemplateOut> {
        const response = await this.deleteInterpretationTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * DELETE is idempotent, so if the organization is already deleted, return 200. `get_object_or_404` is not used because it uses `model.objects` and thus will return 404 even if the organization exists but is already deleted.
     * Delete Organization
     */
    async deleteOrganizationRaw(requestParameters: DeleteOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationOut>> {
        if (requestParameters['organizationId'] == null) {
            throw new runtime.RequiredError(
                'organizationId',
                'Required parameter "organizationId" was null or undefined when calling deleteOrganization().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/organizations/{organization_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters['organizationId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationOutFromJSON(jsonValue));
    }

    /**
     * DELETE is idempotent, so if the organization is already deleted, return 200. `get_object_or_404` is not used because it uses `model.objects` and thus will return 404 even if the organization exists but is already deleted.
     * Delete Organization
     */
    async deleteOrganization(requestParameters: DeleteOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationOut> {
        const response = await this.deleteOrganizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Patient
     */
    async deletePatientRaw(requestParameters: DeletePatientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientOut>> {
        if (requestParameters['patientId'] == null) {
            throw new runtime.RequiredError(
                'patientId',
                'Required parameter "patientId" was null or undefined when calling deletePatient().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/patients/{patient_id}`.replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters['patientId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientOutFromJSON(jsonValue));
    }

    /**
     * Delete Patient
     */
    async deletePatient(requestParameters: DeletePatientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientOut> {
        const response = await this.deletePatientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Study Order
     */
    async deleteStudyOrderRaw(requestParameters: DeleteStudyOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SleepStudyOrderOut>> {
        if (requestParameters['studyOrderId'] == null) {
            throw new runtime.RequiredError(
                'studyOrderId',
                'Required parameter "studyOrderId" was null or undefined when calling deleteStudyOrder().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/study-orders/{study_order_id}`.replace(`{${"study_order_id"}}`, encodeURIComponent(String(requestParameters['studyOrderId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SleepStudyOrderOutFromJSON(jsonValue));
    }

    /**
     * Delete Study Order
     */
    async deleteStudyOrder(requestParameters: DeleteStudyOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SleepStudyOrderOut> {
        const response = await this.deleteStudyOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * DELETE is idempotent, so if the user is already deleted, return 200. `get_object_or_404` is not used because it uses `model.objects` and thus will return 404 even if the user exists but is already deleted.
     * Delete User
     */
    async deleteUserRaw(requestParameters: DeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserOut>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling deleteUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserOutFromJSON(jsonValue));
    }

    /**
     * DELETE is idempotent, so if the user is already deleted, return 200. `get_object_or_404` is not used because it uses `model.objects` and thus will return 404 even if the user exists but is already deleted.
     * Delete User
     */
    async deleteUser(requestParameters: DeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserOut> {
        const response = await this.deleteUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Devices
     */
    async getAllDevicesRaw(requestParameters: GetAllDevicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedDeviceOut>> {
        const queryParameters: any = {};

        if (requestParameters['includeDeleted'] != null) {
            queryParameters['include_deleted'] = requestParameters['includeDeleted'];
        }

        if (requestParameters['serialNumber'] != null) {
            queryParameters['serial_number'] = requestParameters['serialNumber'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/devices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedDeviceOutFromJSON(jsonValue));
    }

    /**
     * List Devices
     */
    async getAllDevices(requestParameters: GetAllDevicesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedDeviceOut> {
        const response = await this.getAllDevicesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Interpretation Templates
     */
    async getAllInterpretationTemplatesRaw(requestParameters: GetAllInterpretationTemplatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedInterpretationTemplateOut>> {
        const queryParameters: any = {};

        if (requestParameters['includeDeleted'] != null) {
            queryParameters['include_deleted'] = requestParameters['includeDeleted'];
        }

        if (requestParameters['templateTitle'] != null) {
            queryParameters['template_title'] = requestParameters['templateTitle'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/interpretation-templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedInterpretationTemplateOutFromJSON(jsonValue));
    }

    /**
     * List Interpretation Templates
     */
    async getAllInterpretationTemplates(requestParameters: GetAllInterpretationTemplatesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedInterpretationTemplateOut> {
        const response = await this.getAllInterpretationTemplatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Organizations
     */
    async getAllOrganizationsRaw(requestParameters: GetAllOrganizationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedOrganizationOut>> {
        const queryParameters: any = {};

        if (requestParameters['includeDeleted'] != null) {
            queryParameters['include_deleted'] = requestParameters['includeDeleted'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['enabled'] != null) {
            queryParameters['enabled'] = requestParameters['enabled'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/organizations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedOrganizationOutFromJSON(jsonValue));
    }

    /**
     * List Organizations
     */
    async getAllOrganizations(requestParameters: GetAllOrganizationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedOrganizationOut> {
        const response = await this.getAllOrganizationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Patients
     */
    async getAllPatientsRaw(requestParameters: GetAllPatientsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedPatientOut>> {
        const queryParameters: any = {};

        if (requestParameters['includeDeleted'] != null) {
            queryParameters['include_deleted'] = requestParameters['includeDeleted'];
        }

        if (requestParameters['organizationId'] != null) {
            queryParameters['organization_id'] = requestParameters['organizationId'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['firstName'] != null) {
            queryParameters['first_name'] = requestParameters['firstName'];
        }

        if (requestParameters['lastName'] != null) {
            queryParameters['last_name'] = requestParameters['lastName'];
        }

        if (requestParameters['mrn'] != null) {
            queryParameters['mrn'] = requestParameters['mrn'];
        }

        if (requestParameters['dob'] != null) {
            queryParameters['dob'] = requestParameters['dob'];
        }

        if (requestParameters['studyStatuses'] != null) {
            queryParameters['study_statuses'] = requestParameters['studyStatuses'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/patients`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedPatientOutFromJSON(jsonValue));
    }

    /**
     * List Patients
     */
    async getAllPatients(requestParameters: GetAllPatientsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedPatientOut> {
        const response = await this.getAllPatientsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Study Orders
     */
    async getAllStudyOrdersRaw(requestParameters: GetAllStudyOrdersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedSleepStudyOrderOut>> {
        const queryParameters: any = {};

        if (requestParameters['includeDeleted'] != null) {
            queryParameters['include_deleted'] = requestParameters['includeDeleted'];
        }

        if (requestParameters['organizationId'] != null) {
            queryParameters['organization_id'] = requestParameters['organizationId'];
        }

        if (requestParameters['organizationName'] != null) {
            queryParameters['organization_name'] = requestParameters['organizationName'];
        }

        if (requestParameters['mrn'] != null) {
            queryParameters['mrn'] = requestParameters['mrn'];
        }

        if (requestParameters['patientId'] != null) {
            queryParameters['patient_id'] = requestParameters['patientId'];
        }

        if (requestParameters['patientName'] != null) {
            queryParameters['patient_name'] = requestParameters['patientName'];
        }

        if (requestParameters['studyStart'] != null) {
            queryParameters['study_start'] = requestParameters['studyStart'];
        }

        if (requestParameters['studyStatuses'] != null) {
            queryParameters['study_statuses'] = requestParameters['studyStatuses'];
        }

        if (requestParameters['orderingPhysicianId'] != null) {
            queryParameters['ordering_physician_id'] = requestParameters['orderingPhysicianId'];
        }

        if (requestParameters['interpretingPhysicianId'] != null) {
            queryParameters['interpreting_physician_id'] = requestParameters['interpretingPhysicianId'];
        }

        if (requestParameters['interpretingPhysicianName'] != null) {
            queryParameters['interpreting_physician_name'] = requestParameters['interpretingPhysicianName'];
        }

        if (requestParameters['referringPhysicianName'] != null) {
            queryParameters['referring_physician_name'] = requestParameters['referringPhysicianName'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/study-orders`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedSleepStudyOrderOutFromJSON(jsonValue));
    }

    /**
     * List Study Orders
     */
    async getAllStudyOrders(requestParameters: GetAllStudyOrdersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedSleepStudyOrderOut> {
        const response = await this.getAllStudyOrdersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Users
     */
    async getAllUsersRaw(requestParameters: GetAllUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedUserOut>> {
        const queryParameters: any = {};

        if (requestParameters['includeDeleted'] != null) {
            queryParameters['include_deleted'] = requestParameters['includeDeleted'];
        }

        if (requestParameters['organizationId'] != null) {
            queryParameters['organization_id'] = requestParameters['organizationId'];
        }

        if (requestParameters['text'] != null) {
            queryParameters['text'] = requestParameters['text'];
        }

        if (requestParameters['roles'] != null) {
            queryParameters['roles'] = requestParameters['roles'];
        }

        if (requestParameters['statuses'] != null) {
            queryParameters['statuses'] = requestParameters['statuses'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedUserOutFromJSON(jsonValue));
    }

    /**
     * List Users
     */
    async getAllUsers(requestParameters: GetAllUsersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedUserOut> {
        const response = await this.getAllUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Device
     */
    async getIndividualDeviceRaw(requestParameters: GetIndividualDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeviceOut>> {
        if (requestParameters['serialNumber'] == null) {
            throw new runtime.RequiredError(
                'serialNumber',
                'Required parameter "serialNumber" was null or undefined when calling getIndividualDevice().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/devices/{serial_number}`.replace(`{${"serial_number"}}`, encodeURIComponent(String(requestParameters['serialNumber']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceOutFromJSON(jsonValue));
    }

    /**
     * Get Device
     */
    async getIndividualDevice(requestParameters: GetIndividualDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeviceOut> {
        const response = await this.getIndividualDeviceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Organization
     */
    async getOrganizationRaw(requestParameters: GetOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationOut>> {
        if (requestParameters['organizationId'] == null) {
            throw new runtime.RequiredError(
                'organizationId',
                'Required parameter "organizationId" was null or undefined when calling getOrganization().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/organizations/{organization_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters['organizationId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationOutFromJSON(jsonValue));
    }

    /**
     * Get Organization
     */
    async getOrganization(requestParameters: GetOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationOut> {
        const response = await this.getOrganizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Patient
     */
    async getPatientRaw(requestParameters: GetPatientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientOut>> {
        if (requestParameters['patientId'] == null) {
            throw new runtime.RequiredError(
                'patientId',
                'Required parameter "patientId" was null or undefined when calling getPatient().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/patients/{patient_id}`.replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters['patientId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientOutFromJSON(jsonValue));
    }

    /**
     * Get Patient
     */
    async getPatient(requestParameters: GetPatientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientOut> {
        const response = await this.getPatientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Signature Url
     */
    async getSignatureUrlRaw(requestParameters: GetSignatureUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['physicianId'] == null) {
            throw new runtime.RequiredError(
                'physicianId',
                'Required parameter "physicianId" was null or undefined when calling getSignatureUrl().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/signatures/{physician_id}`.replace(`{${"physician_id"}}`, encodeURIComponent(String(requestParameters['physicianId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get Signature Url
     */
    async getSignatureUrl(requestParameters: GetSignatureUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getSignatureUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Study
     */
    async getStudyRaw(requestParameters: GetStudyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SleepStudyOut>> {
        if (requestParameters['studyId'] == null) {
            throw new runtime.RequiredError(
                'studyId',
                'Required parameter "studyId" was null or undefined when calling getStudy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/studies/{study_id}`.replace(`{${"study_id"}}`, encodeURIComponent(String(requestParameters['studyId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SleepStudyOutFromJSON(jsonValue));
    }

    /**
     * Get Study
     */
    async getStudy(requestParameters: GetStudyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SleepStudyOut> {
        const response = await this.getStudyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint that returns a presigned URL for the EDF file for a specific study
     * Get Edf For Study
     */
    async getStudyEdfRaw(requestParameters: GetStudyEdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StudyEdfDownload>> {
        if (requestParameters['studyId'] == null) {
            throw new runtime.RequiredError(
                'studyId',
                'Required parameter "studyId" was null or undefined when calling getStudyEdf().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/studies/{study_id}/edf`.replace(`{${"study_id"}}`, encodeURIComponent(String(requestParameters['studyId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StudyEdfDownloadFromJSON(jsonValue));
    }

    /**
     * Endpoint that returns a presigned URL for the EDF file for a specific study
     * Get Edf For Study
     */
    async getStudyEdf(requestParameters: GetStudyEdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StudyEdfDownload> {
        const response = await this.getStudyEdfRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Study Order
     */
    async getStudyOrderRaw(requestParameters: GetStudyOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SleepStudyOrderOut>> {
        if (requestParameters['studyOrderId'] == null) {
            throw new runtime.RequiredError(
                'studyOrderId',
                'Required parameter "studyOrderId" was null or undefined when calling getStudyOrder().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/study-orders/{study_order_id}`.replace(`{${"study_order_id"}}`, encodeURIComponent(String(requestParameters['studyOrderId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SleepStudyOrderOutFromJSON(jsonValue));
    }

    /**
     * Get Study Order
     */
    async getStudyOrder(requestParameters: GetStudyOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SleepStudyOrderOut> {
        const response = await this.getStudyOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Study Data Url
     */
    async getStudyUrlRaw(requestParameters: GetStudyUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StudyDataUrlOut>> {
        if (requestParameters['studyId'] == null) {
            throw new runtime.RequiredError(
                'studyId',
                'Required parameter "studyId" was null or undefined when calling getStudyUrl().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/study-data-url/{study_id}`.replace(`{${"study_id"}}`, encodeURIComponent(String(requestParameters['studyId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StudyDataUrlOutFromJSON(jsonValue));
    }

    /**
     * Get Study Data Url
     */
    async getStudyUrl(requestParameters: GetStudyUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StudyDataUrlOut> {
        const response = await this.getStudyUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get User
     */
    async getUserRaw(requestParameters: GetUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserOut>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserOutFromJSON(jsonValue));
    }

    /**
     * Get User
     */
    async getUser(requestParameters: GetUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserOut> {
        const response = await this.getUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Healthcheck
     */
    async hstportalViewsInternalHealthcheckRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/healthcheck`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Healthcheck
     */
    async hstportalViewsInternalHealthcheck(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.hstportalViewsInternalHealthcheckRaw(initOverrides);
    }

    /**
     * Webhook for PulseAI async integration, this endpoint is hit when PulseAI has finished processing ECG data and is returning their results.
     * Pulseai Callback
     */
    async hstportalViewsInternalPulseaiCallbackRaw(requestParameters: HstportalViewsInternalPulseaiCallbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['studyId'] == null) {
            throw new runtime.RequiredError(
                'studyId',
                'Required parameter "studyId" was null or undefined when calling hstportalViewsInternalPulseaiCallback().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/pulseai/callback/{study_id}`.replace(`{${"study_id"}}`, encodeURIComponent(String(requestParameters['studyId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Webhook for PulseAI async integration, this endpoint is hit when PulseAI has finished processing ECG data and is returning their results.
     * Pulseai Callback
     */
    async hstportalViewsInternalPulseaiCallback(requestParameters: HstportalViewsInternalPulseaiCallbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.hstportalViewsInternalPulseaiCallbackRaw(requestParameters, initOverrides);
    }

    /**
     * Complete a physician interpretation of a study and update event data if modified.
     * Interpret Study
     */
    async interpretStudyRaw(requestParameters: InterpretStudyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SleepStudyOrderOut>> {
        if (requestParameters['studyId'] == null) {
            throw new runtime.RequiredError(
                'studyId',
                'Required parameter "studyId" was null or undefined when calling interpretStudy().'
            );
        }

        if (requestParameters['interpretStudyIn'] == null) {
            throw new runtime.RequiredError(
                'interpretStudyIn',
                'Required parameter "interpretStudyIn" was null or undefined when calling interpretStudy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/studies/{study_id}/interpret`.replace(`{${"study_id"}}`, encodeURIComponent(String(requestParameters['studyId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: InterpretStudyInToJSON(requestParameters['interpretStudyIn']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SleepStudyOrderOutFromJSON(jsonValue));
    }

    /**
     * Complete a physician interpretation of a study and update event data if modified.
     * Interpret Study
     */
    async interpretStudy(requestParameters: InterpretStudyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SleepStudyOrderOut> {
        const response = await this.interpretStudyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Log In
     */
    async logInRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SessionKeyOut>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/log-in`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SessionKeyOutFromJSON(jsonValue));
    }

    /**
     * Log In
     */
    async logIn(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SessionKeyOut> {
        const response = await this.logInRaw(initOverrides);
        return await response.value();
    }

    /**
     * Reset Password
     */
    async resetUserPasswordRaw(requestParameters: ResetUserPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserOut>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling resetUserPassword().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/reset-password/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserOutFromJSON(jsonValue));
    }

    /**
     * Reset Password
     */
    async resetUserPassword(requestParameters: ResetUserPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserOut> {
        const response = await this.resetUserPasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Restore Device
     */
    async restoreDeviceRaw(requestParameters: RestoreDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeviceOut>> {
        if (requestParameters['serialNumber'] == null) {
            throw new runtime.RequiredError(
                'serialNumber',
                'Required parameter "serialNumber" was null or undefined when calling restoreDevice().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/devices/restore/{serial_number}`.replace(`{${"serial_number"}}`, encodeURIComponent(String(requestParameters['serialNumber']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceOutFromJSON(jsonValue));
    }

    /**
     * Restore Device
     */
    async restoreDevice(requestParameters: RestoreDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeviceOut> {
        const response = await this.restoreDeviceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This is idempotent to match the DELETE operation. If the organization is not deleted, return 200. `get_object_or_404` is not used because it uses `model.objects` and thus will return 404 if the organization is deleted and should be restored.
     * Restore Organization
     */
    async restoreOrganizationRaw(requestParameters: RestoreOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationOut>> {
        if (requestParameters['organizationId'] == null) {
            throw new runtime.RequiredError(
                'organizationId',
                'Required parameter "organizationId" was null or undefined when calling restoreOrganization().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/organizations/restore/{organization_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters['organizationId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationOutFromJSON(jsonValue));
    }

    /**
     * This is idempotent to match the DELETE operation. If the organization is not deleted, return 200. `get_object_or_404` is not used because it uses `model.objects` and thus will return 404 if the organization is deleted and should be restored.
     * Restore Organization
     */
    async restoreOrganization(requestParameters: RestoreOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationOut> {
        const response = await this.restoreOrganizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This is idempotent to match the DELETE operation. If the user is not deleted, return 200. `get_object_or_404` is not used because it uses `model.objects` and thus will return 404 if the user is deleted and should be restored.
     * Restore Patient
     */
    async restorePatientRaw(requestParameters: RestorePatientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientOut>> {
        if (requestParameters['patientId'] == null) {
            throw new runtime.RequiredError(
                'patientId',
                'Required parameter "patientId" was null or undefined when calling restorePatient().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/patients/restore/{patient_id}`.replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters['patientId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientOutFromJSON(jsonValue));
    }

    /**
     * This is idempotent to match the DELETE operation. If the user is not deleted, return 200. `get_object_or_404` is not used because it uses `model.objects` and thus will return 404 if the user is deleted and should be restored.
     * Restore Patient
     */
    async restorePatient(requestParameters: RestorePatientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientOut> {
        const response = await this.restorePatientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Restore Study Order
     */
    async restoreStudyOrderRaw(requestParameters: RestoreStudyOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SleepStudyOrderOut>> {
        if (requestParameters['studyOrderId'] == null) {
            throw new runtime.RequiredError(
                'studyOrderId',
                'Required parameter "studyOrderId" was null or undefined when calling restoreStudyOrder().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/study-orders/restore/{study_order_id}`.replace(`{${"study_order_id"}}`, encodeURIComponent(String(requestParameters['studyOrderId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SleepStudyOrderOutFromJSON(jsonValue));
    }

    /**
     * Restore Study Order
     */
    async restoreStudyOrder(requestParameters: RestoreStudyOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SleepStudyOrderOut> {
        const response = await this.restoreStudyOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This is idempotent to match the DELETE operation. If the user is not deleted, return 200. `get_object_or_404` is not used because it uses `model.objects` and thus will return 404 if the user is deleted and should be restored.
     * Restore User
     */
    async restoreUserRaw(requestParameters: RestoreUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserOut>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling restoreUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/restore/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserOutFromJSON(jsonValue));
    }

    /**
     * This is idempotent to match the DELETE operation. If the user is not deleted, return 200. `get_object_or_404` is not used because it uses `model.objects` and thus will return 404 if the user is deleted and should be restored.
     * Restore User
     */
    async restoreUser(requestParameters: RestoreUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserOut> {
        const response = await this.restoreUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Device
     */
    async updateDeviceRaw(requestParameters: UpdateDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeviceOut>> {
        if (requestParameters['serialNumber'] == null) {
            throw new runtime.RequiredError(
                'serialNumber',
                'Required parameter "serialNumber" was null or undefined when calling updateDevice().'
            );
        }

        if (requestParameters['deviceUpdate'] == null) {
            throw new runtime.RequiredError(
                'deviceUpdate',
                'Required parameter "deviceUpdate" was null or undefined when calling updateDevice().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/devices/{serial_number}`.replace(`{${"serial_number"}}`, encodeURIComponent(String(requestParameters['serialNumber']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: DeviceUpdateToJSON(requestParameters['deviceUpdate']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceOutFromJSON(jsonValue));
    }

    /**
     * Update Device
     */
    async updateDevice(requestParameters: UpdateDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeviceOut> {
        const response = await this.updateDeviceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Interpretation Template
     */
    async updateInterpretationTemplateRaw(requestParameters: UpdateInterpretationTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InterpretationTemplateOut>> {
        if (requestParameters['templateId'] == null) {
            throw new runtime.RequiredError(
                'templateId',
                'Required parameter "templateId" was null or undefined when calling updateInterpretationTemplate().'
            );
        }

        if (requestParameters['interpretationTemplateUpdate'] == null) {
            throw new runtime.RequiredError(
                'interpretationTemplateUpdate',
                'Required parameter "interpretationTemplateUpdate" was null or undefined when calling updateInterpretationTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/interpretation-templates/{template_id}`.replace(`{${"template_id"}}`, encodeURIComponent(String(requestParameters['templateId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: InterpretationTemplateUpdateToJSON(requestParameters['interpretationTemplateUpdate']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InterpretationTemplateOutFromJSON(jsonValue));
    }

    /**
     * Update Interpretation Template
     */
    async updateInterpretationTemplate(requestParameters: UpdateInterpretationTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InterpretationTemplateOut> {
        const response = await this.updateInterpretationTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Organization
     */
    async updateOrganizationRaw(requestParameters: UpdateOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationOut>> {
        if (requestParameters['organizationId'] == null) {
            throw new runtime.RequiredError(
                'organizationId',
                'Required parameter "organizationId" was null or undefined when calling updateOrganization().'
            );
        }

        if (requestParameters['organizationUpdate'] == null) {
            throw new runtime.RequiredError(
                'organizationUpdate',
                'Required parameter "organizationUpdate" was null or undefined when calling updateOrganization().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/organizations/{organization_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters['organizationId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: OrganizationUpdateToJSON(requestParameters['organizationUpdate']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationOutFromJSON(jsonValue));
    }

    /**
     * Update Organization
     */
    async updateOrganization(requestParameters: UpdateOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationOut> {
        const response = await this.updateOrganizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Toggle Organization Location Enabled
     */
    async updateOrganizationLocationRaw(requestParameters: UpdateOrganizationLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationLocationOut>> {
        if (requestParameters['locationId'] == null) {
            throw new runtime.RequiredError(
                'locationId',
                'Required parameter "locationId" was null or undefined when calling updateOrganizationLocation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/organization-locations/{location_id}/`.replace(`{${"location_id"}}`, encodeURIComponent(String(requestParameters['locationId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationLocationOutFromJSON(jsonValue));
    }

    /**
     * Toggle Organization Location Enabled
     */
    async updateOrganizationLocation(requestParameters: UpdateOrganizationLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationLocationOut> {
        const response = await this.updateOrganizationLocationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Patient
     */
    async updatePatientRaw(requestParameters: UpdatePatientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientOut>> {
        if (requestParameters['patientId'] == null) {
            throw new runtime.RequiredError(
                'patientId',
                'Required parameter "patientId" was null or undefined when calling updatePatient().'
            );
        }

        if (requestParameters['patientUpdate'] == null) {
            throw new runtime.RequiredError(
                'patientUpdate',
                'Required parameter "patientUpdate" was null or undefined when calling updatePatient().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/patients/{patient_id}`.replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters['patientId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatientUpdateToJSON(requestParameters['patientUpdate']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientOutFromJSON(jsonValue));
    }

    /**
     * Update Patient
     */
    async updatePatient(requestParameters: UpdatePatientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientOut> {
        const response = await this.updatePatientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint that allows the user to update specific pieces of a SleepStudy
     * Update Study
     */
    async updateStudyRaw(requestParameters: UpdateStudyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SleepStudyOut>> {
        if (requestParameters['studyId'] == null) {
            throw new runtime.RequiredError(
                'studyId',
                'Required parameter "studyId" was null or undefined when calling updateStudy().'
            );
        }

        if (requestParameters['sleepStudyIn'] == null) {
            throw new runtime.RequiredError(
                'sleepStudyIn',
                'Required parameter "sleepStudyIn" was null or undefined when calling updateStudy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/studies/{study_id}`.replace(`{${"study_id"}}`, encodeURIComponent(String(requestParameters['studyId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: SleepStudyInToJSON(requestParameters['sleepStudyIn']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SleepStudyOutFromJSON(jsonValue));
    }

    /**
     * Endpoint that allows the user to update specific pieces of a SleepStudy
     * Update Study
     */
    async updateStudy(requestParameters: UpdateStudyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SleepStudyOut> {
        const response = await this.updateStudyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Study Order
     */
    async updateStudyOrderRaw(requestParameters: UpdateStudyOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SleepStudyOrderOut>> {
        if (requestParameters['studyOrderId'] == null) {
            throw new runtime.RequiredError(
                'studyOrderId',
                'Required parameter "studyOrderId" was null or undefined when calling updateStudyOrder().'
            );
        }

        if (requestParameters['sleepStudyOrderUpdate'] == null) {
            throw new runtime.RequiredError(
                'sleepStudyOrderUpdate',
                'Required parameter "sleepStudyOrderUpdate" was null or undefined when calling updateStudyOrder().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/study-orders/{study_order_id}`.replace(`{${"study_order_id"}}`, encodeURIComponent(String(requestParameters['studyOrderId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: SleepStudyOrderUpdateToJSON(requestParameters['sleepStudyOrderUpdate']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SleepStudyOrderOutFromJSON(jsonValue));
    }

    /**
     * Update Study Order
     */
    async updateStudyOrder(requestParameters: UpdateStudyOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SleepStudyOrderOut> {
        const response = await this.updateStudyOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update User
     */
    async updateUserRaw(requestParameters: UpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserOut>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling updateUser().'
            );
        }

        if (requestParameters['userUpdate'] == null) {
            throw new runtime.RequiredError(
                'userUpdate',
                'Required parameter "userUpdate" was null or undefined when calling updateUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserUpdateToJSON(requestParameters['userUpdate']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserOutFromJSON(jsonValue));
    }

    /**
     * Update User
     */
    async updateUser(requestParameters: UpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserOut> {
        const response = await this.updateUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload Signature Image
     */
    async uploadSignatureRaw(requestParameters: UploadSignatureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignatureUploadOut>> {
        if (requestParameters['physicianId'] == null) {
            throw new runtime.RequiredError(
                'physicianId',
                'Required parameter "physicianId" was null or undefined when calling uploadSignature().'
            );
        }

        if (requestParameters['file'] == null) {
            throw new runtime.RequiredError(
                'file',
                'Required parameter "file" was null or undefined when calling uploadSignature().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['file'] != null) {
            formParams.append('file', requestParameters['file'] as any);
        }

        const response = await this.request({
            path: `/api/signatures/{physician_id}`.replace(`{${"physician_id"}}`, encodeURIComponent(String(requestParameters['physicianId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignatureUploadOutFromJSON(jsonValue));
    }

    /**
     * Upload Signature Image
     */
    async uploadSignature(requestParameters: UploadSignatureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignatureUploadOut> {
        const response = await this.uploadSignatureRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generate Study Upload Presigned Url
     */
    async uploadStudyRaw(requestParameters: UploadStudyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RequestUploadOut>> {
        if (requestParameters['studyOrderId'] == null) {
            throw new runtime.RequiredError(
                'studyOrderId',
                'Required parameter "studyOrderId" was null or undefined when calling uploadStudy().'
            );
        }

        if (requestParameters['requestUploadIn'] == null) {
            throw new runtime.RequiredError(
                'requestUploadIn',
                'Required parameter "requestUploadIn" was null or undefined when calling uploadStudy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/upload-study/{study_order_id}`.replace(`{${"study_order_id"}}`, encodeURIComponent(String(requestParameters['studyOrderId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestUploadInToJSON(requestParameters['requestUploadIn']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestUploadOutFromJSON(jsonValue));
    }

    /**
     * Generate Study Upload Presigned Url
     */
    async uploadStudy(requestParameters: UploadStudyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RequestUploadOut> {
        const response = await this.uploadStudyRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetAllPatientsStudyStatusesEnum = {
    Od: 'OD',
    Ip: 'IP',
    Au: 'AU',
    Up: 'UP',
    Pg: 'PG',
    Cv: 'CV',
    Cc: 'CC',
    Gc: 'GC',
    Fa: 'FA',
    Pc: 'PC',
    Rr: 'RR',
    Ri: 'RI',
    Ce: 'CE',
    Ab: 'AB',
    Rj: 'RJ'
} as const;
export type GetAllPatientsStudyStatusesEnum = typeof GetAllPatientsStudyStatusesEnum[keyof typeof GetAllPatientsStudyStatusesEnum];
/**
 * @export
 */
export const GetAllStudyOrdersStudyStatusesEnum = {
    Od: 'OD',
    Ip: 'IP',
    Au: 'AU',
    Up: 'UP',
    Pg: 'PG',
    Cv: 'CV',
    Cc: 'CC',
    Gc: 'GC',
    Fa: 'FA',
    Pc: 'PC',
    Rr: 'RR',
    Ri: 'RI',
    Ce: 'CE',
    Ab: 'AB',
    Rj: 'RJ'
} as const;
export type GetAllStudyOrdersStudyStatusesEnum = typeof GetAllStudyOrdersStudyStatusesEnum[keyof typeof GetAllStudyOrdersStudyStatusesEnum];
