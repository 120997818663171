import { SyntheticEvent, useState } from "react"
import { Badge, IconButton } from "@mui/material"
import Box from "@mui/material/Box"
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen"
import Slide from "@mui/material/Slide"
import { useRecoilValue } from "recoil"
import { Div } from "@huxley-medical/react-components/elements"
import { Button } from "@huxley-medical/react-components/components"
import EventRow from "./EventRow"
import { useParams } from "react-router-dom"
import { plotSidebarTimeSort } from "../../../state/study.state"
import { StyledTab, StyledTabs } from "../../../styled/Tab"
import { EventTable, StyledEventRow, SummaryTable } from "../../../styled/Table"
import { DatasetTabFooter } from "../../../styled/Box"
import { StyledDatasetSidebar } from "../../../styled/Div"

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <Div
      sx={{ height: "calc(100% - 49px)", position: "relative" }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Div sx={{ height: "100%" }}>{children}</Div>}
    </Div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

function ScoringInfoTabs({
  eventsLabel = "Day 7",
  closeComponent,
}: {
  eventsLabel?: string
  closeComponent: React.ReactNode
}) {
  const [currentTab, setCurrentTab] = useState(0)

  const { studyID } = useParams()

  const events = useRecoilValue(
    plotSidebarTimeSort({ studyID: studyID as string })
  )

  const handleTabChange = (_: SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue)
  }

  const handleGenerateReport = () => {
    console.log("Generate Report")
  }

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Box
        sx={{ borderBottom: 1, borderColor: "divider", position: "relative" }}
      >
        <StyledTabs
          value={currentTab}
          onChange={handleTabChange}
          aria-label="dataset info"
        >
          <StyledTab label="Events" {...a11yProps(0)} />
          <StyledTab
            label={
              <Badge color="primary" variant="dot" invisible={false}>
                Summary
              </Badge>
            }
            {...a11yProps(1)}
          />
        </StyledTabs>
        {closeComponent}
      </Box>
      <TabPanel value={currentTab} index={0}>
        <Box
          sx={{
            position: "relative",
            display: "flex",
            padding: "15px",
            borderBottom: "1px solid #E9EDF3",
          }}
        >
          <Box
            sx={{
              whiteSpace: "nowrap",
              display: "flex",
              alignItems: "center",
              flex: "2",
              fontWeight: 600,
              fontSize: "14px",
            }}
          >
            {eventsLabel}
          </Box>
          {/*<EventFilter />*/}
        </Box>
        <Div
          sx={{
            height: "calc(100% - 143px)",
            overflowY: "auto",
          }}
        >
          <EventTable>
            <tbody>
              {events.length > 0 &&
                events.map((eventID) => {
                  return <EventRow key={eventID} eventID={eventID} />
                })}
              {events.length === 0 && (
                <StyledEventRow
                  sx={{
                    "& td": {
                      padding: "16px",
                      textAlign: "center",
                      fontSize: "14px",
                    },
                  }}
                >
                  <td colSpan={3}>No Events</td>
                </StyledEventRow>
              )}
            </tbody>
          </EventTable>
        </Div>
        <DatasetTabFooter>
          <Button onClick={handleGenerateReport}>Generate Report</Button>
        </DatasetTabFooter>
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <Div sx={{ padding: "15px" }}>
          <SummaryTable>
            <tbody>
              <tr>
                <td>AHI:</td>
                <td>16.8</td>
              </tr>
              <tr>
                <td>RDI:</td>
                <td>19.5</td>
              </tr>
              <tr>
                <td>Total Sleep Time:</td>
                <td>7 hrs, 05 mins</td>
              </tr>
              <tr>
                <td>Lowest SpO2:</td>
                <td>74%</td>
              </tr>
            </tbody>
          </SummaryTable>
        </Div>
      </TabPanel>
    </Box>
  )
}

const ScoringSidebar = () => {
  const [isExpanded, setIsExpanded] = useState(false)

  return isExpanded ? (
    <Slide direction="left" in={isExpanded}>
      <StyledDatasetSidebar className="dataset-sidebar">
        <ScoringInfoTabs
          closeComponent={
            <IconButton
              onClick={() => {
                setIsExpanded(!isExpanded)
              }}
              sx={{ position: "absolute", right: "10px", top: "4px" }}
              aria-label="close"
            >
              <CloseFullscreenIcon />
            </IconButton>
          }
        />
      </StyledDatasetSidebar>
    </Slide>
  ) : (
    <>
      {/*<StyledMinimizedDatasetSidebar className="minimized-dataset-sidebar">
        <Zoom in={!isExpanded}>
          <Div
            sx={{
              height: "171px",
              backgroundColor: "#FFF",
              borderBottom: "2px solid #EFEEF4",
            }}
          >
            <StyledExpandSidebarButtonSection
              className="expand-sidebar-btn"
              onClick={() => {
                setIsExpanded(true)
              }}
            >
              <IconButton aria-label="expand" size="small">
                <ExpandIcon />
              </IconButton>
              <StyledEventsExpandButton>Events</StyledEventsExpandButton>
            </StyledExpandSidebarButtonSection>
          </Div>
        </Zoom>
            </StyledMinimizedDatasetSidebar>*/}
    </>
  )
}

export default ScoringSidebar
