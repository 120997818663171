import { RefObject, useCallback } from "react"
import html2canvas from "html2canvas"
import { jsPDF } from "jspdf"
import { renderToString } from "react-dom/server"
import PDFReport from "../components/pdfreport/PdfReport"
import {
  useRecoilBridgeAcrossReactRoots_UNSTABLE,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil"
import {
  fullNightInterval,
  intervalTimeEventTypeAtom,
  studyPlotOrder,
} from "../state/study.state"
import { isGeneratingPDFReport } from "../state/pdfReport.state"
import { eventIDs } from "../state/event.state"
import { usePortalApi } from "../connections"
import { EventPlot } from "../types/event.type"

const usePrintPdf = (chartRef: RefObject<HTMLDivElement>) => {
  const api = usePortalApi()
  const [selectedTimeInterval, setSelectedTimeInterval] = useRecoilState(
    intervalTimeEventTypeAtom
  )
  const [plotOrder, setPlotOrder] = useRecoilState(studyPlotOrder)
  const newPlotOrder = [...plotOrder]
  const RecoilBridge = useRecoilBridgeAcrossReactRoots_UNSTABLE()
  const fullNightIntervalValue = useRecoilValue(fullNightInterval)
  const setIsGeneratingPDF = useSetRecoilState(isGeneratingPDFReport)
  const htmlStringToPdf = async (htmlString: string) => {
    const currentInterval = selectedTimeInterval
    const ecgIndex = newPlotOrder.findIndex(
      (plots: { plotType: EventPlot; enabled: boolean }) =>
        plots.plotType === "ECG"
    )
    const ecgStatus = newPlotOrder[ecgIndex]
    if (ecgIndex && newPlotOrder[ecgIndex].enabled) {
      newPlotOrder[ecgIndex] = {
        plotType: "ECG" as EventPlot,
        enabled: false,
      }
      setPlotOrder(newPlotOrder)
    }
    setSelectedTimeInterval(fullNightIntervalValue)
    setIsGeneratingPDF(true)
    const iframe = document.createElement("iframe")
    const chartSvg = chartRef.current
    if (chartSvg) {
      const graphheightmm = 150
      const graphwidthmm = 210
      const pxToMm = 0.264583
      const graphheightpx = (graphheightmm / pxToMm) * 1.5
      const graphwidthpx = (graphwidthmm / pxToMm) * 1.755
      chartRef.current.style.width = `${graphwidthpx}px`
      chartRef.current.style.height = `${graphheightpx}px`
      iframe.style.visibility = "hidden"
      document.body.appendChild(iframe)
      const cssLink = document.createElement("link")
      cssLink.href = "../pdfStyles.css"
      cssLink.rel = "stylesheet"
      cssLink.type = "text/css"
      const iframedoc = iframe.contentDocument || iframe.contentWindow?.document

      if (iframedoc) {
        iframedoc.body.innerHTML = htmlString
        iframedoc.head.appendChild(cssLink)
        const canvas = await html2canvas(iframedoc.body, { scale: 3.0 })
        const chartCanvas = await html2canvas(chartSvg as HTMLElement, {
          scale: 4.0,
        })
        // Convert the iframe into a PNG image using canvas.
        const imgData = canvas.toDataURL("image/png")
        const chartImgData = chartCanvas.toDataURL("image/png")
        chartRef.current.style.width = "100%"
        chartRef.current.style.height = "100%"
        // Create a PDF document and add the image as a page.
        const doc = new jsPDF({
          format: "letter",
          unit: "mm",
        })

        const imgWidth = 205
        const imgHeight = (imgWidth / canvas.width) * canvas.height

        doc.addImage(
          imgData,
          "PNG",
          0,
          0,
          imgWidth,
          imgHeight,
          undefined,
          "FAST"
        )
        doc.addImage(
          chartImgData,
          "PNG",
          7,
          100, //imgHeight + 50,
          graphwidthmm,
          graphheightmm,
          undefined,
          "FAST"
        )
        // Get the file as blob output.
        doc.save("report.pdf")
      }
      // Remove the iframe from the document when the file is generated.
      document.body.removeChild(iframe)
      setSelectedTimeInterval(currentInterval)
      if (ecgStatus.enabled && newPlotOrder[ecgIndex]) {
        const newOrder = [...plotOrder]
        newOrder[ecgIndex] = {
          plotType: "ECG" as EventPlot,
          enabled: true,
        }
        setPlotOrder(newOrder)
      }
      setTimeout(() => {
        setIsGeneratingPDF(false)
      }, 3000)
    }
  }

  const events = useRecoilValue(eventIDs)

  const printPDF = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (api === undefined) return
      const stringRepresentation = renderToString(
        <RecoilBridge>
          <PDFReport />
        </RecoilBridge>
      )
      htmlStringToPdf(stringRepresentation)
    },
    [
      setSelectedTimeInterval,
      fullNightIntervalValue,
      selectedTimeInterval,
      events,
      api,
    ]
  )
  return printPDF
}

export default usePrintPdf
