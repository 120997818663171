import * as React from "react"
import { useState } from "react"

import {
  Button,
  DialogTitle,
  Divider,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
} from "@mui/joy"
import AddIcon from "@mui/icons-material/Add"
import NewPatientForm from "./NewPatientForm"

function NewPatientModal() {
  const [open, setOpen] = useState(false)

  return (
    <React.Fragment>
      <Button
        size="sm"
        startDecorator={<AddIcon />}
        onClick={() => setOpen(true)}
      >
        New Patient
      </Button>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ModalDialog sx={{ width: 880, overflowY: "auto", marginTop: "40px" }}>
          <ModalClose />
          <Stack spacing={2}>
            <DialogTitle>Create New Patient:</DialogTitle>
            <Divider />
            <NewPatientForm setOpen={setOpen} />
          </Stack>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  )
}

export default NewPatientModal
