import { Grid, Input, Textarea } from "@mui/joy"
import GridFormInput from "../grid/GridFormInput"
import Select from "@mui/joy/Select"
import Option from "@mui/joy/Option"
import { OrganizationTableRow } from "../../../types/table.type"
import GridDivider from "../../griddivider/GridDivider"
import Address from "../address/Address"
import {
  OrganizationIn,
  OrganizationLocationIn,
} from "../../../generated/fetchclient"
import PhoneInput, { Value } from "react-phone-number-input/input"
import { inputOrganizationErrors } from "../../../interfaces/organization.interface"

type OrganizationFormProps = {
  individualOrganization: OrganizationTableRow
  handleLocationChange: (
    index: number,
    property: keyof OrganizationLocationIn,
    value: string | boolean | Value
  ) => void
  handlePropChange: (
    property: keyof OrganizationIn,
    value: Date | string | boolean | Value
  ) => void
  inputOrganizationErrors: inputOrganizationErrors
  removeLocation: (index: number) => void
  addLocation: () => void
}

const OrganizationForm = ({
  individualOrganization,
  addLocation,
  removeLocation,
  handlePropChange,
  handleLocationChange,
  inputOrganizationErrors,
}: OrganizationFormProps) => {
  const statusList = [
    {
      name: "Active",
      value: true,
    },
    {
      name: "In-Active",
      value: false,
    },
  ]

  return (
    <>
      <Grid container spacing={2} md={12} xs={12}>
        <GridDivider
          xs={12}
          sx={{ "--Divider-childPosition": "0%" }}
          text={"Organization Information"}
        />
        <GridFormInput
          error={inputOrganizationErrors.name}
          width={4}
          label="Organization Name"
        >
          <Input
            value={individualOrganization.name}
            onChange={(e) => handlePropChange("name", e.target.value)}
            required
          />
        </GridFormInput>
        <GridFormInput
          error={inputOrganizationErrors.contactName}
          width={4}
          label="Admin Name"
        >
          <Input
            value={individualOrganization.contactName}
            onChange={(e) => handlePropChange("contactName", e.target.value)}
            required
          />
        </GridFormInput>
        <GridFormInput
          error={inputOrganizationErrors.contactEmail}
          width={4}
          label="Admin Email"
        >
          <Input
            value={individualOrganization.contactEmail}
            onChange={(e) => handlePropChange("contactEmail", e.target.value)}
            required
          />
        </GridFormInput>
        <GridFormInput width={6} label="Active">
          <Select
            onChange={(_, value) => {
              handlePropChange("enabled", value as boolean)
            }}
            defaultValue={individualOrganization.enabled}
            required
          >
            {statusList.map((statusData, index) => (
              <Option key={index} value={statusData.value}>
                {statusData.name}
              </Option>
            ))}
          </Select>
        </GridFormInput>
        <GridFormInput
          error={inputOrganizationErrors.contactPhone}
          width={6}
          label="Primary Contact"
        >
          <PhoneInput
            className={"phone-input "}
            country="US"
            value={individualOrganization.contactPhone as Value}
            onChange={(value: Value) =>
              handlePropChange("contactPhone", value || "")
            }
            required
          />
        </GridFormInput>
        <GridFormInput width={12} label="Description">
          <Textarea
            value={individualOrganization.description}
            onChange={(e) => handlePropChange("description", e.target.value)}
            minRows={3}
            sx={{ mb: 1 }}
          />
        </GridFormInput>
      </Grid>
      <Grid container spacing={2} md={12} xs={12}>
        <GridDivider
          xs={12}
          sx={{ "--Divider-childPosition": "0%" }}
          text={"Organization Locations"}
        />
        {individualOrganization.locations.map((locationData, index) => (
          <Address
            handleLocationChange={handleLocationChange}
            inputOrganizationErrors={inputOrganizationErrors}
            key={index}
            addressData={locationData}
            index={index}
            removeLocation={removeLocation}
            addLocation={addLocation}
          />
        ))}
      </Grid>
    </>
  )
}

export default OrganizationForm
