import { Box } from "@mui/joy"

interface PreSignedUrlSignaturProps {
  signatureUrl: string
}

const PreSignedUrlSignature = ({ signatureUrl }: PreSignedUrlSignaturProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "auto",
        height: "300px",
        overflow: "hidden",
      }}
    >
      <img
        alt="signature"
        src={signatureUrl}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
        }}
      />
    </Box>
  )
}

export default PreSignedUrlSignature
