import { atom } from "recoil"

export const isGeneratingPDFReport = atom<boolean>({
  key: "isGeneratingPDFReport",
  default: false,
})

export const reportStatus = atom<boolean>({
  key: "reportStatus",
  default: false,
})
