import { Box, Link } from "@mui/joy"
import HomeRoundedIcon from "@mui/icons-material/HomeRounded"
import { usePortalApi } from "../../connections"
import UserTable from "../../components/user/UserTable"
import { useEffect, useState } from "react"
import InternalHeader from "../../components/internalheader/InternalHeader"
import { HeaderList } from "../../interfaces/header.inteface"
import LoadingIndicator from "../../components/loading/LoadingIndicator"
import useUsers from "../../hooks/useUsers"

const UsersContent = () => {
  const api = usePortalApi()
  const usersApi = useUsers()
  const [isLoading, setIsLoading] = useState(false)
  const [headerList, setHeaderList] = useState<HeaderList[]>([])

  useEffect(() => {
    const fetchData = async () => {
      await usersApi.listUsers()
      setIsLoading(false)
    }

    setIsLoading(true)
    setHeaderList([
      {
        fontWeight: 500,
        fontSize: 12,
        text: "Users",
      },
    ])
    fetchData()
  }, [api])

  useEffect(() => {
    return () => {
      usersApi.resetUserData()
    }
  }, [])

  return (
    <>
      <Box
        sx={{
          px: { xs: 0, sm: 2 },
          padding: 0,
          border: 0,
          height: "100%",
          width: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <InternalHeader breadCrumbList={headerList}>
            <Link
              underline="none"
              color="neutral"
              href="#some-link"
              aria-label="Home"
            >
              <HomeRoundedIcon />
            </Link>
          </InternalHeader>
        </Box>
        <UserTable></UserTable>
      </Box>
      {isLoading && (
        <LoadingIndicator
          header="Loading Users"
          content="This may take some time"
        />
      )}
    </>
  )
}

export default UsersContent
