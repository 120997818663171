import { styled } from "@mui/joy/styles"

export const StyledEventRow = styled("tr")({
  cursor: "pointer",
  borderBottom: "1px solid #E9EDF3",
  padding: "10px",
  fontSize: "13px",
})

export const EventTable = styled("table")({
  width: "100%",
  borderCollapse: "collapse",
})

export const SummaryTable = styled("table")({
  width: "100%",
  fontSize: "14px",
  "& td:first-of-type": {
    color: "#7D7D7D",
  },
})
