import { Box, Button, Divider, Radio, RadioGroup, Typography } from "@mui/joy"
import ModalBox from "../../../components/modal/ModalBox"
import FileDropZone from "../../../components/filedropzone/FileDropZone"
import { RefObject, useEffect } from "react"
import SignaturePad from "../../../components/signaturepad/SignaturePad"
import LoadingIndicator from "../../../components/loading/LoadingIndicator"
import { useRecoilValue } from "recoil"
import { isSignatureLoading } from "../../../state/signature.state"
import EditSignatureButton from "./EditSignatureButton"
import PreSignedUrlSignature from "./SignatureUrlWindow"

interface SignatureModalProps {
  droppedFile: File | null
  setDroppedFile: React.Dispatch<React.SetStateAction<File | null>>
  signatureModalStatus: boolean
  setSignatureModalStatus: React.Dispatch<React.SetStateAction<boolean>>
  setSignatureRadioValue: React.Dispatch<React.SetStateAction<string>>
  signatureRadioValue: string
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  saveSignature: () => void
  signatureUrl: string | undefined
  editSignature: boolean
  setEditSignature: React.Dispatch<React.SetStateAction<boolean>>
  signatureRef: RefObject<HTMLCanvasElement | null>
}

const SignatureModal = ({
  signatureUrl,
  droppedFile,
  setDroppedFile,
  signatureModalStatus,
  setSignatureModalStatus,
  signatureRadioValue,
  setSignatureRadioValue,
  handleChange,
  saveSignature,
  editSignature,
  setEditSignature,
  signatureRef,
}: SignatureModalProps) => {
  const isSignatureLoadingStatus = useRecoilValue(isSignatureLoading)
  const clearSignature = () => {
    setDroppedFile(null)
  }

  const resetData = () => {
    setSignatureRadioValue("upload")
    setEditSignature(false)
    setDroppedFile(null)
  }

  useEffect(() => {
    setSignatureRadioValue("upload")
    setDroppedFile(null)
  }, [editSignature])

  useEffect(() => {
    return () => {
      resetData()
    }
  }, [signatureModalStatus])

  return (
    <ModalBox
      open={signatureModalStatus}
      setOpen={setSignatureModalStatus}
      title="Upload Signature"
      width={
        signatureUrl !== undefined && !editSignature ? "auto" : Number(800)
      }
    >
      <>
        {signatureUrl !== undefined && (!editSignature || editSignature) && (
          <>
            <EditSignatureButton
              editSignature={editSignature}
              setEditSignature={setEditSignature}
            />
            {signatureUrl !== undefined && !editSignature && (
              <PreSignedUrlSignature signatureUrl={signatureUrl} />
            )}
          </>
        )}
        {signatureUrl === undefined ||
          (signatureUrl !== undefined && editSignature && (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "20px",
                  padding: "10px",
                  mb: 1,
                }}
              >
                <Typography sx={{ textAlign: "center" }} level="title-md">
                  {editSignature
                    ? "Please update your signature"
                    : "You dont have a signature on record. You must create one before performing a signature"}
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    flexDirection: "column",
                    display: "flex",
                    gap: "30px",
                  }}
                >
                  <RadioGroup
                    sx={{
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "30px",
                    }}
                    defaultValue="Upload"
                    name="radio-buttons-group"
                    value={signatureRadioValue}
                    onChange={handleChange}
                  >
                    <Radio value="upload" label="Upload" variant="outlined" />
                    <span>Or</span>
                    <Radio
                      value="signature"
                      label="Draw Signature"
                      variant="outlined"
                      sx={{
                        marginTop: "0px",
                      }}
                    />
                  </RadioGroup>
                  {signatureRadioValue === "upload" ? (
                    <FileDropZone
                      droppedFile={droppedFile}
                      setDroppedFile={setDroppedFile}
                      clearSignature={clearSignature}
                    />
                  ) : (
                    <SignaturePad signatureRef={signatureRef} />
                  )}
                </Box>
              </Box>
            </>
          ))}
        <Divider />
        <Box
          sx={{
            display: "flex",
            gap: 1,
            flexDirection: { xs: "column", sm: "row-reverse" },
          }}
        >
          <Button
            variant="solid"
            color="primary"
            onClick={() => saveSignature()}
          >
            {signatureUrl !== undefined && !editSignature
              ? "Submit Interpretation"
              : "Save Signature and Submit Interpretation"}
          </Button>
          <Button
            variant="outlined"
            color="neutral"
            onClick={() => setSignatureModalStatus(false)}
          >
            Close
          </Button>
        </Box>
      </>
      {isSignatureLoadingStatus && (
        <LoadingIndicator header="" content="This may take some time" />
      )}
    </ModalBox>
  )
}

export default SignatureModal
