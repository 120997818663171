import React, { useMemo } from "react"
import { Group } from "@visx/group"
import { CallbackInterface, useRecoilValue } from "recoil"
import {
  ppgExclusions,
  ecgExclusions,
  ecgBadExclusions,
} from "../../../state/exclusions.state"
import { EventPlot } from "../../../types/event.type"
import { ExclusionAnnotation } from "../../../types/exclusion.type"
import Exclusion from "./Exclusion"
import { ScaleLinear } from "d3-scale"

export const exclusionRemove =
  (callback: CallbackInterface) =>
  (
    exclusionID: number,
    plot: EventPlot,
    exclusionPlottingData: ExclusionAnnotation[],
    userId: string | undefined
  ) =>
  () => {
    const exclusionData: ExclusionAnnotation[] = [...exclusionPlottingData]
    exclusionData[exclusionID] = {
      ...exclusionData[exclusionID],
      removed: true,
      removedOn: new Date().getTime() / 1000,
      removedBy: userId,
    }
    callback.set(plot === "HR" ? ecgExclusions : ppgExclusions, exclusionData)
  }

type PlotExclusionParams = {
  height: number
  width: number
  children?: React.ReactNode
  timeScale: ScaleLinear<number, number, never>
  plot: EventPlot
}

const PlotExclusions = ({
  height,
  children,
  timeScale,
  plot,
  width,
}: PlotExclusionParams): JSX.Element => {
  const ecgExclusionsData = useRecoilValue(ecgExclusions)
  const ppgExclusionsData = useRecoilValue(ppgExclusions)
  const ecgBadExclusionsData = useRecoilValue(ecgBadExclusions)
  const exclusionForPlotting = useMemo(() => {
    if (plot === "HR") {
      return ecgExclusionsData
    }
    if (plot === "SpO2") {
      return ppgExclusionsData
    }
    if (plot === "ECG") {
      return ecgBadExclusionsData
    }
    return []
  }, [plot, ecgExclusionsData, ppgExclusionsData, ecgBadExclusionsData])
  return (
    <Group className="plot-events">
      {children /* grid rows, cols, and line path */}
      {["SpO2", "HR", "ECG"].includes(plot) &&
        exclusionForPlotting.length > 0 &&
        exclusionForPlotting.map((exclusion, index) =>
          !exclusion.removed ? (
            <Exclusion
              key={index}
              exclusionIndex={index}
              height={height}
              plot={plot}
              timeScale={timeScale}
              startTs={exclusion.startTS}
              endTs={exclusion.endTS}
              exclusionData={exclusion}
              exclusionForPlotting={exclusionForPlotting}
            />
          ) : (
            <React.Fragment key={index} />
          )
        )}
    </Group>
  )
}

export default PlotExclusions
