import { Box, Button } from "@mui/joy"
import React, { useState, DragEvent, ChangeEvent } from "react"
import { useSetRecoilState } from "recoil"
import { snackAlert } from "../SnackAlerts"

interface FileDropZoneProps {
  droppedFile: File | null
  setDroppedFile: React.Dispatch<React.SetStateAction<File | null>>
  clearSignature: () => void
}

const FileDropZone: React.FC<FileDropZoneProps> = ({
  droppedFile,
  setDroppedFile,
  clearSignature,
}: FileDropZoneProps) => {
  const [isDragging, setIsDragging] = useState(false)
  const [isValidDimension, setIsValidDimesnion] = useState(true)
  const setSnackAlertMsg = useSetRecoilState(snackAlert)

  const checkError = (file: File | null) => {
    if (!file) return false

    const maxFileSize = 5 * 1024 * 1024
    const validFileTypes = [
      "image/png",
      "image/jpeg",
      "image/bmp",
      "image/gif",
      "image/tiff",
    ]

    if (!validFileTypes.includes(file.type)) {
      setSnackAlertMsg({
        open: true,
        message:
          "Invalid file type. Please select a PNG, JPEG, BMP, GIF, or TIFF image.",
        severity: "error",
        autoHideDuration: 5000,
      })
      return true
    }

    if (file.size > maxFileSize) {
      setSnackAlertMsg({
        open: true,
        message: "File size should be less than 5MB.",
        severity: "error",
        autoHideDuration: 5000,
      })
      return true
    }

    return false
  }

  const checkDimension = (file: File) => {
    if (!file) return
    const img = new Image()
    img.onload = () => {
      const width = img.width
      const height = img.height
      if (width > 650 && height > 400) {
        setIsValidDimesnion(false)
        setSnackAlertMsg({
          open: true,
          message:
            "Image dimensions are too large. Maximum dimensions are 650x400px",
          severity: "error",
          autoHideDuration: 5000,
        })
        return false
      } else {
        setIsValidDimesnion(true)
        return true
      }
    }
    img.src = URL.createObjectURL(file)
  }

  const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDragging(true)
  }

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDragging(false)
  }

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDragging(false)
    const file = e.dataTransfer.files[0]
    checkDimension(file)
    if (isValidDimension) return
    if (checkError(file)) return
    if (file) {
      setDroppedFile(file)
    }
  }

  const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const file = e.target.files && e.target.files[0]
    if (!file) return
    checkDimension(file)
    if (isValidDimension) return
    if (checkError(file)) return
    if (file) {
      setDroppedFile(file)
    }
  }

  const handleSelectFilesClick = () => {
    const fileInput =
      document.querySelector<HTMLInputElement>("input[type=file]")
    if (fileInput) {
      fileInput.click()
    }
  }

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <div
          style={isDragging ? dropZoneActiveStyle : dropZoneStyle}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <input
            type="file"
            style={{ display: "none" }}
            onChange={handleFileInputChange}
          />
          {droppedFile ? (
            <p>Dropped file: {droppedFile.name}</p>
          ) : (
            <p>{`Drag 'n' drop a file here`}</p>
          )}
        </div>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "25px",
            mt: 1,
          }}
        >
          <Button onClick={() => clearSignature()} variant="outlined" size="md">
            Clear
          </Button>
          <Button variant="solid" size="md" onClick={handleSelectFilesClick}>
            Select File
          </Button>
        </Box>
      </Box>
    </>
  )
}

const dropZoneStyle: React.CSSProperties = {
  border: "2px dashed #aaa",
  borderRadius: "4px",
  display: "flex",
  width: "100%",
  height: "15vw",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  cursor: "pointer",
}

const dropZoneActiveStyle: React.CSSProperties = {
  ...dropZoneStyle,
  borderColor: "green",
}

export default FileDropZone
