import { atom, selector } from "recoil"
import { TimeSeriesPoint } from "@huxley-medical/react-components/types"
import { sleepWakeEvents } from "../state/event.state"
import { studyMetaData, studyPlotData } from "../state/study.state"
import {
  allNonRemovedExclusions,
  nonExcludedHr,
} from "../state/exclusions.state"
import { generatePlotData } from "../utils/studyUtils"
import { LinePlot } from "../types/line.type"
import { SignalsData, SleepInfo } from "../interfaces/signals.interface"
import {
  getNotOverlappingTimeSeriesWithEventArray,
  getOverlappingTimeSeriesWithEventArray,
  sleepWakeEventsToTimeSeriesPoints,
} from "../utils/seriesUtils"
import { getSumOfOverlappingTimeBetweenTwoEventArrays } from "../utils/eventUtils"
import { EventApi } from "../types/event.type"

export const sleepPlot = selector<TimeSeriesPoint[]>({
  key: "sleepPlot",
  get: ({ get }) => {
    const sleepE = get(sleepWakeEvents)

    return sleepWakeEventsToTimeSeriesPoints(sleepE)
  },
})

export const studySignals = atom<SignalsData | undefined>({
  key: "studySignals",
  default: undefined,
})

export const nonExcludedSpo2 = selector<TimeSeriesPoint[]>({
  key: "nonExcludedSpo2",
  get: ({ get }) => {
    const spo2 = get(studyPlotData).spo2
    const exclusions = get(allNonRemovedExclusions)
    return getNotOverlappingTimeSeriesWithEventArray(spo2, exclusions)
  },
})

export const sleepingHr = selector<TimeSeriesPoint[]>({
  key: "sleepingHr",
  get: ({ get }) => {
    const hr = get(nonExcludedHr)
    const sleepwakeEvents = get(sleepWakeEvents)
    const sleepE =
      sleepwakeEvents &&
      sleepwakeEvents.filter((event) => event.event_data.sleep_label)
    return getOverlappingTimeSeriesWithEventArray(hr, sleepE)
  },
})

export const allPlots = selector<LinePlot[]>({
  key: "allPlots",
  get: ({ get }) => {
    const studyd = get(studyPlotData)
    const sleepd = get(sleepWakeEvents)
    const sleepPlot = sleepWakeEventsToTimeSeriesPoints(sleepd)
    return generatePlotData({
      respiratoryeffortPlot: studyd.respiratory_effort,
      snorePlot: studyd.snore,
      spo2Plot: studyd.spo2,
      cardiacPlot: studyd.hr,
      actigraphyPlot: studyd.actigraphy,
      chestPlot: studyd.chest_movement,
      positionPlot: studyd.body_position,
      sleepPlot: sleepPlot,
      ecgPlot: studyd.ecg,
    })
  },
})

export const allPlotTimeDomain = atom<number[]>({
  key: "allPlotTimeDomain",
  default: [0, 0],
})

/**
 * sleepInfo - returns the sleepInfo for given study
 */
export const sleepInfo = selector<SleepInfo>({
  key: "sleepInfo",
  get: ({ get }) => {
    const sleepE = get(sleepWakeEvents)
    const exclusions = get(allNonRemovedExclusions)
    const studyStart = get(studyMetaData).studyStartTime
    const studyEnd = get(studyMetaData).studyEndTime
    const sleepDurationSeconds =
      sleepE &&
      sleepE
        .filter((sleepData: EventApi) => sleepData.event_data.sleep_label)
        .reduce((acc, event) => acc + event.endTS - event.startTS, 0)

    const sleepTime =
      sleepE &&
      sleepE.filter((sleepData: EventApi) => {
        return sleepData.event_data.sleep_label
      })

    const exclusionsExceptForLowAmp = exclusions.filter(
      (exclusion) => exclusion.event_data?.label !== 2
    )

    const efftst =
      sleepDurationSeconds -
      (getSumOfOverlappingTimeBetweenTwoEventArrays(
        sleepTime,
        exclusionsExceptForLowAmp
      ) +
        0)

    const firstSleepAwake =
      sleepE &&
      sleepE.find((sleepData: EventApi) => sleepData.event_data.sleep_label)
    const sleepLatencySeconds = firstSleepAwake
      ? firstSleepAwake.startTS - studyStart
      : 0

    const sleepEfficiencyPercent =
      (sleepDurationSeconds / (studyEnd - studyStart)) * 100

    return {
      sleepDurationSeconds,
      sleepLatencySeconds,
      sleepEfficiencyPercent,
      effSleepTime: efftst,
    }
  },
})
