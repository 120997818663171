import { Tab } from "@mui/material"
import { Tabs } from "@mui/material"
import { styled } from "@mui/joy/styles"

export const StyledTab = styled(Tab)({
  "&.Mui-selected": {
    color: "#006BB9",
  },
  color: "#8991AB",
  fontWeight: "600",
  textTransform: "none",
})

export const StyledTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: "#006BB9",
  },
  "& .MuiIconButton-root": {
    height: "40px",
    marginTop: "2.5px",
    marginLeft: "auto",
    marginRight: "10px",
  },
  "& .MuiSvgIcon-root": {
    color: "#006BB9",
    cursor: "pointer",
  },
  "& .MuiBadge-dot": {
    right: "-5px",
    top: "0px",
  },
})
