import { Box, FormControl, Grid, Input, Stack } from "@mui/joy"
import ModalBox from "../../../components/modal/ModalBox"
import { Button } from "@mui/joy"
import { useEffect, useMemo, useReducer, useState } from "react"
import {
  interpretationTemplates,
  isTemplateLoading,
} from "../../../state/interpretationtemplate.state"
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil"
import {
  GetAllInterpretationTemplatesRequest,
  InterpretationTemplateIn,
} from "../../../generated/fetchclient"
import InterpretationTemplateForm from "../../../components/interpretationtemplate/InterpretationTemplateForm"
import { currentUser } from "../../../state/auth.state"
import useInterpretationTemplate from "../../../hooks/useInterpretationTemplate"
import { Refresh, Search } from "@mui/icons-material"
import {
  generateHTMLFromJsonContent,
  initialEditorData,
} from "../../../utils/tipTapUtils"
import { JSONContent } from "@tiptap/core"
import { tipTapContentStatus } from "../../../state/tipTap.state"
import IconButton from "@mui/joy/IconButton"
import AddIcon from "@mui/icons-material/Add"
import LoadingIndicator from "../../../components/loading/LoadingIndicator"
import NoInterpretationTemplateFound from "./NoInterpretationTemplateFound"
import AddInterpretationTemplate from "./AddInterpretationTemplate"
import ListInterpretationTemplateGrid from "./ListInterpretationTemplateGrid"

interface InterpretationModalProps {
  interpretationTemplateModalStatus: boolean
  setInterpretationModalStatus: React.Dispatch<React.SetStateAction<boolean>>
  setInterpretationEditorContentData: React.Dispatch<
    React.SetStateAction<JSONContent>
  >
  setInitialInterpretationContentData: React.Dispatch<
    React.SetStateAction<JSONContent>
  >
}

export interface InterpretationTemplateFormData
  extends InterpretationTemplateIn {
  uuid?: string
}

export type Action =
  | { type: "CHANGE"; payload: Partial<InterpretationTemplateFormData> }
  | { type: "SUBMIT" }
  | { type: "REPLACE_STATE"; payload: Partial<InterpretationTemplateFormData> }

const emptyInterpretationTemplateFormErrors = {
  title: "",
  notes: "",
}

const emptyInterpretationQuery: GetAllInterpretationTemplatesRequest = {
  templateTitle: "",
}

const InterpretationTemplateModal = ({
  interpretationTemplateModalStatus,
  setInterpretationModalStatus,
  setInterpretationEditorContentData,
  setInitialInterpretationContentData,
}: InterpretationModalProps) => {
  const interpretationTemplateApi = useInterpretationTemplate()
  const user = useRecoilValue(currentUser)
  const [
    interpretationTemplateFormErrors,
    setInterpretationTemplateFormErrors,
  ] = useState(emptyInterpretationTemplateFormErrors)
  const [
    addInterpretationTemplateFormStatus,
    setAddInterpretationTemplateFormStatus,
  ] = useState<boolean>(false)
  const [
    editInterpretationTemplateFormStatus,
    setEditInterpretationTemplateFormStatus,
  ] = useState<boolean>(false)
  const interpretationTemplatesDataList = useRecoilValue(
    interpretationTemplates
  )
  const [isInterpretationTemplateLoading, setIsInterpretationTemplateLoading] =
    useRecoilState(isTemplateLoading)
  const [interpretationTemplateQuery, setinterpretationTemplateQuery] =
    useState<GetAllInterpretationTemplatesRequest>(emptyInterpretationQuery)
  const isEmptyTipTapEditorContent = useRecoilValue(tipTapContentStatus)
  const resetIsEmptyTipTapEditorContent =
    useResetRecoilState(tipTapContentStatus)
  const resetInterpretationTemplates = useResetRecoilState(
    interpretationTemplates
  )
  const interpretationFormData: InterpretationTemplateFormData = {
    title: "This is title",
    notes: { ...initialEditorData },
    userId: user !== undefined ? (user.uuid as string) : "",
  }
  const [
    initialInterpretationEditorContentData,
    setInitialInterpretationEditorContentData,
  ] = useState<JSONContent>({ ...initialEditorData })
  const [templateInterpretationContent, setTemplateInterpretationContent] =
    useState<InterpretationTemplateFormData>({ ...interpretationFormData })
  const validateForm = () => {
    let titleError = ""
    let notesError = ""

    if (interpretationTemplateFormData.title.trim() === "") {
      titleError = "Required"
    }

    if (isEmptyTipTapEditorContent) {
      notesError = "Required"
    }

    const errors = {
      title: titleError,
      notes: notesError,
    }

    setInterpretationTemplateFormErrors(errors)
    return Object.values(errors).every((e) => e === "")
  }

  const reducer = (state: InterpretationTemplateFormData, action: Action) => {
    switch (action.type) {
      case "CHANGE":
        return { ...state, ...action.payload }
      default:
        return state
    }
  }

  const [interpretationTemplateFormData, dispatch] = useReducer(
    reducer,
    interpretationFormData
  )
  const editInterpretationTemplate = async () => {
    if (!validateForm()) return

    setIsInterpretationTemplateLoading(true)
    await interpretationTemplateApi.updateInterpretationTemplate(
      interpretationTemplateFormData.uuid
        ? interpretationTemplateFormData.uuid
        : "",
      interpretationTemplateFormData
    )
    setEditInterpretationTemplateFormStatus(false)
    setIsInterpretationTemplateLoading(false)
  }
  const selectedInterpretationContent = useMemo(() => {
    return generateHTMLFromJsonContent(
      templateInterpretationContent.notes as JSONContent,
      []
    )
  }, [templateInterpretationContent.notes])

  const addInterpretationTemplate = async () => {
    if (!validateForm()) return

    setIsInterpretationTemplateLoading(true)
    await interpretationTemplateApi.createInterpretationTemplate({
      ...interpretationTemplateFormData,
      notes: { ...interpretationTemplateFormData.notes } as object,
      userId: user !== undefined ? (user.uuid as string) : "",
    })
    setAddInterpretationTemplateFormStatus(false)
    setIsInterpretationTemplateLoading(false)
  }

  useEffect(() => {
    if (
      !addInterpretationTemplateFormStatus &&
      !editInterpretationTemplateFormStatus
    ) {
      dispatch({
        type: "CHANGE",
        payload: {
          ...interpretationFormData,
        },
      })
      setInitialInterpretationEditorContentData(
        interpretationFormData.notes as JSONContent
      )
      setInterpretationTemplateFormErrors(emptyInterpretationTemplateFormErrors)
    }
  }, [
    addInterpretationTemplateFormStatus,
    editInterpretationTemplateFormStatus,
  ])

  const resetData = () => {
    setAddInterpretationTemplateFormStatus(false)
    resetIsEmptyTipTapEditorContent()
    resetInterpretationTemplates()
    setEditInterpretationTemplateFormStatus(false)
  }

  useEffect(() => {
    if (!interpretationTemplateModalStatus) {
      resetData()
    }
  }, [interpretationTemplateModalStatus])

  useEffect(() => {
    return () => {
      resetData()
    }
  }, [])

  useEffect(() => {
    if (interpretationTemplatesDataList.length > 0)
      setTemplateInterpretationContent({
        uuid: interpretationTemplatesDataList[0].uuid,
        title: interpretationTemplatesDataList[0].title,
        notes: interpretationTemplatesDataList[0].notes,
        userId:
          interpretationTemplatesDataList[0].user.uuid !== undefined
            ? interpretationTemplatesDataList[0].user.uuid
            : "",
      })
  }, [interpretationTemplatesDataList])

  const selectInterpretationEditorContent = () => {
    setInitialInterpretationContentData(
      templateInterpretationContent.notes as JSONContent
    )
    setInterpretationEditorContentData(
      templateInterpretationContent.notes as JSONContent
    )
    setInterpretationModalStatus(false)
  }
  const openEditInterpretationForm = (
    interpretationTemplateData: InterpretationTemplateFormData
  ) => {
    dispatch({
      type: "CHANGE",
      payload: { ...interpretationTemplateData },
    })
    setInitialInterpretationEditorContentData(
      interpretationTemplateData.notes as JSONContent
    )
    setEditInterpretationTemplateFormStatus(true)
  }

  const [
    searchInterpretationTemplateQuery,
    setSearchInterpretationTemplateQuery,
  ] = useState<boolean>(false)

  const searchInterpretationTemplates = async () => {
    setIsInterpretationTemplateLoading(true)
    setSearchInterpretationTemplateQuery(true)
    await interpretationTemplateApi.listInterpretationTemplates(
      interpretationTemplateQuery
    )
    setIsInterpretationTemplateLoading(false)
  }

  const getAllInterpretationTemplates = async () => {
    setIsInterpretationTemplateLoading(true)
    await interpretationTemplateApi.listInterpretationTemplates({})
    setSearchInterpretationTemplateQuery(false)
    setinterpretationTemplateQuery({ templateTitle: "" })
    setIsInterpretationTemplateLoading(false)
  }

  const deleteInterpretationTemplate = async (templateId: string) => {
    setIsInterpretationTemplateLoading(true)
    await interpretationTemplateApi.deleteInterpretationTemplate(templateId)
    setIsInterpretationTemplateLoading(false)
  }

  return (
    <ModalBox
      open={interpretationTemplateModalStatus}
      setOpen={setInterpretationModalStatus}
      title={
        addInterpretationTemplateFormStatus
          ? "Add Interpretation Template"
          : editInterpretationTemplateFormStatus
          ? "Edit Interpretation Template"
          : "Interpretation Templates"
      }
      width={
        interpretationTemplatesDataList.length === 0 &&
        interpretationTemplateQuery.templateTitle === "" &&
        !searchInterpretationTemplateQuery
          ? 700
          : 900
      }
    >
      {addInterpretationTemplateFormStatus ||
      editInterpretationTemplateFormStatus ? (
        <>
          <InterpretationTemplateForm
            initialInterpretationEditorContentData={
              initialInterpretationEditorContentData
            }
            formData={interpretationTemplateFormData}
            dispatch={dispatch}
            formErrors={interpretationTemplateFormErrors}
            formSubmit={
              addInterpretationTemplateFormStatus
                ? addInterpretationTemplate
                : editInterpretationTemplate
            }
            formButtonTitle={
              addInterpretationTemplateFormStatus
                ? "Add Template"
                : "Update Template"
            }
            setBackButton={
              addInterpretationTemplateFormStatus
                ? setAddInterpretationTemplateFormStatus
                : setEditInterpretationTemplateFormStatus
            }
          />
        </>
      ) : interpretationTemplatesDataList.length === 0 &&
        interpretationTemplateQuery.templateTitle === "" &&
        !searchInterpretationTemplateQuery ? (
        <AddInterpretationTemplate
          setAddInterpretationTemplateFormStatus={
            setAddInterpretationTemplateFormStatus
          }
        />
      ) : (
        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
          <Grid xs={12}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Stack
                direction="row"
                spacing={2}
                display="flex"
                alignItems="center"
              >
                <FormControl sx={{ width: "15rem" }}>
                  <Input
                    placeholder="Search by title"
                    value={interpretationTemplateQuery.templateTitle}
                    onChange={(e) =>
                      setinterpretationTemplateQuery({
                        ...interpretationTemplateQuery,
                        templateTitle: e.target.value,
                      })
                    }
                  />
                </FormControl>
                <IconButton
                  variant="solid"
                  color="primary"
                  onClick={searchInterpretationTemplates}
                >
                  <Search />
                </IconButton>
                <IconButton
                  variant="outlined"
                  color="primary"
                  onClick={getAllInterpretationTemplates}
                >
                  <Refresh />
                </IconButton>
              </Stack>
              <Button
                onClick={() => setAddInterpretationTemplateFormStatus(true)}
                variant="solid"
                startDecorator={<AddIcon />}
                size="sm"
              >
                Add Templates
              </Button>
            </Box>
          </Grid>
          {searchInterpretationTemplateQuery &&
          interpretationTemplatesDataList.length === 0 ? (
            <NoInterpretationTemplateFound />
          ) : (
            <ListInterpretationTemplateGrid
              setTemplateInterpretationContent={
                setTemplateInterpretationContent
              }
              templateInterpretationContent={templateInterpretationContent}
              openEditInterpretationForm={openEditInterpretationForm}
              selectedInterpretationContent={selectedInterpretationContent}
              selectInterpretationEditorContent={
                selectInterpretationEditorContent
              }
              deleteInterpretationTemplate={deleteInterpretationTemplate}
            />
          )}
        </Grid>
      )}
      {isInterpretationTemplateLoading && (
        <LoadingIndicator
          header="Loading Interpretation Templates"
          content="This may take some time"
        />
      )}
    </ModalBox>
  )
}

export default InterpretationTemplateModal
