import {
  Button,
  Divider,
  Grid,
  Input,
  Option,
  Radio,
  RadioGroup,
  Select,
} from "@mui/joy"
import { AssignmentInd } from "@mui/icons-material"
import GridDivider from "../../griddivider/GridDivider"
import React, { useEffect, useState } from "react"
import { snackAlert } from "../../../components/SnackAlerts"
import { usePortalApi } from "../../../connections"
import { isLoadingStudy } from "../../../state/study.state"
import { SleepStudyOrderIn, UserOut } from "../../../generated/fetchclient"
import { useSetRecoilState } from "recoil"
import { handleApiError } from "../../../utils/apiUtils"
import { states, studyDesatThresholdOptions } from "../../../utils/studyUtils"
import useStudies from "../../../hooks/useStudies"
import { PatientIdentifier } from "../../../types/patient.type"
import { EditModalStudyData } from "../../../types/event.type"
import GridFormInput from "../../forms/grid/GridFormInput"

type StudyDetailFormParams = {
  selectedPatient: PatientIdentifier
  setPage: React.Dispatch<React.SetStateAction<number>>
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  studyData?: EditModalStudyData
}

export const emptyStudyInput = {
  uuid: "",
  patientId: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  scheduledDate: new Date(),
  oxygenDesatThreshold: "three",
  orderingPhysicianId: "",
  interpretingPhysicianId: "",
  assignedDeviceSerialNumber: "",
  notes: "",
  type: "",
  status: "",
}

const emptyInputErrors = {
  address1: "",
  city: "",
  zip: "",
}

function StudyDetailForm({
  selectedPatient,
  setPage,
  setOpen,
  studyData,
}: StudyDetailFormParams) {
  const api = usePortalApi()
  const studiesApi = useStudies()
  const setSnackAlertMsg = useSetRecoilState(snackAlert)
  const setIsLoading = useSetRecoilState(isLoadingStudy)
  const [physicians, setPhysicians] = useState<UserOut[]>([])
  const [studyInput, setStudyInput] = useState<SleepStudyOrderIn>(
    studyData || emptyStudyInput
  )
  const [inputErrors, setInputErrors] = useState(emptyInputErrors)
  const disabledField = studyData ? studyData.status !== "OD" : false

  useEffect(() => {
    const loadPhysicians = async () => {
      if (api === undefined) return
      try {
        const pagedUsers = await api.getAllUsers({
          roles: ["Physician"],
        })
        const { items: physicians } = pagedUsers
        setPhysicians(physicians)
      } catch (error) {
        handleApiError(setSnackAlertMsg)(error)
      }
    }

    loadPhysicians()
  }, [api])

  useEffect(() => {
    setStudyInput({
      ...studyInput,
      patientId: selectedPatient.uuid,
    })
  }, [selectedPatient])

  const handlePropChange = (
    property: keyof SleepStudyOrderIn,
    value: Date | string
  ) => {
    setStudyInput({
      ...studyInput,
      [property]: value,
    })
  }

  const validateForm = () => {
    let address1Error = ""
    let cityError = ""
    let zipError = ""

    if (studyInput.address1.trim() === "") {
      address1Error = "Required"
    }

    if (studyInput.city.trim() === "") {
      cityError = "Required"
    }

    if (studyInput.zip.trim() === "") {
      zipError = "Required"
    }

    const errors = {
      address1: address1Error,
      city: cityError,
      zip: zipError,
    }

    setInputErrors(errors)

    return Object.values(errors).every((e) => e === "")
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (api === undefined) {
      setSnackAlertMsg({
        open: true,
        message: "Something went wrong. API not found.",
        severity: "error",
        autoHideDuration: 5000,
      })
      return
    }

    if (!validateForm()) return

    setIsLoading(true)

    if (studyData) {
      await studiesApi.updateStudyOrder(studyData?.uuid ?? "", studyInput)
      setPage(2)
    } else {
      await studiesApi.createStudy(studyInput)
      setPage(1)
    }

    setIsLoading(false)
    setOpen(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid xs={12} display="flex" justifyContent="center">
          <AssignmentInd />
          <b>Patient:&nbsp;</b>
          {selectedPatient.name}
        </Grid>
        <GridDivider
          xs={12}
          sx={{ "--Divider-childPosition": "0%" }}
          text={"Study Ship To Address"}
        />
        <GridFormInput
          width={12}
          label="Address Line 1"
          error={inputErrors.address1}
        >
          <Input
            value={studyInput.address1}
            disabled={disabledField}
            onChange={(e) => handlePropChange("address1", e.target.value)}
            required
          />
        </GridFormInput>
        <GridFormInput width={12} label="Address Line 2">
          <Input
            value={studyInput.address2}
            disabled={disabledField}
            onChange={(e) => handlePropChange("address2", e.target.value)}
          />
        </GridFormInput>
        <GridFormInput width={4} label="City" error={inputErrors.city}>
          <Input
            value={studyInput.city}
            disabled={disabledField}
            onChange={(e) => handlePropChange("city", e.target.value)}
            required
          />
        </GridFormInput>
        <GridFormInput width={4} label="State">
          <Select
            value={studyInput.state}
            disabled={disabledField}
            onChange={(_, value) => {
              if (value) {
                handlePropChange("state", value)
              }
            }}
            placeholder="Select"
            required
          >
            {states.map((state, index) => (
              <Option key={index} value={state}>
                {state}
              </Option>
            ))}
          </Select>
        </GridFormInput>
        <GridFormInput width={4} label="Zip Code" error={inputErrors.zip}>
          <Input
            value={studyInput.zip}
            disabled={disabledField}
            onChange={(e) => handlePropChange("zip", e.target.value)}
            required
          />
        </GridFormInput>
        <GridDivider
          xs={12}
          sx={{ "--Divider-childPosition": "0%" }}
          text={"Study Details"}
        />
        <GridFormInput width={6} label="Study Start Date Request">
          <Input
            type="date"
            disabled={disabledField}
            value={studyInput.scheduledDate.toISOString().split("T")[0]}
            onChange={(e) => {
              const scheduledDate = e.target.valueAsDate
              if (scheduledDate) {
                handlePropChange("scheduledDate", scheduledDate)
              }
            }}
            required
            slotProps={
              studyData === undefined
                ? {
                    input: {
                      min: new Date().toISOString().split("T")[0],
                    },
                  }
                : {}
            }
          />
        </GridFormInput>
        <GridFormInput width={6} label="Desaturation Threshold">
          <RadioGroup orientation="horizontal">
            {studyDesatThresholdOptions.map((option, index) => (
              <Radio
                key={index}
                checked={studyInput.oxygenDesatThreshold === option.value}
                label={option.label}
                value={option.value}
                onClick={() =>
                  handlePropChange("oxygenDesatThreshold", option.value)
                }
              />
            ))}
          </RadioGroup>
        </GridFormInput>
        <GridFormInput width={6} label="Ordering Physician Name">
          <Select
            value={studyInput.orderingPhysicianId}
            onChange={(_, value) => {
              if (value) {
                handlePropChange("orderingPhysicianId", value)
              }
            }}
            placeholder="Select one"
            required
          >
            {physicians.map((physician, index) => {
              return (
                <Option key={index} value={physician.uuid}>
                  {physician.firstName} {physician.lastName}
                </Option>
              )
            })}
          </Select>
        </GridFormInput>
        <GridFormInput width={6} label="Interpreting Physician Name">
          <Select
            value={studyInput.interpretingPhysicianId}
            onChange={(_, value) => {
              if (value) {
                handlePropChange("interpretingPhysicianId", value)
              }
            }}
            placeholder="Select one"
            required
          >
            {physicians.map((physician, index) => {
              return (
                <Option key={index} value={physician.uuid}>
                  {physician.firstName} {physician.lastName}
                </Option>
              )
            })}
          </Select>
        </GridFormInput>
        <Grid xs={12}>
          <Divider />
        </Grid>
        <Grid xs={12} display="flex" justifyContent="flex-end">
          <Button type="submit">
            {studyData ? "Edit Study" : "Create Study"}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default StudyDetailForm
