import React from "react"
import { useRecoilValue } from "recoil"
import {
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionGroup,
  Divider,
} from "@mui/joy"
import AccordianDataDisplay from "../../../components/accordian/AccordianDataDisplay"
import { ScoringHeaderProps } from "../../../interfaces/header.inteface"
import {
  desatMetrics,
  ecgMetrics,
  hrMetrics,
  spo2Metrics,
  //summaryIndices,
} from "../../../state/graphSummary.state"
import { studyMetaData } from "../../../state/study.state"
import { sleepInfo } from "../../../state/signals.state"
import { secondsToHms } from "../../../utils/timeUtils"

const ScoringHeader: React.FC<ScoringHeaderProps> = ({ children }) => {
  const desatmetrics = useRecoilValue(desatMetrics)
  const studymetrics = useRecoilValue(studyMetaData)
  const spo2metrics = useRecoilValue(spo2Metrics)
  const hrmetrics = useRecoilValue(hrMetrics)
  const sleepinfo = useRecoilValue(sleepInfo)
  //const summaryindices = useRecoilValue(summaryIndices) //only the numb beats should be used
  const ecgMetricsData = useRecoilValue(ecgMetrics)
  const totalDesats =
    desatmetrics.desatsAbove20 +
    desatmetrics.desatsNineTo20 +
    desatmetrics.desatsBelow9
  const seriesSummary = [
    {
      label: "Sleep Time",
      value: secondsToHms(sleepinfo.sleepDurationSeconds),
    },
    {
      label: "Eff. Sleep Time",
      value: secondsToHms(sleepinfo.effSleepTime),
      tooltipHelp:
        "Effective Sleep Time is the time in sleep which has no data exlcusions",
    },
    {
      label: "Sleep Efficiency",
      value: `${sleepinfo.sleepEfficiencyPercent.toFixed(0)}%`,
    },
    {
      label: "sAHI 3",
      value: desatmetrics.ahi3?.toFixed(1) ?? "N/A",
    },
    {
      label: "sAHI 4",
      value: desatmetrics.ahi4?.toFixed(1) ?? "N/A",
    },
    {
      label: "ODI 3",
      value: desatmetrics.odi3?.toFixed(1) ?? "N/A",
    },
    {
      label: "ODI 4",
      value: desatmetrics.odi4?.toFixed(1) ?? "N/A",
    },
  ]
  const spO2Details = [
    {
      label: "Max SpO2",
      value: spo2metrics.maxSpo2?.toFixed(1) ?? "N/A",
    },
    {
      label: "Min SpO2",
      value: spo2metrics.minSpo2?.toFixed(1) ?? "N/A",
    },
    {
      label: "Mean SpO2",
      value: spo2metrics.meanSpo2?.toFixed(1) ?? "N/A",
    },
  ]
  const desatMetricsDisplayItems = [
    {
      label: "Avg. Desat. Nadir",
      value: desatmetrics.meanDesatNadir?.toFixed(1) ?? "N/A",
    },
    {
      label: "Num Desats <9%",
      value: desatmetrics.desatsBelow9?.toFixed(1) ?? "N/A",
    },
    {
      label: "Num Desats 9-20%",
      value: desatmetrics.desatsNineTo20?.toFixed(1) ?? "N/A",
    },
    {
      label: "Num Desats >20%",
      value: desatmetrics.desatsAbove20?.toFixed(1) ?? "N/A",
    },
    {
      label: "Total Desats",
      value: totalDesats.toFixed(1) ?? "N/A",
    },
  ]
  const hrDetails = [
    {
      label: "Mean HR",
      value: hrmetrics.meanHr?.toFixed(0) ?? "N/A",
    },
    {
      label: "Max HR",
      value: hrmetrics.maxHr?.toFixed(0) ?? "N/A",
    },
    {
      label: "Min HR",
      value: hrmetrics.minHr?.toFixed(0) ?? "N/A",
    },
    {
      label: "Mean HR Sleep",
      value: hrmetrics.meanHrSleeping?.toFixed(0) ?? "N/A",
    },
    {
      label: "Max HR Sleep",
      value: hrmetrics.maxHrSleeping?.toFixed(0) ?? "N/A",
    },
    {
      label: "Min HR Sleep",
      value: hrmetrics.minHrSleeping?.toFixed(0) ?? "N/A",
    },
    {
      label: "Total VEB",
      value: ecgMetricsData?.vebEvents ?? "N/A",
    },
    {
      label: "Total SVEB",
      value: ecgMetricsData?.svebEvents ?? "N/A",
    },
  ]
  return (
    <>
      <AccordionGroup color="neutral" variant="plain">
        <Accordion>
          <AccordionSummary>
            <Stack
              direction="row"
              spacing={{ sm: 1, md: 2, xl: 4 }}
              justifyContent={{ sm: "space-between", md: "space-between" }}
            >
              <AccordianDataDisplay
                label="Study Start"
                value={new Date(
                  studymetrics.studyStartTime * 1000
                ).toLocaleString([], {
                  dateStyle: "short",
                  timeStyle: "short",
                })}
              />
              {seriesSummary.map((summary, index) => (
                <AccordianDataDisplay
                  key={index}
                  label={summary.label}
                  value={summary.value}
                  tooltipHelp={summary.tooltipHelp}
                />
              ))}
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Stack direction="column" spacing={{ sm: 1, md: 1, xl: 1 }}>
              <Stack
                direction="row"
                spacing={{ sm: 1, md: 2, xl: 4 }}
                alignItems="center"
              >
                {hrDetails.map((summary, index) => (
                  <AccordianDataDisplay
                    key={index}
                    label={summary.label}
                    value={summary.value}
                  />
                ))}
              </Stack>
              <Stack
                direction="row"
                spacing={{ sm: 1, md: 2, xl: 4 }}
                alignItems="center"
              >
                {spO2Details.map((summary, index) => (
                  <AccordianDataDisplay
                    key={index}
                    label={summary.label}
                    value={summary.value}
                  />
                ))}
                <Divider orientation="vertical" />
                {desatMetricsDisplayItems.map((summary, index) => (
                  <AccordianDataDisplay
                    key={index}
                    label={summary.label}
                    value={summary.value}
                  />
                ))}
              </Stack>
            </Stack>
          </AccordionDetails>
        </Accordion>
      </AccordionGroup>
      {children}
    </>
  )
}

export default ScoringHeader
