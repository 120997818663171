import { Box, IconButton, Stack } from "@mui/joy"
import { Div } from "@huxley-medical/react-components/elements"
import { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil"
import { ParentSize } from "@visx/responsive"
import useLoadStudy from "../../hooks/useLoadStudy"
import IntervalFilter from "../../components/scoring/intervals/IntervalFilter"
import LoadingIndicator from "../../components/loading/LoadingIndicator"
import DatasetSidebar from "../../components/scoring/sidebar/ScoringSidebar"
import StackedPlots from "../../components/scoring/StackedPlots"
import HyponogramScroller from "../../components/scoring/hypnogram/HypnogramScroller"
import ScoringHeader from "../../components/scoring/scoringheader/ScoringHeader"
import { snackAlert } from "../../components/SnackAlerts"
import {
  StyledDatasetHeader,
  StyledDatasetPlots,
  StyledStudyDatasetContent,
} from "../../styled/Div"
import {
  isGeneratingPDFReport,
  reportStatus,
} from "../../state/pdfReport.state"
import {
  activeStudyID,
  currentStudy,
  isLoadingStudy,
  studyMetaData,
  studyPlotOrder,
} from "../../state/study.state"
import {
  csdRegionsOfInterest,
  eventIDs,
  eventMetaData,
  respiratoryEvents,
  sleepWakeEvents,
} from "../../state/event.state"
import {
  ecgBadExclusions,
  ecgExclusions,
  ppgExclusions,
} from "../../state/exclusions.state"
import { currentEpoch } from "../../state/epoch.state"
import {
  fullNightInterval,
  intervalTimeEventTypeAtom,
  timeIntervals,
} from "../../state/study.state"
import { allPlotTimeDomain, studySignals } from "../../state/signals.state"
import StudyScoringHeader from "../../views/scoring/StudyScoringHeader"
import { JSONContent } from "@tiptap/core"
import { isTemplateLoading } from "../../state/interpretationtemplate.state"
import useInterpretationTemplate from "../../hooks/useInterpretationTemplate"
import InterpretationTemplateModal from "./interpretationtemplatemodal/InterpretationTemplateModal"
import SignatureModal from "./signaturemodal/SignatureModal"
import InterpretationDrawer from "./interpretationdrawer/InterpretationDrawer"
import { StudyStatus, statusStrToEnum } from "../../utils/studyUtils"
import { disableGraph } from "../../state/graph.state"
import { currentUser } from "../../state/auth.state"
import useSignature from "../../hooks/useSignature"
import { isSignatureLoading } from "../../state/signature.state"
import { handleApiError } from "../../utils/apiUtils"
import { initialEditorData } from "../../utils/tipTapUtils"
import useInterpretStudy from "../../hooks/useInterpretStudy"
import PlotOrderModal from "../../components/scoring/plotOrderModal/plotOrderModal"
import TuneIcon from "@mui/icons-material/Tune"
import { summaryIndices } from "../../state/graphSummary.state"

const drawerWidth = 350

const StudyScoringContent = () => {
  const { studyID } = useParams()
  const interpretationTemplateApi = useInterpretationTemplate()
  const interpretStudyApi = useInterpretStudy()
  const signatureApi = useSignature()
  const chartRef = useRef<HTMLDivElement>(null)
  const scoringHeight = useRef<HTMLDivElement>(null)
  const user = useRecoilValue(currentUser)
  const signatureRef = useRef<HTMLCanvasElement | null>(null)
  const isGeneratingPDF = useRecoilValue(isGeneratingPDFReport)
  const setSnackAlertMsg = useSetRecoilState(snackAlert)
  const [isSignatureLoadingStatus, setIsSignatureLoadingStatus] =
    useRecoilState(isSignatureLoading)
  const [study, setStudy] = useRecoilState(currentStudy)
  const isLoading = useRecoilValue(isLoadingStudy)
  const setDisableGraph = useSetRecoilState(disableGraph)
  const resetActiveStudyId = useResetRecoilState(activeStudyID)
  const [isInterpretationTemplateLoading, setIsInterpretationTemplateLoading] =
    useRecoilState(isTemplateLoading)
  const resetStudyMetrics = useResetRecoilState(studyMetaData)
  const resetEventIds = useResetRecoilState(eventIDs)
  const resetEcgExclusions = useResetRecoilState(ecgExclusions)
  const resetPpgExclusions = useResetRecoilState(ppgExclusions)
  const resetCurrentEpoch = useResetRecoilState(currentEpoch)
  const resetSnackAlertMsg = useResetRecoilState(snackAlert)
  const resetFullNightIntervalValue = useResetRecoilState(fullNightInterval)
  const resetSelectedTimeInterval = useResetRecoilState(
    intervalTimeEventTypeAtom
  )
  const resetTimeIntervalList = useResetRecoilState(timeIntervals)
  const resetStudyData = useResetRecoilState(studySignals)
  const resetSleepEventData = useResetRecoilState(sleepWakeEvents)
  const resetSnackAletMsg = useResetRecoilState(snackAlert)
  const resetCurrentStudy = useResetRecoilState(currentStudy)
  const resetEventMetaData = useResetRecoilState(eventMetaData)
  const resetSummaryIndicies = useResetRecoilState(summaryIndices)
  const resetCsdRegionsOfInterest = useResetRecoilState(csdRegionsOfInterest)
  const resetRespiratoryEvents = useResetRecoilState(respiratoryEvents)
  const resetEcgBadExclusions = useResetRecoilState(ecgBadExclusions)
  const resetReportStatus = useResetRecoilState(reportStatus)
  const resetGeneratingReportPdfStatus = useResetRecoilState(
    isGeneratingPDFReport
  )
  const resetAllPlotTimeDomain = useResetRecoilState(allPlotTimeDomain)
  const resetStudyPlotOrder = useResetRecoilState(studyPlotOrder)
  const resetDisableGraph = useResetRecoilState(disableGraph)
  const [signatureRadioValue, setSignatureRadioValue] = useState("upload")
  const [openInterpretationDrawerStatus, setOpenInterpretationDrawerStatus] =
    useState<boolean>(false)
  const [interpretationDrawerWidth, setInterpretationDrawerWidth] =
    useState(drawerWidth)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [interpretationEditorContentData, setInterpretationEditorContentData] =
    useState<JSONContent>({ ...initialEditorData })
  const [
    initialInterpretationEditorContentData,
    setInitialInterpretationEditorContentData,
  ] = useState<JSONContent>({ ...initialEditorData })
  const [
    interpretationTemplateModalStatus,
    setInterpretationTemplateModalStatus,
  ] = useState<boolean>(false)
  const [signatureModalStatus, setSignatureModalStatus] =
    useState<boolean>(false)
  const [studyInterpreted, setStudyInterpreted] = useState(false)
  const [studyInterpreting, setStudyInterpreting] = useState<boolean>(false)
  const [signatureUrl, setSignatureUrl] = useState<string | undefined>(
    undefined
  )
  const [droppedFile, setDroppedFile] = useState<File | null>(null)
  const [editSignature, setEditSignature] = useState<boolean>(false)

  const [openPlotOrderModal, setOpenPlotOrderModal] = useState(false)
  const handleOpenPlotOrderModal = () => {
    setOpenPlotOrderModal(true)
  }

  useLoadStudy(studyID as string)

  const openInterpretationTemplateModal = async () => {
    setIsInterpretationTemplateLoading(true)
    await interpretationTemplateApi.listInterpretationTemplates({})
    setInterpretationTemplateModalStatus(true)
    setIsInterpretationTemplateLoading(false)
  }
  const onInterpretationEditorContentChange = (contentData: JSONContent) => {
    setIsInterpretationTemplateLoading(true)
    setInterpretationEditorContentData(contentData)
    setIsInterpretationTemplateLoading(false)
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSignatureRadioValue(event.target.value)
  }

  /*const openInterpretationDrawer = () => {
    setOpenInterpretationDrawerStatus(true)
  }*/

  const interpretCurrentStudy = async () => {
    setStudyInterpreting(true)
    await interpretStudyApi.interpretStudy({
      //interpretationEditorContentData,
      setStudyInterpreted,
    })
    setStudyInterpreting(false)
  }

  const uploadSignature = async (droppedFile: File) => {
    if (droppedFile === null || user === undefined || user.uuid === undefined)
      return

    try {
      if (await signatureApi.uploadSignatureImage(user?.uuid, droppedFile)) {
        await interpretCurrentStudy()
        return true
      } else {
        return false
      }
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  const saveSignature = async () => {
    if (user === undefined || user.uuid === undefined) return

    if (signatureUrl !== undefined && !editSignature) {
      setIsSignatureLoadingStatus(true)
      await interpretCurrentStudy()
      setSnackAlertMsg({
        open: true,
        message: "Study Interpreted",
        severity: "success",
        autoHideDuration: 5000,
      })
      setInterpretationEditorContentData({ ...initialEditorData })
      setOpenInterpretationDrawerStatus(false)
      setSignatureModalStatus(false)
      setIsSignatureLoadingStatus(false)
      return
    }

    if (signatureRadioValue === "upload") {
      if (droppedFile === null) {
        setSnackAlertMsg({
          open: true,
          message: "Please select a file",
          severity: "error",
          autoHideDuration: 5000,
        })
        return
      }
      setIsSignatureLoadingStatus(true)
      if (await uploadSignature(droppedFile)) {
        setIsSignatureLoadingStatus(false)
        setSnackAlertMsg({
          open: true,
          message: "Signature uploaded and Study Interpreted",
          severity: "success",
          autoHideDuration: 5000,
        })
        setInterpretationEditorContentData({ ...initialEditorData })
        setOpenInterpretationDrawerStatus(false)
        setSignatureModalStatus(false)
      } else {
        setIsSignatureLoadingStatus(false)
      }
    } else if (signatureRadioValue === "signature") {
      setIsSignatureLoadingStatus(true)
      const canvas = signatureRef.current
      if (canvas !== null) {
        canvas.toBlob(async (blob) => {
          if (blob !== null) {
            const convertedFile = new File([blob], "signature.png", {
              type: "image/png",
            })
            if (await uploadSignature(convertedFile)) {
              setIsSignatureLoadingStatus(false)
              setSnackAlertMsg({
                open: true,
                message: "Signature uploaded and Study Interpreted",
                severity: "success",
                autoHideDuration: 5000,
              })
              setInterpretationEditorContentData({ ...initialEditorData })
              setOpenInterpretationDrawerStatus(false)
              setSignatureModalStatus(false)
            } else {
              setIsSignatureLoadingStatus(false)
            }
          }
        }, "image/png")
      }
    }
  }

  const openSignatureModal = async () => {
    setIsSignatureLoadingStatus(true)
    if (user !== undefined && user.uuid !== undefined && user?.hasSignature) {
      const url = await signatureApi.getSignatureUrl({
        physicianId: user.uuid,
      })
      setSignatureUrl(url)
    }
    setIsSignatureLoadingStatus(false)
    setSignatureModalStatus(true)
  }

  useEffect(() => {
    if (study && studyInterpreted) {
      setStudy({
        ...study,
        status: "CE",
      })
      setDisableGraph(true)
    }
  }, [studyInterpreted])

  useEffect(() => {
    if (user === undefined || study === undefined) return

    if (
      (study !== undefined &&
        statusStrToEnum[study.status] === StudyStatus.COMPLETE) ||
      user?.primaryRole !== "Physician"
    ) {
      setDisableGraph(true)
    }
  }, [study, user])

  useEffect(() => {
    return () => {
      resetActiveStudyId()
      resetStudyMetrics()
      resetEcgExclusions()
      resetPpgExclusions()
      resetEventIds()
      resetCurrentEpoch()
      resetSnackAlertMsg()
      resetFullNightIntervalValue()
      resetSelectedTimeInterval()
      resetTimeIntervalList()
      resetStudyData()
      resetSleepEventData()
      resetSnackAletMsg()
      resetCurrentStudy()
      resetEventMetaData()
      resetSummaryIndicies()
      resetCsdRegionsOfInterest()
      resetRespiratoryEvents()
      resetEcgBadExclusions()
      resetReportStatus()
      resetGeneratingReportPdfStatus()
      resetAllPlotTimeDomain()
      resetStudyPlotOrder()
      resetDisableGraph()
    }
  }, [])

  return (
    <Div
      sx={{
        padding: 0,
        border: 0,
        height: "100%",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <StudyScoringHeader
        //openInterpretationDrawer={openInterpretationDrawer}
        interpretCurrentStudy={interpretCurrentStudy}
        studyID={studyID}
        chartRef={chartRef}
      ></StudyScoringHeader>
      <StyledStudyDatasetContent
        ref={scoringHeight}
        sx={{
          width:
            interpretationDrawerWidth > 0
              ? `calc(100% - ${
                  openInterpretationDrawerStatus ? interpretationDrawerWidth : 0
                }px)`
              : "100%",
        }}
      >
        <StyledDatasetPlots>
          <StyledDatasetHeader>
            <Box
              sx={{
                borderTop: "2px solid #EFEEF4",
                borderBottom: "2px solid #EFEEF4",
                padding: 1,
                marginBottom: 1,
              }}
            >
              <Stack
                direction="row"
                sx={{ m: 0 }}
                justifyContent={"space-between"}
                alignItems="center"
              >
                <ScoringHeader>
                  <></>
                </ScoringHeader>
                <IconButton onClick={handleOpenPlotOrderModal}>
                  <TuneIcon />
                </IconButton>
              </Stack>
            </Box>
            <Stack direction="row" spacing={2} sx={{ m: 0 }} alignItems="top">
              <Box width="250px" sx={{ pt: 1 }}>
                <IntervalFilter />
              </Box>
              <ParentSize debounceTime={20}>
                {({ width, height }) => {
                  return (
                    <HyponogramScroller
                      yDomainPaddingPercentage={20}
                      tickFormat={(_, n: number) =>
                        ({ "0": "Wake", "1": "Sleep" }[n])
                      }
                      width={width - 10}
                      height={50}
                    />
                  )
                }}
              </ParentSize>
            </Stack>
          </StyledDatasetHeader>
          <Box
            sx={{
              boxSizing: "border-box",
              flex: 1,
            }}
          >
            <PlotOrderModal
              open={openPlotOrderModal}
              handleClose={() => setOpenPlotOrderModal(false)}
            />
            <StackedPlots ref={chartRef} />
          </Box>
        </StyledDatasetPlots>
        <DatasetSidebar />
      </StyledStudyDatasetContent>
      <InterpretationDrawer
        initialInterpretationEditorContentData={
          initialInterpretationEditorContentData
        }
        interpretationDrawerWidth={interpretationDrawerWidth}
        openInterpretationDrawerStatus={openInterpretationDrawerStatus}
        setOpenInterpretationDrawerStatus={setOpenInterpretationDrawerStatus}
        openInterpretationTemplateModal={openInterpretationTemplateModal}
        onInterpretationEditorContentChange={
          onInterpretationEditorContentChange
        }
        setInterpretationDrawerWidth={setInterpretationDrawerWidth}
        openSignatureModal={openSignatureModal}
      />
      <InterpretationTemplateModal
        interpretationTemplateModalStatus={interpretationTemplateModalStatus}
        setInterpretationModalStatus={setInterpretationTemplateModalStatus}
        setInterpretationEditorContentData={setInterpretationEditorContentData}
        setInitialInterpretationContentData={
          setInitialInterpretationEditorContentData
        }
      />
      <SignatureModal
        signatureUrl={signatureUrl}
        editSignature={editSignature}
        setEditSignature={setEditSignature}
        droppedFile={droppedFile}
        setDroppedFile={setDroppedFile}
        signatureModalStatus={signatureModalStatus}
        setSignatureModalStatus={setSignatureModalStatus}
        signatureRadioValue={signatureRadioValue}
        setSignatureRadioValue={setSignatureRadioValue}
        handleChange={handleChange}
        saveSignature={saveSignature}
        signatureRef={signatureRef}
      />
      {isSignatureLoadingStatus && (
        <LoadingIndicator header="" content="This may take some time" />
      )}
      {isInterpretationTemplateLoading &&
        !interpretationTemplateModalStatus && (
          <LoadingIndicator
            header="Loading Interpretation Templates"
            content="This may take some time"
          />
        )}
      {studyInterpreting && (
        <LoadingIndicator
          header="Interpreting Study"
          content="This may take some time"
        />
      )}
      {isLoading && (
        <LoadingIndicator
          header="Loading Full Study Dataset"
          content="This may take some time"
        />
      )}
      {isGeneratingPDF && (
        <LoadingIndicator
          header="Generating PDF Report"
          content="This may take some time"
        />
      )}
      {!isLoading && !study && (
        <LoadingIndicator
          header="Failed to load study dataset"
          content="Please contact Huxley if this issue persists"
          showCircularProgress={false}
        />
      )}
    </Div>
  )
}

export default StudyScoringContent
