import { Box, Button, Typography } from "@mui/joy"

interface AddInterpretationTemplateProps {
  setAddInterpretationTemplateFormStatus: React.Dispatch<
    React.SetStateAction<boolean>
  >
}

const AddInterpretationTemplate = ({
  setAddInterpretationTemplateFormStatus,
}: AddInterpretationTemplateProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "15px",
      }}
    >
      <Typography sx={{ textAlign: "center" }} level="title-md">
        {`You haven't added any templates as of now. Click the button below to add templates `}
      </Typography>
      <Button
        onClick={() => setAddInterpretationTemplateFormStatus(true)}
        variant="solid"
        size="md"
      >
        Add Templates
      </Button>
    </Box>
  )
}

export default AddInterpretationTemplate
