import { useRecoilValue, useSetRecoilState } from "recoil"
import { usePortalApi } from "../connections"
import { snackAlert } from "../components/SnackAlerts"
import {
  InterpretationTemplateUpdate,
  InterpretationTemplateIn,
  InterpretationTemplateOut,
  GetAllInterpretationTemplatesRequest,
} from "../generated/fetchclient"
import {
  interpretationTemplates,
  interpretationTemplateCount,
} from "../state/interpretationtemplate.state"
import { handleApiError } from "../utils/apiUtils"

/**
 * Updates a interpretation template in the list of interpretation templates.
 *
 * @param interpretationTemplates The list of interpretation templates.
 * @param interpretationTemplate The interpretation template to update.
 */

function updateInterpretationTemplateInList(
  interpretationTemplates: InterpretationTemplateOut[],
  interpretationTemplate: InterpretationTemplateOut
) {
  const updatedInterpretationTemplate = interpretationTemplates.map((it) => {
    if (it.uuid === interpretationTemplate.uuid) {
      return interpretationTemplate
    }

    return it
  })
  return updatedInterpretationTemplate
}

const useInterpretationTemplate = () => {
  const api = usePortalApi()
  const setSnackAlertMsg = useSetRecoilState(snackAlert)
  const setInterpretationTemplates = useSetRecoilState(interpretationTemplates)
  const setInterpretationTemplateCount = useSetRecoilState(
    interpretationTemplateCount
  )

  /**
   * Lists interpretation templates.
   *
   * @param query The query to filter the list of interpretation templates.
   */
  const listInterpretationTemplates = async (
    query: GetAllInterpretationTemplatesRequest = {}
  ) => {
    if (api === undefined) return

    try {
      const interpretationTemplates = await api.getAllInterpretationTemplates(
        query
      )
      setInterpretationTemplates(interpretationTemplates.items)
      setInterpretationTemplateCount(interpretationTemplates.count)
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  /**
   * Updates a interpretation template.
   *
   * @param interpretationTemplateId The id of the interpretation template to update.
   * @param interpretationTemplate The updated interpretation tempalte object.
   */
  const updateInterpretationTemplate = async (
    templateId: string,
    interpretationTemplate: InterpretationTemplateUpdate
  ) => {
    if (api === undefined) return

    try {
      const updatedInterpretationTemplate =
        await api.updateInterpretationTemplate({
          templateId,
          interpretationTemplateUpdate: interpretationTemplate,
        })
      setInterpretationTemplates((interpretationTemplates) =>
        updateInterpretationTemplateInList(
          interpretationTemplates,
          updatedInterpretationTemplate
        )
      )
      setSnackAlertMsg({
        open: true,
        message: "Interpretation Template Updated",
        severity: "success",
        autoHideDuration: 5000,
      })
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  /**
   * Deletes a interpretation template by its id.
   *
   * @param templateId The template id of the template to delete.
   */
  const deleteInterpretationTemplate = async (templateId: string) => {
    if (api === undefined) return

    try {
      await api.deleteInterpretationTemplate({ templateId })
      setSnackAlertMsg({
        open: true,
        message: "Interpretation Template Deleted",
        severity: "success",
        autoHideDuration: 5000,
      })
      listInterpretationTemplates()
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  const createInterpretationTemplate = async (
    interpretationTemplate: InterpretationTemplateIn
  ) => {
    if (api === undefined) return

    try {
      await api.createInterpretationTemplate({
        interpretationTemplateIn: interpretationTemplate,
      })
      setSnackAlertMsg({
        open: true,
        message: "Interpretation Template Created",
        severity: "success",
        autoHideDuration: 5000,
      })
      listInterpretationTemplates()
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  return {
    interpretationTemplates: useRecoilValue(interpretationTemplates),
    interpretationTemplateCount: useRecoilValue(interpretationTemplateCount),
    createInterpretationTemplate,
    deleteInterpretationTemplate,
    listInterpretationTemplates,
    updateInterpretationTemplate,
  }
}

export default useInterpretationTemplate
