import { memo } from "react"
import { Text } from "@visx/text"
import { EventPlot } from "../../../types/event.type"

interface GraphPlotLeftAxisLabelProps {
  labelWidth: number
  lineHeight: number
  lineColor: string
  title: string
  plotType: EventPlot
  handleOpenScaleModal: (plotType: EventPlot) => void
}

const GraphPlotLeftAxisLabel = ({
  labelWidth,
  lineHeight,
  lineColor,
  handleOpenScaleModal,
  plotType,
  title,
}: GraphPlotLeftAxisLabelProps) => {
  return (
    <Text
      textAnchor="middle"
      dominantBaseline="middle"
      x={labelWidth / 2 - 10}
      y={lineHeight / 2}
      angle={270}
      fontSize="12px"
      fontWeight={"bold"}
      fill={lineColor}
      width={55}
      height={lineHeight}
      overflow={"hidden"}
      white-space={"nowrap"}
      text-overflow={"ellipsis"}
      onClick={() => handleOpenScaleModal(plotType)}
    >
      {title}
    </Text>
  )
}

export default memo(GraphPlotLeftAxisLabel, (_prevProps, _newProps) => {
  return (
    _prevProps.lineHeight === _newProps.lineHeight &&
    _prevProps.lineColor === _newProps.lineColor &&
    _prevProps.plotType === _newProps.plotType &&
    _prevProps.title === _newProps.title
  )
})
