/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RequestUploadOut
 */
export interface RequestUploadOut {
    /**
     * 
     * @type {string}
     * @memberof RequestUploadOut
     */
    bucket: string;
    /**
     * 
     * @type {string}
     * @memberof RequestUploadOut
     */
    key: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RequestUploadOut
     */
    urls: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RequestUploadOut
     */
    uploadId: string;
    /**
     * 
     * @type {string}
     * @memberof RequestUploadOut
     */
    studyId: string;
}

/**
 * Check if a given object implements the RequestUploadOut interface.
 */
export function instanceOfRequestUploadOut(value: object): boolean {
    if (!('bucket' in value)) return false;
    if (!('key' in value)) return false;
    if (!('urls' in value)) return false;
    if (!('uploadId' in value)) return false;
    if (!('studyId' in value)) return false;
    return true;
}

export function RequestUploadOutFromJSON(json: any): RequestUploadOut {
    return RequestUploadOutFromJSONTyped(json, false);
}

export function RequestUploadOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestUploadOut {
    if (json == null) {
        return json;
    }
    return {
        
        'bucket': json['bucket'],
        'key': json['key'],
        'urls': json['urls'],
        'uploadId': json['upload_id'],
        'studyId': json['study_id'],
    };
}

export function RequestUploadOutToJSON(value?: RequestUploadOut | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'bucket': value['bucket'],
        'key': value['key'],
        'urls': value['urls'],
        'upload_id': value['uploadId'],
        'study_id': value['studyId'],
    };
}

