/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserIn
 */
export interface UserIn {
    /**
     * 
     * @type {string}
     * @memberof UserIn
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserIn
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserIn
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserIn
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof UserIn
     */
    organizationId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserIn
     */
    groups: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UserIn
     */
    primaryRoleName: string;
}

/**
 * Check if a given object implements the UserIn interface.
 */
export function instanceOfUserIn(value: object): boolean {
    if (!('email' in value)) return false;
    if (!('phone' in value)) return false;
    if (!('groups' in value)) return false;
    if (!('primaryRoleName' in value)) return false;
    return true;
}

export function UserInFromJSON(json: any): UserIn {
    return UserInFromJSONTyped(json, false);
}

export function UserInFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserIn {
    if (json == null) {
        return json;
    }
    return {
        
        'firstName': json['first_name'] == null ? undefined : json['first_name'],
        'lastName': json['last_name'] == null ? undefined : json['last_name'],
        'email': json['email'],
        'phone': json['phone'],
        'organizationId': json['organization_id'] == null ? undefined : json['organization_id'],
        'groups': json['groups'],
        'primaryRoleName': json['primary_role_name'],
    };
}

export function UserInToJSON(value?: UserIn | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'first_name': value['firstName'],
        'last_name': value['lastName'],
        'email': value['email'],
        'phone': value['phone'],
        'organization_id': value['organizationId'],
        'groups': value['groups'],
        'primary_role_name': value['primaryRoleName'],
    };
}

