import HomeRoundedIcon from "@mui/icons-material/HomeRounded"
import Box from "@mui/joy/Box"
import Link from "@mui/joy/Link"
import DeviceTable from "../../components/device/DeviceTable"
import { useEffect, useState } from "react"
import { usePortalApi } from "../../connections"
import InternalHeader from "../../components/internalheader/InternalHeader"
import { HeaderList } from "../../interfaces/header.inteface"
import useDevices from "../../hooks/useDevices"
import LoadingIndicator from "../../components/loading/LoadingIndicator"

const DevicesContent = () => {
  const devicesApi = useDevices()
  const api = usePortalApi()
  const [headerList, setHeaderList] = useState<HeaderList[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    setIsLoading(true)
    const fetchData = async () => {
      await devicesApi.listDevices()
      setIsLoading(false)
    }
    setHeaderList([
      {
        fontWeight: 500,
        fontSize: 12,
        text: "Devices",
      },
    ])
    fetchData()
  }, [api])

  useEffect(() => {
    return () => {
      devicesApi.resetDeviceData()
    }
  }, [])
  return (
    <>
      <Box
        sx={{
          px: { xs: 0, sm: 2 },
          padding: 0,
          border: 0,
          height: "100%",
          width: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <InternalHeader breadCrumbList={headerList}>
            <Link
              underline="none"
              color="neutral"
              href="#some-link"
              aria-label="Home"
            >
              <HomeRoundedIcon />
            </Link>
          </InternalHeader>
        </Box>
        <DeviceTable></DeviceTable>
      </Box>
      {isLoading && (
        <LoadingIndicator
          header="Loading Devices"
          content="This may take some time"
        />
      )}
    </>
  )
}

export default DevicesContent
