/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SleepStudyInterpretationOut } from './SleepStudyInterpretationOut';
import {
    SleepStudyInterpretationOutFromJSON,
    SleepStudyInterpretationOutFromJSONTyped,
    SleepStudyInterpretationOutToJSON,
} from './SleepStudyInterpretationOut';
import type { SleepStudyOrderOutBase } from './SleepStudyOrderOutBase';
import {
    SleepStudyOrderOutBaseFromJSON,
    SleepStudyOrderOutBaseFromJSONTyped,
    SleepStudyOrderOutBaseToJSON,
} from './SleepStudyOrderOutBase';
import type { SleepStudyPatientOut } from './SleepStudyPatientOut';
import {
    SleepStudyPatientOutFromJSON,
    SleepStudyPatientOutFromJSONTyped,
    SleepStudyPatientOutToJSON,
} from './SleepStudyPatientOut';

/**
 * 
 * @export
 * @interface SleepStudyOut
 */
export interface SleepStudyOut {
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOut
     */
    uuid?: string;
    /**
     * 
     * @type {Date}
     * @memberof SleepStudyOut
     */
    deletedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof SleepStudyOut
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOut
     */
    assignedDevice: string;
    /**
     * 
     * @type {Date}
     * @memberof SleepStudyOut
     */
    start?: Date;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOut
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOut
     */
    autoscoreJsonUri?: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOut
     */
    uploadId?: string;
    /**
     * 
     * @type {object}
     * @memberof SleepStudyOut
     */
    eventDataAhiThree?: object;
    /**
     * 
     * @type {object}
     * @memberof SleepStudyOut
     */
    eventDataAhiFour?: object;
    /**
     * 
     * @type {object}
     * @memberof SleepStudyOut
     */
    eventDataEcg?: object;
    /**
     * 
     * @type {number}
     * @memberof SleepStudyOut
     */
    ahiThree: number;
    /**
     * 
     * @type {number}
     * @memberof SleepStudyOut
     */
    ahiFour: number;
    /**
     * 
     * @type {SleepStudyPatientOut}
     * @memberof SleepStudyOut
     */
    patient: SleepStudyPatientOut;
    /**
     * 
     * @type {SleepStudyOrderOutBase}
     * @memberof SleepStudyOut
     */
    studyOrder: SleepStudyOrderOutBase;
    /**
     * 
     * @type {Array<SleepStudyInterpretationOut>}
     * @memberof SleepStudyOut
     */
    interpretations: Array<SleepStudyInterpretationOut>;
}

/**
 * Check if a given object implements the SleepStudyOut interface.
 */
export function instanceOfSleepStudyOut(value: object): boolean {
    if (!('assignedDevice' in value)) return false;
    if (!('status' in value)) return false;
    if (!('ahiThree' in value)) return false;
    if (!('ahiFour' in value)) return false;
    if (!('patient' in value)) return false;
    if (!('studyOrder' in value)) return false;
    if (!('interpretations' in value)) return false;
    return true;
}

export function SleepStudyOutFromJSON(json: any): SleepStudyOut {
    return SleepStudyOutFromJSONTyped(json, false);
}

export function SleepStudyOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): SleepStudyOut {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'] == null ? undefined : json['uuid'],
        'deletedAt': json['deleted_at'] == null ? undefined : (new Date(json['deleted_at'])),
        'id': json['id'] == null ? undefined : json['id'],
        'assignedDevice': json['assigned_device'],
        'start': json['start'] == null ? undefined : (new Date(json['start'])),
        'status': json['status'],
        'autoscoreJsonUri': json['autoscore_json_uri'] == null ? undefined : json['autoscore_json_uri'],
        'uploadId': json['upload_id'] == null ? undefined : json['upload_id'],
        'eventDataAhiThree': json['event_data_ahi_three'] == null ? undefined : json['event_data_ahi_three'],
        'eventDataAhiFour': json['event_data_ahi_four'] == null ? undefined : json['event_data_ahi_four'],
        'eventDataEcg': json['event_data_ecg'] == null ? undefined : json['event_data_ecg'],
        'ahiThree': json['ahi_three'],
        'ahiFour': json['ahi_four'],
        'patient': SleepStudyPatientOutFromJSON(json['patient']),
        'studyOrder': SleepStudyOrderOutBaseFromJSON(json['study_order']),
        'interpretations': ((json['interpretations'] as Array<any>).map(SleepStudyInterpretationOutFromJSON)),
    };
}

export function SleepStudyOutToJSON(value?: SleepStudyOut | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'deleted_at': value['deletedAt'] == null ? undefined : ((value['deletedAt']).toISOString()),
        'id': value['id'],
        'assigned_device': value['assignedDevice'],
        'start': value['start'] == null ? undefined : ((value['start']).toISOString()),
        'status': value['status'],
        'autoscore_json_uri': value['autoscoreJsonUri'],
        'upload_id': value['uploadId'],
        'event_data_ahi_three': value['eventDataAhiThree'],
        'event_data_ahi_four': value['eventDataAhiFour'],
        'event_data_ecg': value['eventDataEcg'],
        'ahi_three': value['ahiThree'],
        'ahi_four': value['ahiFour'],
        'patient': SleepStudyPatientOutToJSON(value['patient']),
        'study_order': SleepStudyOrderOutBaseToJSON(value['studyOrder']),
        'interpretations': ((value['interpretations'] as Array<any>).map(SleepStudyInterpretationOutToJSON)),
    };
}

