import React from "react"
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  IconButton,
  List,
  ListItem,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
} from "@mui/joy"
import ListItemText from "@mui/material/ListItemText"
import { ListItemSecondaryAction } from "@mui/material"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { studyPlotOrder } from "../../../state/study.state"
import { EventPlot } from "../../../types/event.type"

export interface PlotOrderModalProps {
  open: boolean
  handleClose: () => void
}

const PlotOrderModal: React.FC<PlotOrderModalProps> = ({
  open,
  handleClose,
}) => {
  const plotOrder = useRecoilValue(studyPlotOrder)
  const setPlotOrder = useSetRecoilState(studyPlotOrder)
  const [selectedPlot, setSelectedPlot] = React.useState<EventPlot | undefined>(
    undefined
  )
  return (
    <Modal
      open={open}
      onClose={handleClose}
      hideBackdrop={true}
      aria-labelledby="modal-plot-order"
      aria-describedby="modal-to-set-plot-order"
    >
      <ModalDialog>
        <ModalClose />
        <Typography>Displayed Signals</Typography>
        <DialogContent>
          <List>
            {plotOrder.map((plot, index) =>
              plot.plotType === "ECG" ? null : (
                <ListItem
                  key={plot.plotType}
                  style={{
                    borderBottom: "1px solid gray",
                    backgroundColor:
                      selectedPlot === plot.plotType ? "lightgray" : "white",
                  }}
                  onClick={() => setSelectedPlot(plot.plotType)}
                >
                  <ListItemSecondaryAction>
                    <Checkbox
                      checked={plot.enabled}
                      onChange={() => {
                        const newPlotOrder = [...plotOrder]
                        const index = newPlotOrder.indexOf(plot)
                        newPlotOrder[index] = {
                          plotType: plot.plotType,
                          enabled: !plot.enabled,
                        }
                        setPlotOrder(newPlotOrder)
                      }}
                    />
                  </ListItemSecondaryAction>
                  <ListItemText
                    primary={
                      plot.plotType === "SANSASleepStage"
                        ? "Sleep"
                        : plot.plotType
                    }
                  />
                </ListItem>
              )
            )}
          </List>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <IconButton
            onClick={() => {
              if (selectedPlot !== undefined) {
                const newPlotOrder = [...plotOrder]
                const index = newPlotOrder.findIndex(
                  (plot) => plot.plotType === selectedPlot
                )
                if (index === 0) {
                  return
                }
                const temp = newPlotOrder[index]
                newPlotOrder[index] = newPlotOrder[index - 1]
                newPlotOrder[index - 1] = temp
                setPlotOrder(newPlotOrder)
              }
            }}
          >
            <ArrowUpwardIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              if (selectedPlot !== undefined) {
                const newPlotOrder = [...plotOrder]
                const index = newPlotOrder.findIndex(
                  (plot) => plot.plotType === selectedPlot
                )
                if (index === newPlotOrder.length - 1) {
                  return
                }
                const temp = newPlotOrder[index]
                newPlotOrder[index] = newPlotOrder[index + 1]
                newPlotOrder[index + 1] = temp
                setPlotOrder(newPlotOrder)
              }
            }}
          >
            <ArrowDownwardIcon />
          </IconButton>
        </DialogActions>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  )
}
export default PlotOrderModal
