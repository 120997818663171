import { Box, Grid, Typography } from "@mui/joy"

const NoInterpretationTemplateFound = () => {
  return (
    <Grid
      xs={12}
      sx={{
        maxHeight: {
          md: "520px",
          xs: "200px",
        },
        minHeight: {
          md: "520px",
          xs: "200px",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box>
        <Typography level="h3">{"No Templates Found"}</Typography>
      </Box>
    </Grid>
  )
}

export default NoInterpretationTemplateFound
